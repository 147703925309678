import React, {useRef} from 'react';
import Slider from 'react-slick';
import {H1} from '../../style/Typography';
import {breakPx} from '../../style/theme';
import CustomBlockContent from '../CustomBlockContent';
import Card from '../Card/index.tsx';
import {
  ButtonHider,
  CarouselHead,
  Slick,
  Wrapper,
  Subhead,
  Button,
} from './styles';
import CarouselButtons from './CarouselButtons';
import LazyLoad from 'react-lazyload';
import {useStaticQuery, graphql} from 'gatsby';

const cfg = {
  maxCount: 4,
  breaks: [
    {px: breakPx.medium, count: 3},
    {px: breakPx.small, count: 2},
    {px: 600, count: 1},
  ],
};

const Noop = () => null;

const slickProps = (slideCount) => ({
  prevArrow: <Noop />,
  nextArrow: <Noop />,
  easing: 'ease-in-out',
  speed: 500,
  slidesToScroll: 1,
  infinite: slideCount > cfg.maxCount,
  slidesToShow: Math.min(slideCount, cfg.maxCount),
  responsive: cfg.breaks.map((brk) => ({
    breakpoint: brk.px,
    settings: {
      infinite: slideCount > brk.count,
      slidesToShow: Math.min(slideCount, brk.count),
    },
  })),
});

export default ({title, subtitle, buttonLink, category, ctaCards}) => {
  const ref = useRef();
  let cards = [];

  if (ctaCards) {
    cards = ctaCards.slice(0, 8);
  } else {
    let {stories} = useStaticQuery(
      graphql`
        query {
          stories: allSanityStoryPage(
            sort: {fields: publishedDate, order: DESC}
          ) {
            cards: nodes {
              id: _id
              title
              fullUrl
              image: previewImage {
                src {
                  asset {
                    url
                    fluid(maxWidth: 350) {
                      srcSet
                      src
                      sizes
                    }
                  }
                }
              }
              category {
                name
                slug {
                  current
                }
              }
            }
          }
        }
      `,
    );

    const storyCardList = category
      ? stories.cards.filter((card) => {
          if (category && card.category) {
            return card.category.slug.current === category.slug.current;
          }
          return true;
        })
      : stories.cards;

      cards = storyCardList.slice(0, 8);
  }


  const onPrev = () => {
    if (ref.current && ref.current.slickPrev) {
      ref.current.slickPrev();
    }
  };

  const onNext = () => {
    if (ref.current && ref.current.slickNext) {
      ref.current.slickNext();
    }
  };

  const renderSubhead =
    typeof subtitle === 'string' ? (
      <div dangerouslySetInnerHTML={{__html: subtitle}} />
    ) : (
      <CustomBlockContent blocks={subtitle} />
    );

  return (
    <Wrapper>
      <CarouselHead>
        <div style={{display: 'block'}}>
          <H1>{title}</H1>
          <Subhead>
            {renderSubhead}
          </Subhead>
          {buttonLink?.buttonText && buttonLink?.href && (
            <Button {...buttonLink} />
          )}
        </div>
        <ButtonHider cfg={cfg} slideCount={cards.length}>
          <CarouselButtons {...{onPrev, onNext}} />
        </ButtonHider>
      </CarouselHead>

      <LazyLoad height={550}>
        <Slick
          ref={ref}
          as={Slider}
          slideCount={cards.length}
          cfg={cfg}
          {...slickProps(cards.length)}
        >
          {cards.map((val, idx) => (
            <Card {...val} key={idx} />
          ))}
        </Slick>
      </LazyLoad>
    </Wrapper>
  );
};
