import React from 'react';
import styled from 'styled-components';
import {colors, query} from './../../style/theme';
import {isNullOrUndefined} from 'util';
import pushDataLayer from '../../utils/pushDataLayer';

interface PlayButtonProps {
  setVideo: (ambientVideo?: boolean, showVideo?: boolean) => void;
  ambientVideo?: boolean;
  showVideo?: boolean;
  trackingLabel?: string;
}

const VideoPlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.yellow};
  border-radius: 50%;
  cursor: pointer;
  width: 60px;
  height: 60px;
  transition: all 0.2s ease;
  z-index: 1;
  border: 2px solid ${colors.yellow};

  &:hover {
    background-color: transparent;
  }

  svg {
    width: 60px;
    height: 60px;
    position: relative;
  }

  g {
    transition: all 0.1s ease;
  }

  @media (${query.above(992)}) {
    width: 100px;
    height: 100px;

    svg {
      width: 100px;
      height: 100px;
    }
  }
`;

class PlayButton extends React.Component<PlayButtonProps> {
  render() {
    const {ambientVideo, showVideo, trackingLabel} = this.props;
    return (
      /* prettier-ignore */
      <VideoPlayButton onClick={(event: React.MouseEvent) => {
        event.preventDefault();
        pushDataLayer({
          'videoLabel': trackingLabel,
          'event': 'videoPlay'
        });
        !isNullOrUndefined(ambientVideo) && this.props.setVideo(ambientVideo);
        !isNullOrUndefined(showVideo) && this.props.setVideo(true);
        }}>
        <svg width='98px' height='98px' viewBox='0 0 98 98' version='1.1'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-920.000000, -433.000000)'>
              <g transform='translate(924.000000, 435.000000)'>
                <g id='Oval'>
                  <use
                    fill='#1FBAC6'
                    fillOpacity='1'
                    filter='url(#filter-2)'
                  ></use>
                  <use fill='#1FBAC6' fillRule='evenodd'></use>
                </g>
                <polygon
                  id='Path'
                  fill='#414042'
                  points='36 25 36 66 67 45.5'
                ></polygon>
              </g>
            </g>
          </g>
        </svg>
      </VideoPlayButton>
    );
  }
}

export default PlayButton;
