import React from 'react';

export const boldTitleRender = (props) => (
  <span style={{color: '#666'}}>{props.children}</span>
);

export const SuperscriptTagRender = (props) => <sup>{props.children}</sup>;

export const blockMarks = {
  decorators: [
    {
      title: 'Strong',
      value: 'strong',
      blockEditor: {
        render: boldTitleRender,
      },
    },
    {title: 'Emphasis', value: 'em'},
    {title: 'Code', value: 'code'},
    {title: 'Underline', value: 'underline'},
    {title: 'Strike', value: 'strike-through'},
    {
      title: 'Superscript Tag',
      value: 'sup',
      blockEditor: {
        render: SuperscriptTagRender,
      },
    },
  ],
};

export const serializers = {
  marks: {
    CustomLink: ({mark, children}) => {
      const {
        documentLink,
        externalLink,
        internalLink,
        emailLink,
        openInNewWindow,
      } = mark;
      let href = null;

      if (documentLink) {
        let fileName = documentLink.file.asset.url.split('/').pop();

        href = `/files/${fileName}`;
      }

      if (externalLink) {
        href = externalLink;
      }

      if (internalLink) {
        href = internalLink.fullUrl;
      }

      if (emailLink) {
        href = `mailto:${emailLink}`;
      }

      return (
        <a href={href || ''} target={openInNewWindow ? '_blank' : ''}>
          {children}
        </a>
      );
    },
    sup: ({mark, children}) => {
      return <sup>{children}</sup>;
    },
  },
};
