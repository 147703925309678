import React from 'react';
import styled from 'styled-components';
import {H1} from '../../style/Typography';
import {fonts, colors, query} from '../../style/theme';
import Img from 'gatsby-image';

export const SectionWrapper = styled.section``;

export const CopySection = styled.div``;

export const Wrapper = styled.div<{image: boolean}>`
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 20px ${(props) => (props.image === true ? '40px' : '0')};
  @media (${query.above.tablet}) {
    padding: 40px 20px ${(props) => (props.image === true ? '100px' : '0')};
  }
`;

export const CopyContainer = styled.div`
  max-width: 820px;
  color: ${colors.charcoal};
  text-align: left;
  margin: 0;
`;

export const Eyebrow = styled.span`
  color: ${colors.charcoal};

  font-family: ${fonts.sofiaRegular.fontFamily};
  text-transform: uppercase;
  font-weight: bold;
  display: block;

  font-size: 12px;
  letter-spacing: 1.25px;
  line-height: 24px;

  padding: 10px 0;

  @media (${query.above.tablet}) {
    font-size: 15px;
    letter-spacing: 1.5px;
    line-height: 20px;
  }
`;

export const Headline = styled(H1)`
  color: ${colors.charcoal};
  margin-bottom: 20px;

  @media (${query.above.tablet}) {
    font-size: 60px;
    line-height: 63px;

    font-size: 57px;
    line-height: 60px;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0;

  padding: 5px 0;
  max-width: 200px;

  *[fill='#FFFFFF'],
  *[fill='#ffffff'],
  *[stroke='#FFFFFF'],
  *[stroke='#ffffff'] {
    fill: ${colors.charcoal};
  }

  @media (${query.above.tablet}) {
    padding: 10px 0 15px;
    max-width: 376px;

    svg {
      width: 375px;
      height: 135px;
    }
  }
`;

export const BlockCopyWrapper = styled.div`
  p {
    font-family: ${fonts.sofiaRegular.fontFamily};

    sup {
      vertical-align: super;
      font-size: 0.5em;
      padding-left: 2px;
      color: inherit;
    }

    @media (${query.above.tablet}) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  strong {
    font-weight: 800;
  }
`;

export const HeroImage = styled(Img)`
  width: 100%;
  position: relative;
`;
