import styled from 'styled-components';
import Link from '../../Link';
import {fonts, query} from '../../../style/theme';

export const NavBar = styled.nav<{isOpen: boolean; isActive: boolean}>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  flex-direction: column;
  position: fixed;
  min-height: ${({isOpen}) => (isOpen ? '100vh' : 0)};
  z-index: 10;
  width: 100%;
  background: white;
  transition: ${(props) => (props.isActive ? 'top' : 'foo')} 0.2s linear;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
`;

export const LogoWrap = styled(Link)`
  flex: 2 2 auto;
  padding: 10px 20px 4px 10px;

  svg {
    width: 110px;
    height: 38px;
  }

  *[fill='#FFFFFF'],
  *[fill='#ffffff'],
  *[stroke='#FFFFFF'],
  *[stroke='#ffffff'] {
    fill: #414042;
  }
  @media (${query.upto.tablet}) {
    flex: 0 1 auto;
  }
`;

export const SignupButton = styled.button`
  flex: 0 0 200px;
  background: #f6c142;
  font-size: 14px;
  line-height: 14px;
  border: 0;
  cursor: pointer;
  ${fonts.sofiaBold};
  color: #414042;
  outline: none;
  margin: 0;
  @media (${query.upto.tablet}) {
    flex: 2 2 auto;
  }
`;

export const BurgerWrap = styled.div`
  width: 62px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    cursor: pointer;
  }
`;

export const Inner = styled.div`
  display: flex;
  height: 50px;
`;
