import React from 'react';
import styled from 'styled-components';
import {H0} from '../../style/Typography';
import {colors, query} from '../../style/theme';
import Img from 'gatsby-image';
import FreeText from '../FreeTextArea';

export const Section = styled.section`
  padding: 40px 20px;
  @media (${query.above.tablet}) {
    padding: 80px 20px;
  }
`;

export const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

export const Headline = styled(H0)`
  color: ${colors.charcoal};
  text-align: left;
  font-size: 32px;
  line-height: 34px;
  @media (${query.above.tablet}) {
    font-size: 60px;
    line-height: 63px;
    text-align: center;
  }
`;

export const Copy = styled(FreeText)`
  margin-top: 17px;

  @media (min-width: 769px) {
    max-width: 765px;
    margin: 17px auto 0 auto;
    text-align: center;
  }
`;

export const LogoGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  padding: 30px 0 0 0;

  @media (${query.above.tablet}) {
    padding: 20px 0 0 0;
  }
`;

export const LogoItem = styled.a`
  width: 65px;
  margin: 10px;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (${query.above.tablet}) {
    width: 150px;
    margin: 20px;
    & > div {
      padding: 20px;
      transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.07);
    }
    &[href]:hover > div {
      transform: translateY(-8px);
      box-shadow: 0 8px 16px 4px rgba(0, 0, 0, 0.07);
    }
  }
`;

export const LogoImage = styled<any>(Img)`
  width: ${(props) =>
    props.aspectRatio < 1.3 ? `${props.aspectRatio * 60}px` : 'auto'};
  position: relative;

  @media (${query.above.tablet}) {
    width: ${(props) =>
      props.aspectRatio < 1.3 ? `${props.aspectRatio * 140}px` : 'auto'};
  }
`;
