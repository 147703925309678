import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import Logo from '../../images/footer-logo.svg';
import BrandSocialIcons from '../SocialIcons/Brand';
import {
  FooterSection,
  UpperFooterWrapper,
  LogoWrapper,
  UpperLinksGroup,
  UpperLinkWrapper,
  UpperLink,
  SocialIconWrapper,
  LowerFooterWrapper,
  Copyright,
  LowerLinksGroup,
  LowerLink,
} from './styles';

const Footer = ({upperLinks, lowerLinks}) => {
  const date = new Date();

  return (
    <FooterSection>
      <UpperFooterWrapper>
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>

        <UpperLinksGroup>
          {upperLinks.length
            ? upperLinks.map((link) => (
                <UpperLinkWrapper key={link._key}>
                  <UpperLink
                    href={link.href}
                    target={link.openInNewWindow ? '_blank' : '_self'}
                    text={link.text}
                  />
                </UpperLinkWrapper>
              ))
            : null}
        </UpperLinksGroup>
        <SocialIconWrapper>
          <BrandSocialIcons />
        </SocialIconWrapper>
      </UpperFooterWrapper>
      <LowerFooterWrapper>
        {lowerLinks.length ? (
          <LowerLinksGroup>
            {lowerLinks.map((link) => (
              <LowerLink
                key={link._key}
                href={link.href}
                target={link.openInNewWindow ? '_blank' : '_self'}
              >
                {link.text}
              </LowerLink>
            ))}
          </LowerLinksGroup>
        ) : null}
        <Copyright>
          ©{date.getFullYear()} American Chemistry Council, Inc. All rights
          reserved.
        </Copyright>
      </LowerFooterWrapper>
    </FooterSection>
  );
};

Footer.propTypes = {
  upperLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      openInNewWindow: PropTypes.bool,
    }),
  ),
  lowerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      openInNewWindow: PropTypes.bool,
    }),
  ),
};

Footer.defaultProps = {
  upperLinks: null,
  lowerLinks: null,
};

export default () => {
  const data = useStaticQuery(graphql`
    {
      sanityFooter {
        id
        lowerLinks {
          _key
          _type
          href
          text
          openInNewWindow
        }
        upperLinks {
          _key
          _type
          href
          text
          openInNewWindow
        }
      }
    }
  `);

  return (
    <Footer
      lowerLinks={data.sanityFooter.lowerLinks}
      upperLinks={data.sanityFooter.upperLinks}
    />
  );
};
