import React, {useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import CategoryBar from './CategoryBar';
import TagList from './TagList';
import StoryList from './StoryList';
import FloatingFilterButton from './FloatingFilterButton';
import {_buildStoryPredicate} from './types';
import {
  story as _story,
  tag as _tag,
  category as _category,
} from '../../interfaces';
import ActiveTags from './ActiveTags';
import styled from 'styled-components';
import useScrollToRef from '../../utils/useScrollToRef';
import {observer, inject} from 'mobx-react';
import qs from 'query-string';

const discoverQuery = graphql`
  query {
    allSanityStoryCategory(sort: {fields: name}) {
      nodes {
        id
        name
        slug {
          current
        }
      }
    }
    allSanityTags {
      nodes {
        id
        name
        slug {
          current
        }
      }
    }
    allSanityStoryPage {
      nodes {
        id
        title
        metaDescription
        publishedDate(formatString: "MMM D, YYYY")
        sortDate: publishedDate(formatString: "X")
        slug {
          _key
          current
        }
        tags {
          id
          name
          slug {
            current
          }
        }
        category {
          id
          name
          slug {
            current
          }
        }
        previewImage {
          src {
            asset {
              url
              fluid(maxWidth: 500) {
                __typename
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          alt
          title
        }
      }
    }
    allSanityOutreachPage {
      nodes {
        id
        title
        metaDescription
        publishedDate(formatString: "MMM D, YYYY")
        sortDate: publishedDate(formatString: "X")
        slug {
          _key
          current
        }
        tags {
          id
          name
          slug {
            current
          }
        }
        category {
          id
          name
          slug {
            current
          }
        }
        previewImage {
          src {
            asset {
              url
              fluid(maxWidth: 500) {
                __typename
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          alt
          title
        }
      }
    }
    allSanityPetitionPage {
      nodes {
        id
        title
        metaDescription
        publishedDate(formatString: "MMM D, YYYY")
        sortDate: publishedDate(formatString: "X")
        slug {
          _key
          current
        }
        tags {
          id
          name
          slug {
            current
          }
        }
        category {
          id
          name
          slug {
            current
          }
        }
        previewImage {
          src {
            asset {
              url
              fluid(maxWidth: 500) {
                __typename
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          alt
          title
        }
      }
    }
  }
`;

const Stopper = styled.div`
  width: 100%;
  height: 0;
  margin: 0;
`;

const updateUrl = (selectedCategory: string, selectedTags: Set<_tag>) => {

  let params = {
    ...qs.parse(window.location.search),
    category: selectedCategory ? selectedCategory : '',
    tags: selectedTags.size > 0 ? Array.from(selectedTags).join(',') : '',
  };

  Object.keys(params).forEach(key => !params[key] && delete params[key]);

  const url = `${window.location.pathname}${
    Object.keys(params).length > 0 ? `?${qs.stringify(params)}` : ''
  }`;

  history.pushState({}, '', url);
};

// given optional catergory and tags, returns a predicate that will match a story accordingly.
const buildStoryPredicate: _buildStoryPredicate = (
  selectedDiscoverCategory,
  selectedDiscoverTags,
) => (story, idx) => {
  // only check story category when activeCategoryId is specified (length != 0)
  if (
    selectedDiscoverCategory &&
    story.category?.slug.current !== selectedDiscoverCategory
  ) {
    return false;
  }
  // now that category is checked, skip tags if there aren't any activeTagIds
  if (selectedDiscoverTags.size === 0) {
    return true;
  }
  // check each of the stories against activeTagIds, return true at first match (OR)
  for (let i = 0; i < story.tags.length; i++) {
    if (selectedDiscoverTags.has(story.tags[i].slug.current)) {
      return true;
    }
  }
  // looks like no tags matched, too bad.
  return false;
};

const DiscoverPage = (props: any) => {
  const {
    selectedTags,
    removeTag,
    selectedCategory,
    setCategory,
  } = props.discoverStore;
  const [tagsListShowing, setTagsListShowing] = useState(false);
  const [taglistRef, scrollToTaglistTop] = useScrollToRef();

  const results = useStaticQuery(discoverQuery);

  const predicate = buildStoryPredicate(selectedCategory, selectedTags);
  const allStories = ([
    ...(results?.allSanityStoryPage?.nodes || []),
    ...(results?.allSanityOutreachPage?.nodes || []),
    ...(results?.allSanityPetitionPage?.nodes || []),
  ] as _story[]).sort((a, b) => parseInt(b.sortDate) - parseInt(a.sortDate));
  const allCategories = (results?.allSanityStoryCategory?.nodes ||
    []) as _category[];
  const allTags = (results?.allSanityTags?.nodes || []) as _tag[];

  typeof window !== 'undefined' && updateUrl(selectedCategory, selectedTags);

  const onClearAll = () => {
    removeTag('all');
    setCategory(null);
    scrollToTaglistTop();
  };

  const onPickCategory = (slug: string) => {
    setCategory(slug);
  };

  const onToggleTagsList = () => {
    setTagsListShowing(!tagsListShowing);
  };

  const onTagsListDone = () => {
    setTagsListShowing(false);
  };

  const tagsAreSelected = !!selectedTags.size;
  const stories = allStories.filter(predicate);

  // don't set featured story if there are categories or tags active
  const featuredStoryId =
    (!selectedCategory && !tagsAreSelected && props?.featuredStory?.id) || null;

  return (
    <div style={{position: 'relative'}}>
      <CategoryBar
        {...{
          allCategories,
          selectedCategory,
          tagsListShowing,
          tagsAreSelected,
          onPickCategory,
          onToggleTagsList,
        }}
      />
      <TagList
        {...{
          allTags,
          onDone: onTagsListDone,
          showTagsList: tagsListShowing,
        }}
      />
      <Stopper />
      <ActiveTags
        {...{
          allTags,
        }}
      />
      <StoryList {...{stories, featuredStoryId, onClearAll, ref: taglistRef}}>
        <FloatingFilterButton
          {...{tagsListShowing, tagsAreSelected, onToggleTagsList}}
        />
      </StoryList>
    </div>
  );
};

export default inject(`discoverStore`)(observer(DiscoverPage));
