import {createGlobalStyle} from 'styled-components';
import theme, {query} from './theme';

const noop = (/*props*/) => null;

const Guides = createGlobalStyle`
  .Container {
    &:before {
      content: '';
      position: absolute;
      z-index: 9001;

      outline: 1000px solid rgba(64,64,64,0.3);

      top: 0;
      bottom: 0;

      left: ${theme.constants.minGutterMobile};
      right: ${theme.constants.minGutterMobile};

      @media (${query.above.tablet}) {
        left: max(
          ${theme.constants.minGutter},
          (100% - ${theme.constants.maxContentWidth}) / 2
        );
        right: max(
          ${theme.constants.minGutter},
          (100% - ${theme.constants.maxContentWidth}) / 2
        );
      }
      pointer-events: none;
    }
  }

  .Content > section, #EmailSignupHero, [data-guides] {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      z-index: 9001;
      outline: orange dashed 3px !important;
      top: ${theme.constants.sectionPaddingMobile};
      bottom: ${theme.constants.sectionPaddingMobile};
      left: ${theme.constants.minGutterMobile};
      right: ${theme.constants.minGutterMobile};
      @media (${query.above.tablet}) {
        outline: orange dashed 3px !important;
        top: ${theme.constants.sectionPadding};
        bottom: ${theme.constants.sectionPadding};
        left: max(
          ${theme.constants.minGutter},
          (100% - ${theme.constants.maxContentWidth}) / 2
        );
        right: max(
          ${theme.constants.minGutter},
          (100% - ${theme.constants.maxContentWidth}) / 2
        );
      }
      pointer-events: none;
    }
  }
`;

const Component = process.env.GATSBY_DEV_GUIDES ? Guides : noop;
export default Component;
