import {useState, useEffect} from 'react';

export function useIsMobile() {
  const mobileQuery =
    typeof window !== 'undefined'
      ? window.matchMedia('(max-width: 1024px)')
      : {};

  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? mobileQuery.matches : false,
  );

  useEffect(() => {
    const boundMediaListener = () => setIsMobile(mobileQuery.matches);

    mobileQuery.addListener(boundMediaListener);

    return () => {
      if (typeof window !== 'undefined') {
        mobileQuery.removeListener(boundMediaListener);
      }
    };
  }, []);

  return [isMobile];
}
