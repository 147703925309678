import React, {useState, FormEvent} from 'react';
import styled from 'styled-components';
import {H2 as BaseH2, P1} from '../../style/Typography';
import {fonts, query, colors} from '../../style/theme';
import {MainContentSection} from '../../style/layout';
import {Button} from '../../style/Buttons';
import {saveContact} from '../../utils/hubSpot';
import SuccessHero from './SuccessHero';
import FreeTextArea from '../FreeTextArea';
import pushDataLayer from '../../utils/pushDataLayer';
import {useStaticQuery, graphql} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import LazyLoad from 'react-lazyload';

const Container = styled(MainContentSection)`
  position: relative;
`;

const CustomBackgroundImage = styled(BackgroundImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Inner = styled.div`
  margin: -8px 0 -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 270px;
  position: relative;
  z-index: 2;

  @media (${query.above.tablet}) {
    margin: -14px 0 -57px;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
`;

const Left = styled.div`
  flex: 0 0 100%;
  margin-bottom: 10px;

  @media (${query.above.tablet}) {
    flex: 0 0 48%;
    margin-bottom: 0px;
  }

  p {
    font-size: 18px;
    line-height: 27px;
  }
`;

const Right = styled.div`
  flex: 0 0 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (${query.above.tablet}) {
    flex: 0 0 48%;
    width: auto;
  }
`;

const H2 = styled(BaseH2)`
  color: white;
  font-size: 60px;
  line-height: 63px;
  margin-bottom: 12px;

  @media (${query.upto.tablet}) {
    font-size: 32px;
    line-height: 34px;
    letter-spacing: -0.5px;
  }
`;

const Copy = styled(FreeTextArea)`
  color: white;
  max-width: 466px;

  @media (${query.upto.tablet}) {
    max-width: 100%;
  }

  strong {
    font-weight: bold;
  }
  p {
    ${fonts.sofiaRegular};

    @media (${query.upto.tablet}) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: normal;
    }
  }
`;

const Form = styled.form<{formStatus: string}>`
  width: 100%;
  position: relative;
  z-index: 1;
  right: 0;

  > * {
    transition: opacity 0.2s linear;
  }

  > *:not(img) {
    opacity: ${({formStatus}) => (formStatus === 'submitting' ? 0.1 : 1)};
  }

  > img {
    opacity: ${({formStatus}) => (formStatus === 'submitting' ? 1 : 0)};
    z-index: ${({formStatus}) => (formStatus === 'submitting' ? 2 : -1)};
  }
`;

const TextInput = styled.input`
  background: transparent;
  border: 0;
  border-bottom: 2px solid white;
  width: 100%;
  margin-bottom: 40px;
  color: white;
  font-size: 18px;
  line-height: 48px;
  ${fonts.sofiaRegular};
  outline: none;
  max-width: 484px;
  -webkit-appearance: none;
  border-radius: 0;

  ::placeholder {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    font-weight: inherit;
  }

  @media (${query.upto.tablet}) {
    max-width: 100%;
    font-size: 14px;
    margin-bottom: 24px;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin: 0 0 30px;
  display: block;

  &:hover {
    color: ${colors.yellow};
  }

  @media (${query.above.tablet}) {
    display: inline-flex;
    width: auto;
  }
`;

const ErrorMessage = styled.div<{formStatus: string}>`
  font-family: ${fonts.sofiaRegular.fontFamily};
  color: red;
  display: ${(props) => (props.formStatus === 'error' ? 'block' : 'none')};
  margin-top: 38px;
  text-align: center;
`;

const Loading = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: -1;
  width: 100px;
`;

interface _status {
  status?: string | null;
  errorMessage?: string | null;
}

export default () => {
  const [status, setStatus] = useState<_status>({
    status: null,
    errorMessage: null,
  });

  const images = useStaticQuery(graphql`
    query {
      background: file(name: {eq: "email-hero-background"}) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      loading: file(name: {eq: "loading"}) {
        src: publicURL
      }
    }
  `);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setStatus({status: 'submitting'});

    const formData = new FormData(event.currentTarget as HTMLFormElement);

    saveContact(formData)
      .then((resp) => {
        if (resp.status && resp.status === 'error') {
          console.info(resp);
          setStatus({
            status: 'error',
            errorMessage:
              'Sorry, there was an error. Please check your information and try again.',
          });
        } else {
          pushDataLayer({event: 'Email_Submit'});
          setStatus({status: 'success'});
        }
      })
      .catch((err) => {
        console.warn(err);
        setStatus({
          status: 'error',
          errorMessage:
            'We encountered a server error. Please try again in a few minutes.',
        });
      });
  };

  return (
    <LazyLoad classNamePrefix="EmailSignupHero" height={379}>
      <Container id="EmailSignupHero">
        <CustomBackgroundImage
          fluid={images.background.childImageSharp.fluid}
        />
        <Inner>
          <Left>
            <H2>Stay in the&nbsp;loop</H2>
            <Copy>
              <p>
                Get information and updates about how America’s plastic makers
                are going <em>From Single Use to Reuse.</em>
              </p>
            </Copy>
          </Left>

          <Right>
            <Form onSubmit={handleSubmit} formStatus={`${status.status}`}>
              <TextInput
                name="email"
                type="email"
                placeholder="Email address"
                required
              />

              <TextInput name="zip" type="text" placeholder="ZIP code" />
              <SubmitButton type="submit">Sign Up</SubmitButton>

              <ErrorMessage formStatus={`${status.status}`}>
                {status.errorMessage || 'Error!!'}
              </ErrorMessage>

              <Loading src={images.loading.src} loading="lazy" />
            </Form>
          </Right>
        </Inner>
        <SuccessHero visible={status.status === 'success'} />
      </Container>
    </LazyLoad>
  );
};
