import React, {FunctionComponent as fc} from 'react';
import {
  CardWrapper,
  CardImageWrapper,
  CardImage,
  FeaturedImage,
  FeaturedImageLink,
  CardBody,
  Title,
  TitleLink,
  AccentGroup,
  PrimaryAccent,
  SecondaryAccent,
  Description,
  CardArrowLink,
} from './styles';
import {ImageProps} from '../../interfaces';

export interface propsCard {
  title: string;
  fullUrl?: string;
  link?: string;
  image?: ImageProps;
  description?: string;
  accent?: {
    primary?: {
      accent: string;
      link?: string;
      event?: Function;
    };
    secondary?: {
      accent: string;
    };
  };
  featured?: boolean;
  parentPath?: string;
}

const Card: fc<propsCard> = ({
  title,
  fullUrl,
  link,
  image,
  description,
  accent,
  featured,
  parentPath,
}) => {
  const mainLink = fullUrl ? fullUrl : link ? link : '#';

  const ctaImage = image ? (
    <CardImage
      fluid={image.src.asset.fluid}
      alt={image.alt}
      title={image.title}
    />
  ) : null;
  const featImage = image ? (
    featured === true ? (
      <FeaturedImage
        fluid={image.src.asset.fluid}
        alt={image.alt}
        title={image.title}
      >
        <FeaturedImageLink href={mainLink} />
      </FeaturedImage>
    ) : null
  ) : null;

  const renderTitle = <Title featured={featured}>{title}</Title>;
  const titleWrapped =
    mainLink === '#' ? (
      renderTitle
    ) : (
      <TitleLink href={mainLink}>{renderTitle}</TitleLink>
    );

  const renderDescription = description ? (
    <Description>{description}</Description>
  ) : null;

  const accentMedian = accent?.primary && accent?.secondary ? ' | ' : '';
  const primaryAccentEvent = () =>
    accent?.primary?.event ? accent.primary.event() : {};
  const renderPrimaryAccent = accent?.primary ? (
    <PrimaryAccent
      href={accent.primary.link ? accent.primary.link : '#'}
      text={accent.primary.accent}
      onClick={(e: React.MouseEvent) => primaryAccentEvent()}
    />
  ) : null;
  const renderSecondaryAccent = accent?.secondary ? (
    <SecondaryAccent>{accent.secondary.accent}</SecondaryAccent>
  ) : null;
  const renderAccentGroup = (
    <AccentGroup featured={featured}>
      {renderPrimaryAccent}
      {accentMedian}
      {renderSecondaryAccent}
    </AccentGroup>
  );

  return (
    <CardWrapper featured={featured}>
      <CardImageWrapper featured={featured} href={mainLink}>
        {ctaImage}
      </CardImageWrapper>
      <CardBody>
        {parentPath !== 'advocacy' && renderAccentGroup}
        {titleWrapped}
        {parentPath !== 'advocacy' && renderDescription}
        <CardArrowLink href={mainLink} text="Learn More" />
      </CardBody>
      {featImage}
    </CardWrapper>
  );
};

export default Card;
