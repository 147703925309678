import React, {useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import CategoryBar from './CategoryBar';
import TagList from './TagList';
import ArticleList from './ArticleList';
import FloatingFilterButton from './FloatingFilterButton';
import {_buildArticlePredicate} from './types';
import {
  article as _article,
  tag as _tag,
  category as _category,
  article,
} from '../../interfaces';
import ActiveTags from './ActiveTags';
import styled from 'styled-components';
import useScrollToRef from '../../utils/useScrollToRef';
import {observer, inject} from 'mobx-react';
import qs from 'query-string';

const Stopper = styled.div`
  width: 100%;
  height: 0;
  margin: 0;
`;

const updateUrl = (selectedCategory: string, selectedTags: Set<_tag>) => {
  let params = {
    ...qs.parse(window.location.search),
    category: selectedCategory ? selectedCategory : '',
    tags: selectedTags.size > 0 ? Array.from(selectedTags).join(',') : '',
  };

  Object.keys(params).forEach((key) => !params[key] && delete params[key]);

  const url = `${window.location.pathname}${
    Object.keys(params).length > 0 ? `?${qs.stringify(params)}` : ''
  }`;

  history.pushState({}, '', url);
};

// given optional catergory and tags, returns apredicate to match an article.
const buildArticlePredicate: _buildArticlePredicate = (
  activeCategoryId,
  activeTagIds,
) => (article, idx) => {
  // only check article category when activeCategoryId is specified (length != 0)
  if (activeCategoryId && article.category?.slug.current !== activeCategoryId) {
    return false;
  }
  // now that category is checked, skip tags if there aren't any activeTagIds
  if (activeTagIds.size === 0) {
    return true;
  }
  // check each of the stories against activeTagIds, return true at first match
  for (let i = 0; i < article.tags.length; i++) {
    if (activeTagIds.has(article.tags[i].slug.current)) {
      return true;
    }
  }
  // looks like no tags matched, too bad.
  return false;
};

export interface _propsStaticCardGrid {
  articles: _article[];
  categories: _category[];
  tags: _tag[];
  featuredArticle?: article;
  noTaxonomy?: boolean;
}

interface _withDiscoverStore {
  discoverStore?: any;
}

// This component renders a runtime static (ie constant) list of articles,
// tags and categories, and manages their presentational state internally.
// The component closures the passed data and maintains the full list of
// articles regardless of which/how many are displayed
const StaticCardGrid = ({
  articles,
  tags,
  categories,
  featuredArticle,
  noTaxonomy,
  discoverStore,
}: _propsStaticCardGrid & _withDiscoverStore) => {
  const {
    selectedTags,
    removeTag,
    selectedCategory,
    setCategory,
  } = discoverStore;
  const [taglistRef, scrollToTaglistTop] = useScrollToRef();

  const onClearAll = () => {
    removeTag('all');
    setCategory(null);
    scrollToTaglistTop();
  };

  typeof window !== 'undefined' && updateUrl(selectedCategory, selectedTags);
  const predicate = buildArticlePredicate(selectedCategory, selectedTags);

  const tagsAreSelected = !!selectedTags.size;
  const filteredArticles = articles.filter(predicate);

  // don't set featured story if there are categories or tags active
  const featuredArticleId =
    (!selectedCategory && !tagsAreSelected && featuredArticle?.id) || undefined;

  return (
    <div style={{position: 'relative'}}>
      {noTaxonomy ? null : (
        <React.Fragment>
          <CategoryBar allCategories={categories} />
          <TagList allTags={tags} />
          <Stopper />
          <ActiveTags allTags={tags} />
        </React.Fragment>
      )}

      <ArticleList
        {...{
          articles: filteredArticles,
          featuredArticleId,
          onClearAll,
          ref: taglistRef,
        }}
      >
        {noTaxonomy ? null : <FloatingFilterButton />}
      </ArticleList>
    </div>
  );
};

export default inject(`discoverStore`)(observer(StaticCardGrid));
