import styled, {css} from 'styled-components';
import Img from 'gatsby-image';
import {H2, P1} from './../../style/Typography';
import {colors, fonts, query} from './../../style/theme';
import FreeTextArea from './../FreeTextArea';
import desktopBlueCirclesPattern from './../../images/Module-Pattern-Blue-Circles.png';
import mobileBlueCirclesPattern from './../../images/Mobile-Module-Pattern-Blue-Circles.jpg';
import desktopWhiteWavesPattern from './../../images/Module-Pattern-White-Waves.png';
import mobileWhiteWavesPattern from './../../images/Mobile-Module-Pattern-White-Waves.png';
import desktopYellowArrowsPattern from './../../images/Module-Pattern-Yellow-Arrows.jpg';
import mobileYellowArrowsPattern from './../../images/Mobile-Module-Pattern-Yellow-Arrows.jpg';

const handleBackgroundImage = (cardTheme: string) => {
  switch (cardTheme) {
    case 'Left Aligned Blue Circles Pattern with White background':
      return css`
        background: transparent url(${mobileBlueCirclesPattern}) no-repeat top
          left;

        @media (${query.above.tablet}) {
          background: transparent url(${desktopBlueCirclesPattern}) no-repeat
            top left;
        }
      `;
    case 'Right Aligned White Waves Pattern with Green background':
      return css`
        background: rgb(95, 136, 78) url(${mobileWhiteWavesPattern}) no-repeat
          bottom right;

        @media (${query.above.tablet}) {
          background: rgb(95, 136, 78) url(${desktopWhiteWavesPattern})
            no-repeat bottom right;
        }
      `;
    case 'Left Aligned Yellow Arrows Pattern with White background':
      return css`
        background: transparent url(${mobileYellowArrowsPattern}) no-repeat top
          left;

        @media (${query.above.tablet}) {
          background: transparent url(${desktopYellowArrowsPattern}) no-repeat
            top left;
        }
      `;
    default:
      return '';
  }
};

export const Container = styled.section<{
  imagePosition: string;
  cardTheme: string;
}>`
  position: relative;
  padding: 30px 0;
  background-size: 65% !important;

  ${({cardTheme}) => handleBackgroundImage(cardTheme)};

  @media (${query.above.tablet}) {
    height: 100%;
    padding: 0;
    background-size: 30% !important;
  }
`;

export const ContainerWithNoBackgroundImage = styled.div``;

export const Inner = styled.div<{imagePosition: string}>`
  display: grid;
  grid-template-rows: auto 500px;
  grid-template-columns: 100%;
  grid-template-areas:
    'CopyPanel'
    'ImagePanel';
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;

  @media (${query.above.phone}) {
    grid-template-rows: auto 700px;
  }

  @media (${query.above.tablet}) {
    grid-template-rows: 100%;
    grid-template-areas: ${(props) =>
      props.imagePosition === 'left'
        ? `'ImagePanel CopyPanel'`
        : `'CopyPanel ImagePanel'`};
    grid-template-columns: 50% 50%;
  }

  @media (${query.above.small}) {
    grid-template-columns: ${(props) =>
      props.imagePosition === 'left' ? '45% 55%' : '55% 45%'};
  }

  @media (${query.above.large}) {
    grid-template-columns: 50% 50%;
  }
`;

export const ImagePanel = styled.div`
  grid-area: ImagePanel;
  padding: 20px;

  @media (${query.above.tablet}) {
    padding: 10vh 5vw;
    height: auto;
  }

  @media (${query.above.small}) {
    padding: 10vh 5vw;
  }

  @media (${query.above.large}) {
    padding: 150px 100px 100px 150px;
  }
`;

export const Image = styled(Img)`
  width: 100%;
  height: 100%;
  max-height: 700px;
  box-shadow: 15px 30px 30px 0px rgba(0, 0, 0, 0.1);

  @media (${query.above.tablet}) {
    min-height: 500px;
    min-width: 325px;
    max-width: 500px;
  }

  @media (${query.above.small}) {
    max-height: 600px;
  }

  @media (${query.above.large}) {
    max-height: auto;
    min-width: 500px;
  }
`;

export const CopyPanel = styled.div`
  grid-area: CopyPanel;
  display: grid;
`;

export const Content = styled.div<{imagePosition: string}>`
  justify-self: start;
  align-self: start;
  // padding: 50px 20px 20px 20px;
  padding: 20px;

  @media (${query.above.tablet}) {
    justify-self: start;
    align-self: center;
    padding: ${(props) =>
      props.imagePosition === 'left'
        ? '12vh 5vw 8vh 10vw'
        : '12vh 10vw 8vh 5vw'};
  }

  @media (${query.above.small}) {
    padding: 12vh 5vw 8vh 5vw;
  }

  @media (${query.above.large}) {
    justify-self: center;
    padding: 120px 200px 80px 0;
    padding: ${(props) =>
      props.imagePosition === 'left'
        ? '100px 200px 100px 0'
        : '100px 0 100px 0'};
  }
`;

export const ContentHeadline = styled(H2)<{imagePosition: string}>`
  ${fonts.sofiaBold};
  color: ${(props) =>
    props.imagePosition === 'left' ? `${colors.charcoal}` : `${colors.white}`};
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 34px;
  width: 100%;
  margin-bottom: 15px;

  @media (${query.above.tablet}) {
    letter-spacing: -0.88px;
    line-height: 48px;
    font-size: 50px;
    max-width: 460px;
    margin-bottom: 20px;
  }

  @media (${query.above.small}) {
    font-size: 60px;
    letter-spacing: -1.73px;
    line-height: 63px;
    max-width: 460px;
    margin-bottom: 20px;
  }
`;

export const ContentCopy = styled(FreeTextArea)<{imagePosition: string}>`
  ${fonts.sofiaRegular};
  color: ${(props) =>
    props.imagePosition === 'left' ? `${colors.charcoal}` : `${colors.white}`};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  width: 100%;
  max-width: 95%;

  strong {
    font-weight: bold;
  }

  p:last-child {
    margin-top: 30px;
  }

  @media (${query.above.tablet}) {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    max-width: 475px;
  }
`;
