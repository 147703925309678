import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Desktop from './Desktop';
import Mobile from './Mobile';
import {NavProps} from './common';
import {useIsMobile} from '../../hooks/useIsMobile';

export default (props: NavProps) => {
  const [isMobile] = useIsMobile();
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  const data = useStaticQuery(graphql`
    query MainNavigationQuery {
      sanityMainNavigation {
        id: _key
        items {
          id: _key
          href
          linkText
          openInNewWindow
          subItems {
            id: _key
            href
            linkText
            openInNewWindow
          }
          image {
            src {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const Nav = isMobile ? Mobile : Desktop;

  return <Nav {...props} items={data.sanityMainNavigation.items} />;
};
