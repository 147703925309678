import React from 'react';
import CustomBlockContent from '../CustomBlockContent';
import {
  SectionWrapper,
  CopySection,
  Wrapper,
  Headline,
  CopyContainer,
  BlockCopyWrapper,
} from './styles';

export default (props: {eyebrow: string; headline: string; copy: object}) => (
  <SectionWrapper>
    <CopySection>
      <Wrapper>
        <CopyContainer>
          {props.headline ? (
            <Headline as="h1">{props.headline}</Headline>
          ) : null}
          <BlockCopyWrapper>
            <CustomBlockContent blocks={props.copy} />
          </BlockCopyWrapper>
        </CopyContainer>
      </Wrapper>
    </CopySection>
  </SectionWrapper>
);
