import React from 'react';
import {ButtonRound} from '../../style/Buttons';
import {colors} from '../../style/theme';
import styled from 'styled-components';

const noop = () => {};

const StyledSvg = styled.svg`
  width:47%;
  height:47%;
  .primary {
    fill: ${colors.charcoal};
    transition: fill 0.3s;
  }
  /* &:hover .primary {
    fill: ${colors.yellow};
  } */
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 148px;
  justify-content: space-between;
`;

const PrevGlyph = () => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.163 26.163">
    <path
      className="primary"
      d="M7.723 13.081l10.96-10.96L16.56 0 3.48 13.081l13.08 13.082 2.12-2.121z"
      fillRule="nonzero"
    />
  </StyledSvg>
);

const NextGlyph = () => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.163 26.163">
    <path
      className="primary"
      d="M18.44 13.081L7.48 2.121 9.604 0l13.08 13.081-13.08 13.082-2.121-2.121z"
      fillRule="nonzero"
    />
  </StyledSvg>
);

export default ({onPrev, onNext, className}) => (
  <StyledWrapper className={className}>
    <ButtonRound onClick={onPrev || noop}>
      <PrevGlyph />
    </ButtonRound>
    <ButtonRound onClick={onNext || noop}>
      <NextGlyph />
    </ButtonRound>
  </StyledWrapper>
);
