import styled from 'styled-components';
import {colors, query, fonts} from '../../style/theme';
import {H1} from '../../style/Typography';
import FreeTextArea from '../FreeTextArea';
import ButtonLink from '../ButtonLink';
import {MainContentSection} from '../../style/layout';

export const Container = styled(MainContentSection)`
  background-color: ${colors.lightGrayishMagenta};
`;

export const Inner = styled.div`
  margin: -10px 0 -40px;
  @media (${query.above.tablet}) {
    margin: -14px 0 -20px;
  }
  @media (${query.above.small}) {
    margin: -14px 0 -35px;
  }
`;

export const Title = styled(H1)`
  text-align: center;
  color: ${colors.charcoal};
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 15px;
  ${fonts.sofiaBold};

  @media (${query.above.tablet}) {
    font-size: 60px;
    line-height: 63px;
    margin-bottom: 25px;
  }
`;

export const Copy = styled(FreeTextArea)`
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.charcoal};
  ${fonts.sofiaRegular};
`;

export const CardContainer = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 50px;
  padding: 0px 0px 40px 0px;

  @media (${query.above.tablet}) {
    padding: 5vh 2.5vw;
  }

  @media (${query.above(767)}) {
    padding: 20px 0;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }

  @media (${query.above.small}) {
    padding: 35px 0;
    grid-gap: 25px;
  }

  @media (${query.above.large}) {
    padding: 35px 60px;
    grid-gap: 60px;
    margin: 0 auto;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
  padding: 30px 0px;

  @media (${query.above(500)}) {
    padding: 30px;
  }
`;

export const Button = styled(ButtonLink)`
  padding: 0 auto;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  ${fonts.sofiaRegular};
  font-weight: 700;

  @media (${query.above(500)}) {
    padding: 0 45px;
    width: auto;
    font-size: 16px;
    line-height: 16px;
  }
`;
