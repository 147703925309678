import React, {FunctionComponent as fc} from 'react';
import {story as _article} from '../../interfaces';
import {observer, inject} from 'mobx-react';
import Card from '../Card';

// TODO: cards should expose event handlers, and become fully dumb components,
// then those can be used to hook up proper grid behavior, like selecting a
// category, and this file can go away because the behavior will be dictated
// by the container (the CardGrid in this case)
// in the mean time, this component will just blank out the category, as it's
// first intended use is via the advocacy page, with no categories.

export interface propsGridCard {
  article: _article;
  featured?: boolean;
  discoverStore?: any;
}

// Gives Data to shared Card Component
const GridCard: fc<propsGridCard> = ({article, featured, discoverStore}) => {
  const {setCategory} = discoverStore;
  const title = article.title;
  const link = article.fullUrl;
  const description = article.metaDescription || '';
  // const primaryAccent = article?.category?.name || 'General';
  // const primaryAccentLink = `/news?category=${article?.category?.slug.current}`;
  // const primaryAccentEvent = () => {
  //   setCategory(article?.category?.slug.current);
  // };
  const secondaryAccent = article.publishedDate;
  const accent = {
    // primary:
    // {
    //   accent: primaryAccent,
    //   link: primaryAccentLink,
    //   event: primaryAccentEvent,
    // },
    secondary: {
      accent: secondaryAccent,
    },
  };

  return (
    <Card
      title={title}
      link={link}
      image={article?.previewImage || null}
      description={description}
      accent={accent}
      featured={featured}
    />
  );
};

export default inject(`discoverStore`)(observer(GridCard));
