import React from 'react';
import {_propsTagList, _propsTag} from './types';
import styled from 'styled-components';
import theme, {fonts, colors, query} from '../../style/theme';
import {mainContentGutterStyle} from '../../style/layout';
import {H3} from '../../style/Typography';
import {ButtonSmall, CloseButtonRound} from '../../style/Buttons';
import Modal from '../Modal';
import CloseButton from '../../images/Discover_Close.svg';
import {buildTypograpy} from '../../style/Typography';
import {observer, inject} from 'mobx-react';
import OutsideClickHandler from 'react-outside-click-handler';

const OuterFrame = styled.div`
  position: relative;
  height: 0;
`;

const InnerFrame = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: ${colors.white};
  z-index: 1;
`;

const Content = styled.div`
  overflow: scroll;
  margin-bottom: 90px;
  max-height: calc(100vh - 120px);
  height: 100%;

  @media (${query.above.tablet}) {
    overflow: initial;
    margin: 0;
    max-height: none;
    height: auto;
  }
`;

const TagListDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  padding: 15px 15px 0 15px;

  &.hidden {
    display: none;
  }

  hr {
    display: flex;
    flex: 1 1 100%;

    @media (${query.above.tablet}) {
      display: none;
    }
  }

  @media (${query.above.tablet}) {
    ${mainContentGutterStyle}
    padding: 35px;
    box-shadow: 15px 30px 39px 0 rgba(0, 0, 0, 0.1);
  }
`;

// todo - consider a disabled texture for tags that conatin no stories as filtered (this could be expensive)
const TagListItemDiv = styled(ButtonSmall)`
  background-color: ${colors.white};
  color: ${colors.charcoal};
  border: 2px solid #ced2d3;
  transition: all 0.3s;
  margin: 5px 4px;
  height: 55px;

  &:hover {
    border: 2px solid ${colors.yellow};
  }

  &.active {
    background-color: ${colors.yellow};
    border: 2px solid ${colors.yellow};
  }

  @media (${query.above.tablet}) {
    margin: 6px 4px;
  }
`;

const ClearWrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  height: 100px;
  border-top: 1px solid ${colors.lightGray};
  width: 100vw;
  margin-left: -15px;
  box-shadow: 4px 0 6px 0 rgba(0, 0, 0, 0.19);

  @media (${query.above.tablet}) {
    position: static;
    bottom: auto;
    height: auto;
    width: 100%;
    border: none;
    align-items: flex-end;
    justify-content: flex-end;
    box-shadow: none;
  }
`;

const Clear = styled.div<{active?: boolean}>`
  margin: 20px;
  cursor: pointer;
  color: ${colors.charcoal};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 15px;
  ${({active}) =>
    buildTypograpy({
      base: 'p1',
      color: active ? 'green' : 'charcoal',
      bold: true,
    })}

  &:hover {
    color: ${colors.green};
    border-bottom: 2px solid ${colors.green};
    margin-bottom: 18px;
  }

  @media (${query.above.tablet}) {
    margin: 20px 20px 0 20px;
    &:hover {
      margin-bottom: -2px;
    }
  }
`;

const Done = styled(CloseButtonRound)`
  display: flex;
  margin-left: auto;
  margin-right: 25px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);

  @media (${query.above.tablet}) {
    display: none;
  }
`;

const Headline = styled(H3)`
  color: ${colors.charcoal};
  ${fonts.sofiaBold};
  flex: 1 1 100%;
  font-size: 24px;
  letter-spacing: -0.25px;
  line-height: 28px;
  margin: 5px;
  display: flex;

  @media (${query.above.tablet}) {
    display: none;
  }
`;

const TagModal = styled(Modal)`
  display: flex;

  @media (${query.above.tablet}) {
    display: none;
  }
`;

const noop = () => {};

const Tag = ({slug, name, discoverStore}: _propsTag) => {
  const {selectedTags, toggleTag} = discoverStore;

  const tagClassName = selectedTags.has(slug.current) ? 'active' : '';

  return (
    <TagListItemDiv
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        toggleTag(slug.current);
      }}
      className={tagClassName}
      key={slug.current}
      as="div"
    >
      {name}
    </TagListItemDiv>
  );
};

const MobxTag = inject(`discoverStore`)(observer(Tag));

const ReusableTagList = ({allTags, discoverStore}: _propsTagList) => {
  const {removeTag, showTagsList, closeTagsList, selectedTags} = discoverStore;

  return (
    <TagListDiv className={showTagsList ? '' : 'hidden'}>
      <Content>
        <Headline>Choose a Topic</Headline>
        <hr />
        {allTags.map((tag) => (
          <MobxTag {...tag} id={tag.slug.current} />
        ))}
      </Content>
      <ClearWrapper>
        <Clear
          active={!!selectedTags.size}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            removeTag('all');
          }}
        >
          Clear All
        </Clear>
        <Done
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            closeTagsList();
          }}
        >
          <CloseButton />
        </Done>
      </ClearWrapper>
    </TagListDiv>
  );
};

const MobxReusableTagList = inject(`discoverStore`)(observer(ReusableTagList));

const TagList = ({allTags, discoverStore}: _propsTagList) => {
  const {showTagsList, closeTagsList} = discoverStore;

  return (
    <>
      {/* this is the tagslist for desktop */}
      <OutsideClickHandler onOutsideClick={() => closeTagsList()}>
        <OuterFrame>
          <InnerFrame>
            <MobxReusableTagList allTags={allTags} />
          </InnerFrame>
        </OuterFrame>

        {/* this is the modal and tagslist for mobile */}
        {showTagsList && (
          <TagModal
            showModal={showTagsList}
            handleClose={(e) => closeTagsList()}
            enableClose={false}
            backgroundColor={`${colors.white}`}
          >
            <MobxReusableTagList allTags={allTags} />
          </TagModal>
        )}
      </OutsideClickHandler>
    </>
  );
};

export default inject(`discoverStore`)(observer(TagList));
