/*
 *  A generic component that loads a component based on the schema
 *
 *  Takes a component prop that must have id and __typename properties and
 *  attempts to find and import a module with a matching __typename after removing
 *  the Sanity prefix.
 *
 *  Passes all data from the component prop to the loaded component
 */
import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import {pageComponent} from '../interfaces';

interface PageProps {}

const ModuleLoader = ({
  component,
  order,
}: {
  component: pageComponent;
  order: number;
}) => {
  const realComponentName = (
    component?.__typename ||
    component?._type ||
    ''
  ).replace('Sanity', '');
  try {
    let Module = null;
    order = order || 0;
    if (order > 2) {
      Module = loadable(() => import(`./${realComponentName}`));
    } else {
      Module = require(`./${realComponentName}`).default;
    }
    return <Module {...component} />;
  } catch (err) {
    if (process.env.GATSBY_NODE_ENV === 'development') {
      if (/Cannot find module/.test(err.message)) {
        console.warn(err.message);
      }
      console.warn(err);
    }
    return null;
  }
};

ModuleLoader.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired,
    _type: PropTypes.string,
  }),
  order: PropTypes.number,
};

export default ModuleLoader;
