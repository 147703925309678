import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import {query} from '../../style/theme';
import HeroSlide from './HeroSlide';

interface HeroCarouselProps {
  slides: Array<any>;
  slideImages: Array<any>;
}

const Container = styled.section`
  height: 100vh;
  position: relative;

  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
    width: 100%;
    min-height: none;
  }

  @media (${query.above.tablet}) {
    height: 80vh;
    min-height: 650px;

    .slick-slider,
    .slick-list,
    .slick-track {
      height: 100%;
      width: 100%;
      min-height: 650px;
    }
  }

  @media (${query.above(1600)}) {
    height: 85vh;
  }

  .slick-slide > div {
    height: 100%;
    width: 100vw;
  }
`;

const Slick = styled(Slider)`
  position: relative;
  overflow: hidden;

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    z-index: 4;

    div {
      outline: none;
    }
  }
`;

const sliderRef: any = React.createRef();

const HeroCarousel = ({slides, slideImages}: HeroCarouselProps) => {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    sliderRef.current.slickGoTo(activeSlide);
  }, [activeSlide]);

  const settings = {
    dots: false,
    fade: true,
    autoplay: true,
    infinite: true,
    arrows: false,
    afterChange: (currentSlide: any) => setActiveSlide(currentSlide),
    className: 'HeroCarousel',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    autoplaySpeed: 5000,
    cssEase: 'linear',
  };

  const slidesToRender =
    typeof window === 'undefined' ? slides.slice(0, 1) : slides;

  return (
    <Container>
      <Slick ref={(slider) => (sliderRef.current = slider)} {...settings}>
        {slides.length > 0 &&
          slidesToRender.map((slide: any, index: number) => {
            const {_key, _type} = slide;
            return (
              <HeroSlide
                key={`${_type}-${_key}`}
                slide={slide}
                slideImage={slideImages[index]}
                active={index === activeSlide}
                numberOfDots={slides.length}
                activeSlide={activeSlide}
                setActiveSlide={setActiveSlide}
              />
            );
          })}
      </Slick>
    </Container>
  );
};

export default HeroCarousel;
