import styled, {css} from 'styled-components';
import theme, {query} from './theme';

export const gutter = {
  mobile: theme.constants.minGutterMobile,
  desktop: ` max(
    ${theme.constants.minGutter},
    (100% - ${theme.constants.maxContentWidth}) / 2
  )`,
};

export const mainContentGutterStyle = css`
  box-sizing: border-box;
  width: 100%;
  padding-left: ${gutter.mobile};
  padding-right: ${gutter.mobile};

  @media (${query.above.tablet}) {
    padding-left: ${gutter.desktop};
    padding-right: ${gutter.desktop};
  }
`;

export const MainContentSection = styled.section.attrs((props) => ({
  className: `main-content-section ${props.className}`,
}))`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.constants.sectionPaddingMobile} ${gutter.mobile};

  @media (${query.above.tablet}) {
    padding: ${theme.constants.sectionPadding} ${gutter.desktop};
  }
`;
