import React from 'react';
import Alert from '@material-ui/lab/Alert';
import SnackBar from '@material-ui/core/Snackbar';

interface InfoDialogProps {
  children: any;
}


export default (props: InfoDialogProps) => {
  return (
    <SnackBar open={true}>
      <Alert
        severity="info"
        variant="filled"
      >
        {props.children}
      </Alert>
    </SnackBar>
  );
};
