import {tag, category, story} from '../../interfaces';

// standard Array.filter callback shape
export interface _storyPredicate {
  (story: story, idx?: number, lst?: story[]): boolean;
}

export interface _buildStoryPredicate {
  (activeCategoryId: string, activeTagIds: Set<string>): _storyPredicate;
}

export interface _propsStoryList {
  stories: story[];
  featuredStoryId?: string;
  onClearAll?: () => void;
  discoverStore?: any;
}

export interface _propsTagList {
  allTags: tag[];
  showTagsList?: boolean;
  onDone?: () => void;
  discoverStore?: any;
}

export interface _propsCatergoryBar {
  allCategories: category[];
  activeCategoryId: string;
  tagsListShowing: boolean;
  tagsAreSelected: boolean;
  onPickCategory?: (categrotyId: string) => void;
  onToggleTagsList?: () => void;
  discoverStore?: any;
}

export interface _propsFloatingFilterButton {
  tagsListShowing: boolean;
  tagsAreSelected: boolean;
  discoverStore?: any;
  onToggleTagsList: () => void;
}

export interface _propsActiveTags {
  allTags: tag[];
  activeTagIds: Set<string>;
  showTagsList: boolean;
  onToggleTag?: (tagId: string) => void;
  discoverStore?: any;
}

export interface _propsTag {
  slug: {current: string};
  name: string;
  discoverStore?: any;
  id?: string;
}
