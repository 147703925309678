import styled from 'styled-components';
import {H2, P1} from './../../style/Typography';
import {colors, fonts, query} from './../../style/theme';
import FreeTextArea from './../FreeTextArea';

export const Container = styled.div`
  background-color: ${colors.lightGrayishMagenta};
  padding: 30px 0;

  @media (${query.above.tablet}) {
    padding: 0;
  }
`;

export const Inner = styled.div<{imagePosition: string}>`
  display: grid;
  grid-template-rows: auto 540px;
  grid-template-columns: 100%;
  grid-template-areas:
    'CopyPanel'
    'StatCardPanel';
  margin: 0 auto;
  max-width: 1440px;

  @media (${query.above.tablet}) {
    grid-template-rows: 100%;
    grid-template-areas: ${(props) =>
      props.imagePosition === 'left'
        ? `'StatCardPanel CopyPanel'`
        : `'CopyPanel StatCardPanel'`};
    grid-template-columns: 50% 50%;
  }

  @media (${query.above.small}) {
    grid-template-columns: ${(props) =>
      props.imagePosition === 'left' ? '45% 55%' : '55% 45%'};
  }

  @media (${query.above.large}) {
    grid-template-columns: 50% 50%;
  }
`;

export const StatCardPanel = styled.div`
  grid-area: StatCardPanel;
  padding: 20px;
  display: flex;
  align-items: center;

  @media (${query.above.tablet}) {
    padding: 10vh 5vw;
  }

  @media (${query.above.large}) {
    padding: 150px 100px 100px 150px;
  }
`;

export const CopyPanel = styled.div`
  grid-area: CopyPanel;
  display: grid;
`;

export const Content = styled.div<{imagePosition: string}>`
  justify-self: start;
  align-self: start;
  padding: 20px;

  @media (${query.above.tablet}) {
    align-self: center;
    padding: 10vh 5vw 10vh 5vw;
  }

  @media (${query.above.small}) {
    padding: ${(props) =>
      props.imagePosition === 'left'
        ? '10vh 5vw 10vh 10vw'
        : '10vh 10vw 10vh 5vw'};
  }

  @media (${query.above.large}) {
    justify-self: center;
    padding: 100px 200px 100px 0;
    padding: ${(props) =>
      props.imagePosition === 'left'
        ? '100px 200px 100px 0'
        : '100px 0 100px 0'};
  }
`;

export const Headline = styled(H2)`
  ${fonts.sofiaBold};
  color: ${colors.charcoal};
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 34px;
  width: 100%;
  margin-bottom: 15px;

  @media (${query.above.tablet}) {
    letter-spacing: -0.88px;
    line-height: 48px;
    font-size: 50px;
    max-width: 460px;
    margin-bottom: 20px;
  }

  @media (${query.above.small}) {
    font-size: 60px;
    letter-spacing: -1.73px;
    line-height: 63px;
    max-width: 460px;
    margin-bottom: 20px;
  }
`;

export const Copy = styled(FreeTextArea)`
  ${fonts.sofiaRegular};
  color: ${colors.charcoal};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  width: 100%;
  max-width: 95%;

  strong {
    font-weight: bold;
  }

  p:last-child {
    margin-top: 30px;
  }

  @media (${query.above.tablet}) {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    max-width: 460px;
  }
`;
