import styled from 'styled-components';
import {query} from '../../style/theme';

export const JumplinkWrap = styled.div<{visible: boolean}>`
  max-width: 685px;
  margin: 0 auto;
  padding: 72px 0 41px 0;

  @media (${query.upto.tablet}) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    max-width: none;
    margin: 0;
    padding: 0;
    z-index: 4;

    display: ${({visible}) => (visible ? 'block' : 'none')};

    > button {
      width: 100%;
      padding: 0;
      border-radius: 0;
      border: 0;
      margin: 0;
      height: 60px;
      line-height: 60px;
      max-height: none;

      &:hover {
        background-color: #ffd04f;
        color: #414042;
      }
    }
  }
`;
