import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../images/facebook.svg';
import TwitterIcon from '../../images/twitter.svg';
import EmailIcon from '../../images/email.svg';
import {query} from '../../style/theme';

const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet?text=';
const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php?u=';

const Wrapper = styled.div`
  max-width: 685px;
  margin: 0 auto;
  margin-bottom: ${(props) =>
    props.bottomSpacing ? '80px !important' : '40px !important'};
  @media (${query.upto.tablet}) {
    margin-bottom: ${(props) =>
      props.bottomSpacing ? '60px !important' : '20px !important'};
    padding: 0 20px;
  }
`;

const Container = styled.ul`
  display: flex;
  justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
  align-items: center;
`;

const Item = styled.li`
  margin-left: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s linear;

  a,
  button {
    display: block;
    border: 2px solid #f6c142;
    border-radius: 50%;
    background: #f6c142;
    cursor: pointer;
  }
  a {
    width: 41px;
    height: 41px;
  }
  button {
    width: 45px;
    height: 45px;
  }
  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &:first-child {
    margin-left: 0;
  }

  *[fill] {
    transition: all 0.1s linear;
  }
  *[stroke] {
    transition: all 0.1s linear;
  }

  &:hover {
    *[stroke='#414042'] {
      stroke: #f6c142;
    }
    *[fill='#414042'] {
      fill: #f6c142;
    }
    *[fill='#F6C142'],
    *[fill='#f6c142'] {
      fill: #414042;
    }
  }
`;

const Button = styled.button`
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  background: transparent;
  border: none;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  width: auto;
`;
const Link = styled.a``;

class ShareSocialIcons extends React.Component {
  defaultProps = {
    bottomSpacing: false,
    centered: false,
  };

  state = {
    encodedLink: null,
    encodedTitle: null,
  };

  onClickSocialShare = (e, network, title) => {
    e.preventDefault();

    let url = null,
      {encodedLink, encodedTitle} = this.state;

    switch (network) {
      case 'twitter':
        url = `${TWITTER_SHARE_URL}${encodedTitle}%20${encodedLink}`;
        break;
      case 'facebook':
        url = `${FACEBOOK_SHARE_URL}${encodedLink}&quote=${encodedTitle}`;
        break;
      default:
        url = null;
    }

    window.open(url, 'From Single use to Reuse', 'width=600,height=400');
  };

  componentDidMount = () => {
    const currentURL = window.location.href;
    this.setState({
      encodedLink: encodeURIComponent(currentURL),
      encodedTitle: encodeURIComponent(
        this.props.copy || 'From+Single+Use+to+Reuse',
      ),
    });
  };

  render() {
    const {encodedTitle, encodedLink} = this.state;

    return (
      <Wrapper bottomSpacing={this.props.bottomSpacing}>
        <Container centered={this.props.centered}>
          <Item>
            <Button
              data-analytics-id="twitter-share"
              onClick={(e) => this.onClickSocialShare(e, 'twitter')}
            >
              <TwitterIcon />
            </Button>
          </Item>

          <Item>
            <Button
              data-analytics-id="facebook-share"
              onClick={(e) => this.onClickSocialShare(e, 'facebook')}
            >
              <FacebookIcon />
            </Button>
          </Item>

          <Item>
            <Link
              data-analytics-id="email-share"
              href={`mailto:mailto:?subject=${encodedTitle}&body=${encodedLink}`}
              target="popup"
            >
              <EmailIcon />
            </Link>
          </Item>
        </Container>
      </Wrapper>
    );
  }
}

export default ShareSocialIcons;
