import {useStaticQuery, graphql} from 'gatsby';
import {GlossaryItemInterface} from '../../interfaces';

interface GlossaryItemQuery {
  terms: {
    edges: [{node: GlossaryItemInterface}];
  };
}

const useGlossaryItems = () => {
  const data = useStaticQuery(graphql`
    query {
      terms: allSanityGlossaryItem {
        edges {
          node {
            id: _id
            term
            slug {
              current
            }
            definition
          }
        }
      }
    }
  `) as GlossaryItemQuery;

  return data.terms.edges
    .map(item => item.node)
    .sort((a: any, b: any) => a.term.localeCompare(b.term));
};

export default useGlossaryItems;
