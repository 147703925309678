import React, {useRef, useState} from 'react';
import Page from '../Page';
import {OutreachPageProps} from '../../interfaces';
import {propsPageStyle} from '../../style/PageStyle';
import styled from 'styled-components';
import ModuleLoader from '../ModuleLoader';
import CenteredInteriorHero from '../CenteredInteriorHero';
import OutreachForm from '../OutreachForm';
import {JumplinkWrap} from './styles';
import {Button} from '../../style/Buttons';
import scrollToElement from 'scroll-to-element';
import {InView} from 'react-intersection-observer';
import {query} from '../../style/theme';
import {TextArea} from '../EmbeddedFreeText';

const OutreachPage = styled(Page)``;

const BodySection = styled.div`
  ${TextArea} {
    margin: 0 auto 25px;
  }

  @media (${query.upto.tablet}) {
    > *:last-child {
      margin-bottom: 0;
    }
    > *:first-child {
      margin-top: 45px;
    }
  }
`;

export default (props: OutreachPageProps & propsPageStyle) => {
  let body = props.body || [];
  let formRef = useRef(null);
  const [isFormVisible, setFormVisible] = useState(false);

  return (
    <OutreachPage parentPath="news" showPreFooter {...props}>
      <CenteredInteriorHero {...props.headerHero} />
      <JumplinkWrap visible={!isFormVisible}>
        <Button
          onClick={() => {
            scrollToElement(formRef.current);
          }}
        >
          Email Your Legislator
        </Button>
      </JumplinkWrap>
      <BodySection>
        {body.map((component, index) => {
          if (component === null) return null;
          return (
            <ModuleLoader
              component={component}
              key={`module-child-${index}${
                (component && component.id) || Math.random().toString()
              }`}
            />
          );
        })}
      </BodySection>
      <InView as="div" onChange={(inView) => setFormVisible(inView)}>
        <span ref={formRef}>
          <OutreachForm
            campaignId={props.campaign && props.campaign.campaignId}
            contactListId={props.campaign && props.campaign.formId}
            message={props.campaign && props.campaign.message}
            messageEditable={props.campaign && props.campaign.messageEditable}
          />
        </span>
      </InView>
    </OutreachPage>
  );
};
