import React, {useState, useEffect, useRef} from 'react';
import {
  Container,
  Inner,
  UL,
  LI,
  Term,
  Definition,
  ButtonGroup,
  Button,
} from './styles';
import useGlossaryItems from './useGlossaryItems';
import {GlossaryListProps, GlossaryItemInterface} from './../../interfaces';

const GlossaryItem = ({term, definition, content}: GlossaryItemInterface) => {
  const [isOpen, setOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const definitionWrap = useRef(null);

  const getContentHeight = () => {
    return definitionWrap.current ? definitionWrap.current.offsetHeight : 0;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        setContentHeight(getContentHeight());
      });
    }
    setContentHeight(getContentHeight());
  }, []);

  return (
    <LI>
      <Term
        isOpen={isOpen}
        content={content}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        {term}
        {':'}
      </Term>
      <Definition contentHeight={contentHeight} isOpen={isOpen}>
        <div ref={definitionWrap}>{definition}</div>
      </Definition>
    </LI>
  );
};

const GlossaryList = (props: GlossaryListProps) => {
  const sortedGlossaryItems = useGlossaryItems();
  const {downloadButton} = props;

  return (
    <Container>
      <Inner>
        <UL>
          {sortedGlossaryItems &&
            sortedGlossaryItems.map((glossaryItem: any, index: number) => {
              return (
                <GlossaryItem
                  key={`glossary-item-${index}`}
                  {...glossaryItem}
                  // if index === 0 just display the Alphabet
                  // else check if the present term first letter and the previous term first letter are same
                  //    if same do not display the alphabet
                  //    else display the alphabet
                  content={
                    index === 0
                      ? `${glossaryItem.term[0].toUpperCase()}`
                      : sortedGlossaryItems[index].term[0].toUpperCase() !==
                        sortedGlossaryItems[index - 1].term[0].toUpperCase()
                      ? `${glossaryItem.term[0].toUpperCase()}`
                      : ''
                  }
                />
              );
            })}
        </UL>
        {downloadButton?.buttonText &&
          (downloadButton?.href || downloadButton?.file?.file?.asset?.url) && (
            <ButtonGroup>
              <Button {...downloadButton} />
            </ButtonGroup>
          )}
      </Inner>
    </Container>
  );
};

export default GlossaryList;
