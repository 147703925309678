import React from 'react';
import styled from 'styled-components';
import {_propsActiveTags} from './types';
import {ButtonSmall} from '../../style/Buttons';
import {mainContentGutterStyle} from '../../style/layout';
import Close from '../../images/close-tag-icon.svg';
import {colors, query} from '../../style/theme';
import {observer, inject} from 'mobx-react';

const Wrapper = styled.div`
  background: ${colors.lightGrayishMagenta};
  ${mainContentGutterStyle}
  display: flex;
  padding-top: 20px;
  margin-bottom: -28px;
  background: ${colors.lightGrayishMagenta};

  @media (${query.above.tablet}) {
    padding-top: 24px;
    margin-bottom: 0;
  }
`;

const ScrollPanel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  mask-image: linear-gradient(to left, transparent 2%, black 8%);
  overflow-x: auto;
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }
  & {
    scrollbar-width: none; /* Firefox */
  }
`;

const ActiveTag = styled(ButtonSmall)`
  padding: 10px 15px;
  line-height: 20px;
  border: solid ${colors.yellow} 2px;
`;

const CloseIconWrapper = styled.div`
  padding-left: 15px;
  margin-top: 3px;
  width: 12px;
  height: 12px;
  line-height: 12px;
`;

const ActiveTags = ({
  allTags,
  showTagsList,
  discoverStore,
}: _propsActiveTags) => {
  const {selectedTags, removeTag} = discoverStore;
  return (
    <>
      {!showTagsList && selectedTags.size > 0 && (
        <Wrapper>
          <ScrollPanel>
            {allTags
              .filter((t) => selectedTags.has(t.slug.current))
              .map((tag, index) => {
                return (
                  <ActiveTag
                    as="div"
                    key={tag.id}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      removeTag(tag.slug.current);
                    }}
                  >
                    {tag.name}
                    <CloseIconWrapper>
                      <Close />
                    </CloseIconWrapper>
                  </ActiveTag>
                );
              })}
          </ScrollPanel>
        </Wrapper>
      )}
    </>
  );
};

export default inject(`discoverStore`)(observer(ActiveTags));
