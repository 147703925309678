const envOverride = (key: string, dft: string): string => {
  let override = process.env[key];
  return override ? String(override) : dft;
};

export const conf = {
  portalId: envOverride('GATSBY_HUBSPOT_PORTAL_ID', '6767731'),
  formId: {
    emailSignup: envOverride(
      'GATSBY_HUBSPOT_FORM_ID_EMAIL_SIGNUP',
      'bfdf0e17-e4b2-4637-9774-67283f782a12',
    ), // TODO: this is the wrong id ~awt
    doNotSell: envOverride(
      'GATSBY_HUBSPOT_FORM_ID_DO_NOT_SELL',
      '4b689fbb-a34d-4161-b427-d624ddc41ce9',
    ),
  },
};

// post(formId) returns async funtion to use for form submission
export const post = (formId: string) => async (formData: FormData) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${conf.portalId}/${formId}`;

  const fields = Array.from(formData.entries()).map((item) => {
    return {
      name: item[0],
      value: item[1],
    };
  });

  const data = {
    submittedAt: Date.now() / 1000,
    fields,
    context: {
      pageUri: window.location.pathname,
      pageName: document.querySelector('title')?.innerText || 'unknown',
    },
  };

  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
};

export const saveContact = post(conf.formId.emailSignup);
export const submitDoNotSellRequest = post(conf.formId.doNotSell);
