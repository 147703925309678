import React, {FunctionComponent as fc} from 'react';
import {_propsArticleList} from './types';
import {article as _article} from '../../interfaces';
import styled from 'styled-components';
import GridCard from './GridCard';
import {query, colors} from '../../style/theme';
import {P1, H0} from '../../style/Typography';
import {Button} from '../../style/Buttons';
import {MainContentSection} from '../../style/layout';

const Wrapper = styled(MainContentSection)`
  background: ${colors.lightGrayishMagenta};
  @media (${query.above.tablet}) {
    padding-top: 40px;
  }
`;

const List = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  @media (${query.above.tablet}) {
    grid-gap: 42px;
    grid-template-columns: 1fr 1fr;
  }
  @media (${query.above.medium}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const NoResults = styled.div<{show?: boolean}>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  & > * {
    max-width: 702px;
    margin: 18px 0;
  }
`;

const noop = () => {};

const ArticleList: fc<_propsArticleList> = ({
  articles,
  featuredArticleId,
  onClearAll = noop,
  children,
}) => {
  return (
    <Wrapper>
      <List>
        {articles.map((article: _article) => (
          <GridCard
            article={article}
            key={article.id}
            featured={!!featuredArticleId && article.id === featuredArticleId}
          />
        ))}
      </List>
      <NoResults show={!articles.length}>
        <H0 as="h2" responsive>
          No results found.
        </H0>
        <P1 responsive>
          There are no results that match all of your keyword selections. Please
          deselect one or more of the keyword filters to expand your results.
        </P1>
        <Button onClick={() => onClearAll()}>Search All</Button>
      </NoResults>
      {children}
    </Wrapper>
  );
};

export default ArticleList;
