import React from 'react';
import CustomBlockContent from '../CustomBlockContent';
import {
  Container,
  Inner,
  StatCardPanel,
  CopyPanel,
  Content,
  Headline,
  Copy,
} from './styles';
import StatCard from './StatCard';
import {TwoColumnStatsProps} from './../../interfaces';
import {P1} from './../../style/Typography';

const TwoColumnStats = (props: TwoColumnStatsProps) => {
  const {twoColumnStatCards, twoColumnStatImages, id} = props;
  return (
    <Container>
      {twoColumnStatCards.map((twoColumnStatCard: any, index: number) => {
        return (
          <React.Fragment key={`${index}-${id}`}>
            <Inner imagePosition={twoColumnStatCard.statCardPosition}>
              <StatCardPanel>
                <StatCard
                  card={twoColumnStatImages[index].statCard[0]}
                  statCard={twoColumnStatCard.statCard[0]}
                  cardIndex={`card-${index}`}
                />
              </StatCardPanel>
              <CopyPanel>
                <Content imagePosition={twoColumnStatCard.statCardPosition}>
                  <Headline>{twoColumnStatCard.headline}</Headline>
                  <Copy>
                    <CustomBlockContent
                      blocks={twoColumnStatCard.description}
                    />
                  </Copy>
                </Content>
              </CopyPanel>
            </Inner>
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default TwoColumnStats;
