import React from 'react';
import {colors, themeColor} from '../../style/theme';
import styled, {CSSObject} from 'styled-components';

interface propsSvgIcon {
  color?: themeColor;
  color2?: themeColor;
  scale?: number;
  className?: string;
}

const colorable = ({color, color2}: propsSvgIcon) => {
  const pri: CSSObject = {fill: colors[color || 'charcoal']};
  const sec: CSSObject = {fill: colors[color2 || 'charcoal']};
  return {'.primary': pri, '.secondary': sec};
};

const SVG = styled.svg<propsSvgIcon>`
  ${colorable}
`;

// todo: refactor so we can just pass in children and don't have to remember to proxy props
// also props like 'color' are proxied and shouldn't be
export const DownChev = (props: propsSvgIcon) => (
  <SVG {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      className="primary"
      d="M10 17l-10-10l2-2l8 8l8 -8l2 2z"
      fillRule="nonzero"
    />
  </SVG>
);

export const Close = (props: propsSvgIcon) => (
  <SVG {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      className="primary"
      d="M10 8l-8-8l-2 2l8 8l-8 8l2 2l8-8l8 8l2-2l-8-8l8-8l-2-2z"
      fillRule="nonzero"
    />
  </SVG>
);

export const Filter = (props: propsSvgIcon) => (
  <SVG {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g className="primary" fillRule="evenodd">
      <path d="M18.25 1.88L10 11.11 1.75 1.88z" />
      <path d="M8.35 6.45l2.75 2.44.1 6.79-2.95 2.44z" />
    </g>
  </SVG>
);

//todo: implement some kind of wrapper that does stuff like this (see floating filter button)
/*
const FilterIconBox = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
  }
`;
*/
