import React from 'react';
import Page from '../Page';
import {ArticlePageProps} from '../../interfaces';
import {propsPageStyle} from '../../style/PageStyle';
import styled from 'styled-components';
import {query} from '../../style/theme';
import ModuleLoader from '../ModuleLoader';
import ShareSocialIcons from '../ShareSocialIcons';

const ArticlePage = styled(Page)`
  main > * {
    margin-bottom: 60px;

    &:last-child,
    &.main-content-section {
      margin-bottom: 0;
    }

    @media (${query.upto.tablet}) {
      margin-bottom: 43px;

      &:last-child,
      &.main-content-section {
        margin-bottom: 0;
      }
    }
  }
`;

export default (props: ArticlePageProps & propsPageStyle) => {
  let body = props.body || [];

  body = body.map((component, index) => {
    if (component === null) return null;
    return (
      <ModuleLoader
        component={component}
        key={`module-child-${index}${
          (component && component.id) || Math.random().toString()
        }`}
      />
    );
  });

  const shareIcons = (
    <ShareSocialIcons copy={props.socialShareDescription || props.metaTitle} />
  );

  body.splice(1, 0, shareIcons);

  const endShareIcons = (
    <ShareSocialIcons
      copy={props.socialShareDescription || props.metaTitle}
    />
  );

  const glossaryIndex = body.findIndex((c) => {
    return (
      c &&
      c.props &&
      c.props.component &&
      c.props.component.__typename === 'SanityGlossaryCTA'
    );
  });

  const endSocial =
    glossaryIndex > -1
      ? glossaryIndex
      : body.length - props.endSocialIndex;
  body.splice(endSocial, 0, endShareIcons);

  return (
    <ArticlePage {...props} parentPath={props.parentPath}>
      {body}
      {props.children}
    </ArticlePage>
  );
};
