import React, {useRef} from 'react';
import Logo from '../../../images/logo-with-slogan-sm.svg';
import {NavWrapper, Inner, LogoWrap, SignupButton} from './styles';
import {NavProps} from '../common';
import NavList from '../NavList';
import AlertBar from '../../AlertBar';
import {useNavState, scrollToHero} from '../utils';
import {observer, inject} from 'mobx-react';

const DesktopNav = (props: NavProps) => {
  const isHome = props.pagePath === '/';
  const wrapperRef = useRef(null);
  const {isAtTop, position, top, transition} = useNavState(wrapperRef);
  const styleState = isHome && isAtTop ? 'transparent' : 'solid';

  const {showAlertBar} = props.store;

  return (
    <NavWrapper
      style={{
        position,
        top,
        transition,
      }}
      styleState={styleState}
      ref={wrapperRef}
    >
      <AlertBar showAlertBar={showAlertBar} />

      <Inner>
        <LogoWrap styleState={styleState} href="/">
          <Logo />
        </LogoWrap>

        <NavList
          items={props.items}
          styleState={styleState}
          pagePath={props.pagePath}
        >
          <SignupButton
            onClick={(e) => {
              e.preventDefault();
              scrollToHero();
            }}
            styleState={styleState}
          >
            Sign Up
          </SignupButton>
        </NavList>
      </Inner>
    </NavWrapper>
  );
};

export default inject(`store`)(observer(DesktopNav));
