import React from 'react';
import styled from 'styled-components';
import {fonts, colors, query} from '../../style/theme';

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 99px;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;

  @media (${query.upto.tablet}) {
    padding-top: 19px;
  }
`;

const QuoteMark = styled.div`
  color: #709f5a;
  ${fonts.bioSansBold};
  font-size: 100px;
  line-height: 50px;
  margin-bottom: 10px;

  @media (${query.upto.tablet}) {
    font-size: 80px;
    line-height: 10px;
  }
`;

const Quote = styled.blockquote`
  ${fonts.notoSerifRegular};
  font-size: 31px;
  line-height: 46px;
  text-align: center;
  color: ${colors.green};
  margin-bottom: 20px;
  letter-spacing: -0.16px;

  @media (${query.upto.tablet}) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 18px;
  }
`;

const Citation = styled.div`
  ${fonts.sofiaRegular};
  text-align: center;
  color: #414042;

  @media (${query.upto.tablet}) {
    font-size: 12px;
    line-height: 17px;
  }
`;

const Name = styled.div`
  ${fonts.sofiaBold};
  font-size: 16px;
  letter-spacing: 1.15px;
`;

const Title = styled.div``;

interface ComponentProps {
  quote: string;
  name: string;
  title: string;
  employer: string;
}

export default ({quote, name, title, employer}: ComponentProps) => (
  <Wrap>
    <QuoteMark>“</QuoteMark>
    <Quote>{quote}</Quote>
    <Citation>
      <Name>{name}</Name>
      <Title>
        {title}
        {employer && title && `, ${employer}`}
      </Title>
    </Citation>
  </Wrap>
);
