import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import CustomBlockContent from '../CustomBlockContent';
import {colors, fonts, query} from './../../style/theme';
import {H3, P1} from '../../style/Typography';
import TextLink from './../TextLink';
import FreeTextArea from './../FreeTextArea';
import LazyLoad from 'react-lazyload';

interface StaticInfographicCardProps {
  card: any;
  statCard: any;
  cardIndex: string;
}

export const CardWrapper = styled.div`
  max-width: 375px;
  background-color: ${colors.white};
  margin: 0 auto;
  box-shadow: 15px 30px 30px 0px rgba(0, 0, 0, 0.1);
`;

export const CardImage = styled(Img)<{cardIndex: string}>`
  align-items: center;
  margin: 30px auto;
  max-width: ${(props) => (props.cardIndex === 'card-1' ? '215px' : '200px')};

  @media (${query.above(767)}) {
    max-width: ${(props) => (props.cardIndex === 'card-1' ? '160px' : '150px')};
  }

  @media (${query.above.small}) {
    max-width: ${(props) => (props.cardIndex === 'card-1' ? '215px' : '200px')};
  }
`;

export const CardTitle = styled(H3)`
  text-align: center;
  padding: 25px 0 5px 0;
  color: ${colors.charcoal};
  font-size: 24px;
  letter-spacing: -0.25px;
  line-height: 28px;
  ${fonts.sofiaBold};

  @media (${query.above(767)}) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (${query.above.small}) {
    font-size: 25px;
    letter-spacing: -0.32px;
    line-height: 38px;
  }
`;

export const CardCopy = styled(FreeTextArea)`
  margin: 25px 25px 10px 20px;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.charcoal};
  ${fonts.sofiaRegular};
  text-align: center;

  strong {
    ${fonts.sofiaBold};
  }

  @media (${query.above(767)}) {
    font-size: 16px;
    line-height: 25px;
    margin: 20px 30px;
    min-height: 180px;
  }

  @media (${query.above(1000)}) {
    min-height: 150px;
  }

  @media (${query.above(1135)}) {
    min-height: 100px;
  }
`;

export const LinkWrapper = styled.div`
  text-align: center;
  padding: 0 0 30px 0;
  vertical-align: bottom;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.charcoal};
  ${fonts.sofiaRegular};

  @media (${query.above.tablet}) {
    padding: 0 25px 30px 25px;
    font-size: 16px;
    line-height: 25px;
  }
`;

export const CardContent = styled.div`
  margin-top: 15px;
`;

const StaticInfographicCard = ({
  card,
  statCard,
  cardIndex,
}: StaticInfographicCardProps) => {
  return (
    <React.Fragment>
      <CardWrapper>
        <CardTitle as="h3">{statCard.headline}</CardTitle>
        <LazyLoad>
          <CardImage
            fluid={card.image.src.asset.fluid}
            alt={card.image.alt}
            title={card.image.title}
            cardIndex={cardIndex}
          />
        </LazyLoad>
        <CardContent>
          <CardCopy>
            <CustomBlockContent blocks={statCard.copy} />
          </CardCopy>
          <LinkWrapper>
            Source: <TextLink {...statCard.link} />
          </LinkWrapper>
        </CardContent>
      </CardWrapper>
    </React.Fragment>
  );
};

export default StaticInfographicCard;
