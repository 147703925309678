import React from 'react';
import {colors} from '../../style/theme';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  width: 23%;
  height: 23%;
  .primary {
    stroke: ${colors.yellow};
  }
`;

export default () => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71 70">
    <g
      transform="translate(2.3 2)"
      className="primary"
      strokeWidth="4"
      fill="none"
      fillRule="evenodd"
    >
      <circle cx="33" cy="33" r="33" />
      <path d="M20.2 34.3l11.5 9.5 15.8-22.7" />
    </g>
  </StyledSvg>
);
