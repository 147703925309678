import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import {query, colors} from '../../style/theme';
import {buildTypograpy as font, H3} from '../../style/Typography';
import Link from '../Link';
import TextLink, {ArrowLink} from '../TextLink';


export const CTACardWrapperStyles = `
  margin: 20px;
`;

export const FeaturedStoryStyles = `
  grid-column-start: 1;
  grid-column-end: -1; /* entire row, even if that's just 1 column */
  grid-row-start: 1;
  &:hover {
    top: 0;
  }
  @media (${query.above.tablet}) {
    display: table;
    flex-direction: row-reverse;
  }
`;

export const CardWrapper = styled.div`
  color: ${colors.charcoal};
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.07);
  transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  position: relative;

  border-radius: 5px;
  overflow: hidden;
  background: white;

  @media (${query.above.tablet}) {
    :hover {
      transform: translateY(-10px);
      box-shadow: 0 36px 18px 4px rgba(0, 0, 0, 0.07);
    }
  }
  ${(props) => props.featured === true ? `${FeaturedStoryStyles}` : null}
`;


const aspectRatio = 9 / 16; // height / width
// const aspectRatio = 5 / 4; // height / width
// aspect ratio container, contained img to fill
const featuredCardHead = `
  @media (${query.above.tablet}) {
    display: none;
  }
`;
export const CardImageWrapper = styled(Link)`
  display: block;
  text-decoration: none;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: ${aspectRatio * 100}%;
  position: relative;
  ${(props) => (props.featured === true ? `${featuredCardHead}` : null)}
  background: ${colors.payneGrey};
`;

export const FeaturedImageLink = styled(Link)`
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const FeaturedImage = styled(BackgroundImage)`
  height: 100%;
  background-position: center center;
  background-size: cover;

  display: none;
  width: 0;
  padding: 0;
  @media (${query.above.tablet}) {
    display: table-cell;
    width: 40%;
    /* new continer layout is 60% : 40% */
    padding-bottom: ${aspectRatio * 40}%;
  }
`;

export const CardImage = styled(Img).attrs({
  // otherwise gatsby-image yeets `position: relative` inline in tag
  style: {position: 'abosolute'},
})`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 32px;
  height: 100%;
  h3 {
    flex: 1;
  }
`;

export const CardArrowLink = styled(ArrowLink)`
  align-self: flex-end;
  margin-top: 16px;
  flex: 1;
  align-items: flex-end;
  svg {
    margin-bottom: 2px;
  }
`;


const FeaturedTitleStyles = `
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.38px;
  margin-bottom: 7px;
`;

export const Title = styled(H3)`
  margin-bottom: 15px;
  color: ${colors.charcoal};
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${colors.lightCharcoal};
  }
  ${(props) => (props.featured === true ? `${FeaturedTitleStyles}` : null)}
`;
export const TitleLink = styled(Link)`
  text-decoration: none;
  color: ${colors.charcoal};
`;

export const Description = styled.div``;


const FeaturedAccentGroupStyles = `
  margin-bottom: 8px;
  font-size: 16px;
`;
export const AccentGroup = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  ${(props) => (props.featured === true ? `${FeaturedAccentGroupStyles}` : null)}
`;
export const PrimaryAccent = styled(TextLink)``;
export const SecondaryAccent = styled.span`
  ${font({
    base: 'p3',
  })}
`;
