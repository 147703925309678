import React from 'react';
import styled from 'styled-components';
import {P1, typographyProps} from '../../style/Typography';
import theme, {colors, themeColor} from '../../style/theme';
import {FunctionComponent} from 'react';

interface colorProps {
  color?: themeColor;
  borderColor?: themeColor;
  placeholderColor?: themeColor;
}

const borderColorFn = ({color, borderColor}: colorProps): string =>
  colors[borderColor || color || 'charcoal'];

const placeholderColorFn = ({color, placeholderColor}: colorProps): string =>
  colors[placeholderColor || color || 'lightCharcoal'];

// P1 base styles, use as later to get input element
const InputStyle = styled(P1)`
  background: transparent;
  border: 0;
  border-bottom: solid ${theme.constants.inputBorderWidth} ${borderColorFn};
  width: 100%;
  outline: none;

  ::placeholder {
    color: ${placeholderColorFn};
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    font-weight: inherit;
  }
`;

interface TextInputProps extends typographyProps, colorProps {
  [index: string]: any;
}

const TextInput: FunctionComponent<TextInputProps> = (
  props: TextInputProps,
) => <InputStyle as="input" {...props} />;

export default TextInput;
