import React from 'react';
import styled from 'styled-components';
import {Button} from '../../style/Buttons';
import {colors, query} from '../../style/theme';
import {_propsFloatingFilterButton} from './types';
import {Filter as FilterIcon} from '../SvgIcons';
import {observer, inject} from 'mobx-react';

const Layout = styled.div`
  position: sticky;
  bottom: 15px;
  display: flex;
  justify-content: flex-end;
  margin: 10px 15px -40px 0;
`;

const FilterButton = styled(Button)`
  /* margin: 0 15px 20px auto; */
  display: flex;
  height: 48px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  padding: 0 16px;
  &:hover {
    background-color: ${colors.white};
  }

  @media (${query.above.tablet}) {
    display: none;
  }
`;

const FilterIconBox = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
  }
`;

const FloatingFilterButton = ({discoverStore}: _propsFloatingFilterButton) => {
  const {toggleTagsList, showTagsList} = discoverStore;
  const isDocument =
    typeof document !== undefined && typeof document !== 'undefined';
  if (isDocument) {
    document.body.style.overflow =
      showTagsList && window.innerHeight < 769 ? 'hidden' : '';
  }
  return (
    <>
      {!showTagsList && (
        <Layout>
          <FilterButton
            compact
            color="yellow"
            textColor="charcoal"
            hoverColor="white"
            onClick={toggleTagsList}
          >
            <FilterIconBox>
              <FilterIcon color="charcoal" />
            </FilterIconBox>
            FILTER
          </FilterButton>
        </Layout>
      )}
    </>
  );
};

export default inject(`discoverStore`)(observer(FloatingFilterButton));
