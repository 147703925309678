import React from 'react';
import CustomBlockContent from '../CustomBlockContent';
import {StatsHeroProps} from './../../interfaces';
import StaticInfographicCard from './StaticInfographicCard';
import {
  Container,
  Inner,
  Title,
  Copy,
  CardContainer,
  ButtonGroup,
  Button,
} from './Styles';
import {P1} from './../../style/Typography';

const StatsHero = (props: StatsHeroProps) => {
  const {headline, description, cards, statCards, button} = props;
  return (
    <Container>
      <Inner>
        <Title>{headline}</Title>
        <Copy>
          <CustomBlockContent blocks={description} />
        </Copy>
        <ButtonGroup>
          <Button {...button[0]} />
        </ButtonGroup>
        <CardContainer>
          {cards.map((card, index) => {
            return (
              <StaticInfographicCard
                key={`card-${index}`}
                card={card}
                statCard={statCards[index]}
                cardIndex={`card-${index}`}
              />
            );
          })}
        </CardContainer>
      </Inner>
    </Container>
  );
};

export default StatsHero;
