import styled from 'styled-components';
import Img from 'gatsby-image';
import {query} from '../../../style/theme';

export const VideoPreview = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PreviewImage = styled(Img)<{minHeight: number}>`
  max-height: 575px;
  max-width: 1024px;
  width: 100%;
  height: auto;
  bottom: 0;
  z-index: 1;
  min-height: 300px;
  margin: 0 auto;

  @media (${query.above.small}) {
    min-height: ${(props) => `${props.minHeight}px`};
  }

  @media (${query.above.large}) {
    max-width: 1240px;
    max-height: 650px;
  }
`;
