import React, {Fragment} from 'react';
import theme, {fonts, query} from '../../style/theme';
import {Text} from '../../style/Typography';
import styled from 'styled-components';

const Table = styled.table`
  display: table;
  margin: 20px auto;
  border-collapse: collapse;
  border: ${theme.colors.lightGray} dashed 2px;
  td,
  th {
    padding: 3px 5px;
    text-align: left;
    border: ${theme.colors.lightGray} dashed 2px;
    ${fonts.sofiaRegular}
  }
  th {
    ${fonts.sofiaBold}
  }
`;

const QueryRows = () =>
  Object.keys(query.above).map((k) => (
    <tr key={k}>
      <td>{`query.upto.${k}`}</td>
      <td>{query.upto[k]}</td>
      <td>{`query.above.${k}`}</td>
      <td>{query.above[k]}</td>
    </tr>
  ));

const QueryTable = () => {
  return (
    <div>
      <Text responsive size="p2">
        <Table>
          <thead>
            <tr>
              <th>property</th>
              <th>css</th>
              <th>property</th>
              <th>css</th>
            </tr>
          </thead>
          <tbody>
            <QueryRows />
            <tr>
              <td>query.upto(1337)</td>
              <td>${query.upto(1337)}</td>
              <td>query.above(1337)</td>
              <td>${query.above(1337)}</td>
            </tr>
          </tbody>
        </Table>
      </Text>
    </div>
  );
};

export default () => (
  <Fragment>
    <QueryTable />
  </Fragment>
);
