import React, {useState} from 'react';
import TextInput from '../../components/TextInput';
import {Button} from '../../style/Buttons';
import {post as hubSpotPost} from '../../utils/hubSpot';
import {Form} from './styles';
import ThankYouSvg from '../../pages/do-not-sell/_ThankYouSvg';
import ShareIcons from '../ShareSocialIcons';
import Disclaimer from '../OutreachForm/Disclaimer';
interface _propsFormContent {
  onSubmit: React.FormEventHandler;
  busy?: boolean;
  errorMsg?: string;
}

const FormContent = ({onSubmit, busy, errorMsg}: _propsFormContent) => (
  <Form.Container>
    <Form.H2>Take&nbsp;Action</Form.H2>
    <form onSubmit={onSubmit}>
      <TextInput
        name="firstname"
        type="text"
        placeholder="First Name"
        color="charcoal"
        placeholderColor="gray"
        borderColor="gray"
        required
      />
      <TextInput
        name="lastname"
        type="text"
        placeholder="Last Name"
        color="charcoal"
        placeholderColor="gray"
        borderColor="gray"
        required
      />
      <TextInput
        name="email"
        type="email"
        placeholder="Email"
        color="charcoal"
        placeholderColor="gray"
        borderColor="gray"
        required
      />
      <TextInput
        name="zip"
        type="text"
        placeholder="Zip Code"
        color="charcoal"
        placeholderColor="gray"
        borderColor="gray"
        required
      />
      <Button type="submit" color="yellow" textColor="charcoal">
        Sign the Petition
      </Button>
      <div>
        <Form.Msg show={!!errorMsg}>
          <Form.P2 color="red">{errorMsg}</Form.P2>
        </Form.Msg>
        <Form.Msg show={!!busy}>
          <Form.P2 color="gray">submitting...</Form.P2>
        </Form.Msg>
      </div>
    </form>
    <Disclaimer />
  </Form.Container>
);

const SuccessContent = () => (
  <Form.Container centered>
    <ThankYouSvg />
    <Form.H2>
      Thanks for signing.
      <br />
      Now share with friends.
    </Form.H2>
    <Form.P1>
      You’ve helped us take another step forward. Help us build more momentum by
      sharing this with friends and family so they can add their voices to the
      effort.
    </Form.P1>
    <Form.H3>Share:</Form.H3>
    <ShareIcons centered />
  </Form.Container>
);

interface _props {
  hubspotFormId: string;
}

const PetitionForm = ({hubspotFormId}: _props) => {
  const [submitted, setSubmitted] = useState(false);
  const [busy, setBusy] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const submitHubspotForm = hubSpotPost(hubspotFormId);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMsg('');
    setBusy(true);

    const formData = new FormData(event.currentTarget as HTMLFormElement);

    submitHubspotForm(formData)
      .then((resp) => {
        if (resp.status && resp.status === 'error') {
          console.info(resp);
          setErrorMsg(
            'Sorry, there was an error. Please check your information and try again.',
          );
        } else {
          setSubmitted(true);
        }
      })
      .catch((err) => {
        console.warn(err);
        setErrorMsg(
          'We encountered a server error. Please try again in a few minutes.',
        );
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <div>
      {submitted ? (
        <SuccessContent />
      ) : (
        <FormContent {...{submitted, busy, errorMsg, onSubmit}} />
      )}
    </div>
  );
};

export default PetitionForm;
