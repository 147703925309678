import React from 'react';
import {Disclaimer, FinePrintLink} from './styles';

export default () => (
  <Disclaimer>
    By signing, you accept ReusePlastics.org’s{' '}
    <FinePrintLink
      openInNewWindow
      href="https://www.americanchemistry.com/Terms-and-Conditions-of-Use/"
    >
      Terms of Use
    </FinePrintLink>{' '}
    and{' '}
    <FinePrintLink
      openInNewWindow
      href="https://www.americanchemistry.com/Privacy/"
    >
      Privacy Policy
    </FinePrintLink>
    , and agree to receive occasional emails about campaigns on
    ReusePlastics.org. You can unsubscribe at any time.
  </Disclaimer>
);
