import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../images/facebook.svg';
import TwitterIcon from '../../images/twitter.svg';
import InstagramIcon from '../../images/instagram.svg';
import LinkedInIcon from '../../images/linkedin.svg';

const INSTAGRAM_LINK = 'https://www.instagram.com/singleusereuse/';
const TWITTER_LINK = 'https://twitter.com/SingleUseReuse/';
const FACEBOOK_LINK =
  'https://www.facebook.com/Reuse-Plastics-106244251043808/';
const LINKEDIN_LINK =
  'https://www.linkedin.com/showcase/single-use-to-reuse/about/';

interface _props {
  centered?: boolean;
}

const Container = styled.ul<_props>`
  display: flex;
  justify-content: ${(p) => (p.centered ? 'center' : 'flex-start')};
  align-items: center;
`;

const Item = styled.li`
  margin-left: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s linear;

  a {
    display: block;
    width: 41px;
    height: 41px;
    border: 2px solid #f6c142;
    border-radius: 50%;
    background: #f6c142;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &:first-child {
    margin-left: 0;
  }

  *[fill] {
    transition: all 0.1s linear;
  }

  &:hover {
    *[fill='#414042'] {
      fill: #f6c142;
    }
    *[fill='#F6C142'],
    *[fill='#f6c142'] {
      fill: #414042;
    }
  }
`;

const Link = styled.a``;

export default (props: _props) => (
  <Container {...props}>
    <Item>
      <Link href={INSTAGRAM_LINK} target="_blank">
        <InstagramIcon />
      </Link>
    </Item>

    <Item>
      <Link href={TWITTER_LINK} target="_blank">
        <TwitterIcon />
      </Link>
    </Item>

    <Item>
      <Link href={FACEBOOK_LINK} target="_blank">
        <FacebookIcon />
      </Link>
    </Item>

    <Item>
      <Link href={LINKEDIN_LINK} target="_blank">
        <LinkedInIcon />
      </Link>
    </Item>
  </Container>
);
