import styled from 'styled-components';
import {colors, fonts, query} from '../../style/theme';
import TextLink from '../TextLink';
import {Link} from 'gatsby';

export const FooterSection = styled.footer`
  background: ${colors.charcoal};
  padding: 40px 20px;
`;

export const UpperFooterWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  border-bottom: solid 1px ${colors.lightCharcoal};
  padding: 0 0 10px 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;

  @media (${query.above.tablet}) {
    grid-template-columns: 200px auto 210px;
    grid-template-rows: auto;
  }
`;

export const LogoWrapper = styled(Link)`
  display: block;
  width: 180px;
  padding: 10px 0 20px 0;
  margin: 0 auto;

  @media (${query.above.tablet}) {
    padding: 0 0 20px 0;
    margin: 0;
  }
`;

export const UpperLinksGroup = styled.div`
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 40px;

  @media (${query.above.tablet}) {
    display: flex;
  }
`;

export const UpperLinkWrapper = styled.div`
  display: block;
  margin: 0 20px 20px 20px;
  text-align: center;

  @media (${query.above.tablet}) {
    display: inline-block;
  }
`;

export const UpperLink = styled(TextLink)`
  color: ${colors.white};
  font-family: ${fonts.sofiaBold.fontFamily};
  font-weight: ${fonts.sofiaBold.fontWeight};
  font-size: 16px;
  letter-spacing: 0;
  text-align: center;
  padding: 0 0 5px 0;

  &:hover {
    border-color: ${colors.yellow};
  }
`;

export const SocialIconWrapper = styled.div`
  padding: 0 0 20px 0;
  margin: 0 auto;

  @media (${query.above.tablet}) {
    padding: 0;
  }
`;

export const LowerFooterWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;

  @media (${query.above.tablet}) {
    grid-template-columns: 50% auto;
    grid-template-rows: auto;
  }
`;

export const Copyright = styled.p`
  color: ${colors.white};
  font-family: ${fonts.sofiaRegular.fontFamily};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;

  @media (${query.above.tablet}) {
    text-align: left;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`;

export const LowerLinksGroup = styled.div`
  display: block;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 15px 0 10px 0;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  @media (${query.above.tablet}) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    padding: 0;
    text-align: right;
  }
`;

export const LowerLink = styled.a`
  color: ${colors.white};
  display: block;
  font-family: ${fonts.sofiaRegular.fontFamily};
  font-size: 12px;
  letter-spacing: 1.38px;
  padding: 0 0 20px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;

  @media (${query.above.tablet}) {
    display: inline-block;
    padding: 0 0 0 40px;
  }

  &:hover {
    color: ${colors.yellow};
  }
`;
