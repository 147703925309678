import {useRef} from 'react';

// todo ref typing that doesn't require the any type
export default (ref = null) => {
  const scrollRef = useRef(ref);
  const scrollToRef = () => {
    if (typeof (scrollRef.current as any).scrollIntoView === 'function') {
      (scrollRef.current as any).scrollIntoView({behavior: 'smooth'});
    }
  };
  return [scrollRef, scrollToRef] as [any, () => void];
};
