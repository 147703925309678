import {tag, category, article} from '../../interfaces';

// standard Array.filter callback shape
export interface _articlePredicate {
  (article: article, idx?: number, lst?: article[]): boolean;
}

export interface _buildArticlePredicate {
  (activeCategoryId: string, activeTagIds: Set<string>): _articlePredicate;
}

export interface _propsArticleList {
  articles: article[];
  featuredArticleId?: string;
  onClearAll?: () => void;
  discoverStore?: any;
}

export interface _propsTagList {
  allTags: tag[];
  // showTagsList?: boolean;
  // onDone?: () => void;
  discoverStore?: any;
}

export interface _propsCatergoryBar {
  allCategories: category[];
  // activeCategoryId: string;
  // tagsListShowing: boolean;
  // tagsAreSelected: boolean;
  // onPickCategory?: (categrotyId: string) => void;
  discoverStore?: any;
}

export interface _propsFloatingFilterButton {
  //   tagsListShowing: boolean;
  //   tagsAreSelected: boolean;
  discoverStore?: any;
}

export interface _propsActiveTags {
  allTags: tag[];
  // activeTagIds: Set<string>;
  // showTagsList: boolean;
  discoverStore?: any;
}

export interface _propsTag {
  slug: {current: string};
  name: string;
  discoverStore?: any;
  id?: string;
}
