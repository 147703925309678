import React from 'react';
import {
  Container,
  ContainerWithNoBackgroundImage,
  Inner,
  ImagePanel,
  Image,
  CopyPanel,
  Content,
  ContentHeadline,
  ContentCopy,
} from './styles';
import {P1} from './../../style/Typography';
import {isNullOrUndefined} from 'util';
import CustomBlockContent from '../CustomBlockContent';
interface TwoColumnContentProps {
  twoColumnCardImages: Array<any>;
  twoColumnContentCards: Array<any>;
  desktopBlueCirclesPattern: any;
}
interface TwoColumnContentCardProps {
  imagePosition: string;
  headline: string;
  copy: Array<any>;
  cardTheme: any;
}

const TwoColumnContent = (props: any) => {
  const {twoColumnCardImages, twoColumnContentCards} = props;
  return (
    <React.Fragment>
      {twoColumnContentCards.length > 0 &&
        twoColumnContentCards.map(
          (twoColumnContentCard: TwoColumnContentCardProps, index: number) => {
            const {image} = twoColumnCardImages[index];
            const {
              imagePosition,
              headline,
              copy,
              cardTheme,
            } = twoColumnContentCard;
            return (
              <React.Fragment>
                {!isNullOrUndefined(cardTheme) ? (
                  <Container
                    imagePosition={imagePosition}
                    cardTheme={cardTheme}
                  >
                    <Inner imagePosition={imagePosition}>
                      <ImagePanel>
                        {image && image.src && (
                          <Image
                            fluid={image.src.asset.fluid}
                            alt={image.alt}
                            title={image.title}
                          />
                        )}
                      </ImagePanel>
                      <CopyPanel>
                        <Content imagePosition={imagePosition}>
                          <ContentHeadline imagePosition={imagePosition}>
                            {headline}
                          </ContentHeadline>
                          <ContentCopy imagePosition={imagePosition}>
                            <CustomBlockContent blocks={copy} />
                          </ContentCopy>
                        </Content>
                      </CopyPanel>
                    </Inner>
                  </Container>
                ) : (
                  <ContainerWithNoBackgroundImage>
                    <Inner imagePosition={imagePosition}>
                      <ImagePanel>
                        {image && image.src && (
                          <Image
                            fluid={image.src.asset.fluid}
                            alt={image.alt}
                            title={image.title}
                          />
                        )}
                      </ImagePanel>
                      <CopyPanel>
                        <Content imagePosition={imagePosition}>
                          <ContentHeadline imagePosition={imagePosition}>
                            {headline}
                          </ContentHeadline>
                          <ContentCopy>
                            <CustomBlockContent blocks={copy} />
                          </ContentCopy>
                        </Content>
                      </CopyPanel>
                    </Inner>
                  </ContainerWithNoBackgroundImage>
                )}
              </React.Fragment>
            );
          },
        )}
    </React.Fragment>
  );
};

export default TwoColumnContent;
