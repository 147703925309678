import React from 'react';
import {propsPageStyle} from '../../style/PageStyle';
import ModuleLoader from '../ModuleLoader';
import CenteredInteriorHero from '../CenteredInteriorHero';
import {PageProps, pageComponent} from '../../interfaces';
import PetitionForm from './PetitionForm';
import {PetitionPage, Layout, Form, FormShift, Imposition} from './styles';
import useScrollToRef from '../../utils/useScrollToRef';

const mapComponent = (component: pageComponent, index: number) => {
  if (component === null) return null;
  return (
    <ModuleLoader
      component={component}
      order={index}
      key={`module-child-${index}${
        (component && component.id) || Math.random().toString()
      }`}
    />
  );
};

interface _props extends PageProps, propsPageStyle {
  headerHero: any;
  formId: string;
}
export default ({body = [], ...props}: _props) => {
  const [formRef, scrollToForm] = useScrollToRef();
  return (
    <PetitionPage {...props}>
      <CenteredInteriorHero {...props.headerHero} />
      <Layout.Main>
        <Layout.Content>
          {body.map(mapComponent)}
          <Imposition onClick={() => scrollToForm()}>Take Action</Imposition>
        </Layout.Content>
        <Layout.Form ref={formRef}>
          <FormShift>
            <PetitionForm hubspotFormId={props.formId} />
          </FormShift>
        </Layout.Form>
      </Layout.Main>
    </PetitionPage>
  );
};
