import React from 'react';
import {_propsCatergoryBar} from './types';
import styled from 'styled-components';
import {colors, themeColor, query} from '../../style/theme';
import {buildTypograpy as font} from '../../style/Typography';
import {mainContentGutterStyle} from '../../style/layout';
import {DownChev, Close} from '../SvgIcons';
import {observer, inject} from 'mobx-react';

const OuterFrame = styled.div`
  position: relative;
  height: 0;
`;

const InnerFrame = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const Bar = styled.div`
  ${mainContentGutterStyle}
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-wrap: nowrap;
`;

const Catergories = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  mask-image: linear-gradient(to left, transparent 2%, black 8%);
  overflow-x: auto;
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }
  & {
    scrollbar-width: none; /* Firefox */
  }
`;

const Category = styled.div<{active: boolean}>`
  ${font({
    base: 'ui1',
    color: 'white',
    bold: true,
  })}
  white-space:nowrap;
  text-transform: uppercase;
  padding: 21px 16px;
  opacity: 0.6;
  cursor: pointer;
  border-bottom: solid transparent 3px;
  transition: all 0.2s linear;
  font-size: 15px;
  line-height: 15px;

  &:hover {
    opacity: 1;
  }

  ${(props) =>
    props.active &&
    `
    opacity: 1;
    border-bottom: solid ${colors.yellow} 3px;
  `}
`;

const TagsButtonDiv = styled.div`
  @media (${query.upto.tablet}) {
    display: none;
  }

  display: flex;
  justify-items: flex-end;
  align-items: center;
  ${font({
    base: 'ui1',
    color: 'white',
    bold: true,
  })}
  white-space: nowrap;
  text-transform: uppercase;
  padding: 21px 16px;
  border-bottom: 3px solid transparent;

  cursor: pointer;
  font-size: 15px;
  line-height: 15px;

  & span {
    opacity: 0.6;
  }
  &:hover span {
    opacity: 1;
  }

  &.tagsListShowing {
    background-color: ${colors.white};
    color: ${colors.charcoal};

    & span {
      opacity: 1;
    }
  }

  z-index: 2; /* lift over boxshadow of tags list */
`;

const TagsButtonIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 8px;
  position: relative;
  svg {
    position: absolute;
    top: 0.5px;
    left: 0;
    height: 14px;
    width: 14px;
  }
`;

const noop = () => {};

interface _tagsButtonProps {
  discoverStore?: any;
}

const TagsButton = ({discoverStore}: _tagsButtonProps) => {
  const {showTagsList, toggleTagsList, closeTagsList} = discoverStore;
  const className = showTagsList ? 'tagsListShowing' : '';
  return (
    <TagsButtonDiv onClick={() => toggleTagsList()} {...{className}}>
      <span>Filter by Tag</span>
      <TagsButtonIcon>
        <DownChev color={showTagsList ? 'transparent' : 'yellow'} />
        <Close color={showTagsList ? 'charcoal' : 'transparent'} />
      </TagsButtonIcon>
    </TagsButtonDiv>
  );
};

const MobxTagsButton = inject(`discoverStore`)(observer(TagsButton));

const CategoryBar = ({
  allCategories,
  tagsAreSelected,
  onPickCategory = noop,
  discoverStore,
}: _propsCatergoryBar) => {
  const [hasMounted, setHasMounted] = React.useState(false);
  const {setCategory, selectedCategory} = discoverStore;

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <OuterFrame>
      <InnerFrame>
        <Bar>
          <Catergories>
            <Category
              onClick={(e) => setCategory(null)}
              active={!selectedCategory}
            >
              All
            </Category>
            {allCategories.map((cat) => {
              return (
                <Category
                  onClick={(e) => setCategory(cat.slug.current)}
                  key={cat.id}
                  active={selectedCategory === cat.slug.current}
                >
                  {cat.name}
                </Category>
              );
            })}
          </Catergories>
          <MobxTagsButton />
        </Bar>
      </InnerFrame>
    </OuterFrame>
  );
};

export default inject(`discoverStore`)(observer(CategoryBar));
