import React from 'react';
import styled from 'styled-components';
import {Text} from '../../style/Typography';


const ErrorBox = styled.div`
  color: red;
  text-align: center;
  padding-top: 34px;
`;

const getMessage = (message?: string) => {
  const defaultMessage = `We've encountered an error with your submission. Please wait a few moments and try again.`

  if (!message) {
    return defaultMessage;
  }

  if (message === 'Invalid data. No emails have been created.') {
    return `Sorry, but this outreach form isn't avaiable for the address you entered. Please use an address that is relevant to the&nbsp;issue.`;
  }

  return message;

}

interface ErrorBoxProps {
  message?: string;
}

export default (props: ErrorBoxProps) => {

  const message = getMessage(props.message);

  return (
    <ErrorBox>
      <Text dangerouslySetInnerHTML={{__html: message}} />
    </ErrorBox>
  );
};