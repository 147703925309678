import React, {useState} from 'react';
import {
  Container,
  Inner,
  Form,
  TextInput,
  TextArea,
  NameFieldSet,
  AddressFieldSet,
  FieldWrapHalf,
  ButtonWrap,
  FormTitle,
  SectionTitle,
  Label,
  Spinner,
} from './styles';
import Disclaimer from './Disclaimer';
import Select from './Select';
import {Button} from '../../style/Buttons';
import SuccessHero from './SuccessHero';
const {UsaStates} = require('usa-states');
import pushDataLayer from '../../utils/pushDataLayer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorBox from './ErrorBox';

const usStates = new UsaStates();

const STATE_OPTIONS = usStates.states.map(
  ({name, abbreviation}: {name: string; abbreviation: string}) => {
    return {
      value: abbreviation,
      label: name,
    };
  },
);

interface OutreachFormProps {
  campaignId: number;
  formId: number;
  message: string;
  messageEditable: boolean;
}

interface SubmitState {
  name?: string;
  message?: string;
}

export default (props: OutreachFormProps) => {
  const [submitState, setSubmitState] = useState<SubmitState>({});

  const submit = async (formEl: HTMLFormElement) => {
    const data = new FormData(formEl);

    const serialized = Array.from(data.entries()).reduce(
      (final, [name, value]) => {
        return {
          ...final,
          [name]: value,
        };
      },
      {
        campaigns: [props.campaignId],
        formId: props.formId,
      },
    );

    const url = `https://l8xec990d4.execute-api.us-east-1.amazonaws.com/production/take-action`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(serialized),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.error) {
          setSubmitState({
            name: 'error',
            message: data.error,
          });
        } else {
          pushDataLayer({event: 'ContactYourLegislator'});
          setSubmitState({
            name: 'success',
          });
        }
      })
      .catch((err) => {
        console.warn(err);
        setSubmitState({
          name: 'error',
        });
      });
  };

  return (
    <Container>
      <Inner submitState={submitState}>
        <FormTitle>Review your message</FormTitle>
        <Form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitState({name: 'submitting'});
            submit(e.currentTarget);
          }}
        >
          <fieldset>
            <Label>Message Body</Label>
            <TextArea
              name="message"
              rows="7"
              defaultValue={props.message}
              disabled={!props.messageEditable}
            />
          </fieldset>

          <SectionTitle>Enter your info</SectionTitle>

          <NameFieldSet>
            <Label>Your Information</Label>

            <FieldWrapHalf>
              <Select
                placeholder="Title*"
                options={['Mr.', 'Mrs.', 'Ms.', 'Dr.'].map((opt) => {
                  return {label: opt, value: opt};
                })}
                selectProps={{required: 'true'}}
                name="prefix"
              />
            </FieldWrapHalf>

            <FieldWrapHalf>
              <TextInput
                type="text"
                name="firstname"
                placeholder="First Name*"
                required
              />
            </FieldWrapHalf>

            <FieldWrapHalf>
              <TextInput
                type="text"
                name="lastname"
                placeholder="Last Name*"
                required
              />
            </FieldWrapHalf>

            <FieldWrapHalf>
              <TextInput
                type="email"
                name="email"
                placeholder="Email*"
                required
              />
            </FieldWrapHalf>
          </NameFieldSet>

          <AddressFieldSet>
            <Label>Home Address</Label>
            <FieldWrapHalf>
              <TextInput
                type="text"
                name="address1"
                placeholder="Street Address*"
                required
              />
            </FieldWrapHalf>

            <FieldWrapHalf>
              <TextInput type="text" name="city" placeholder="City*" required />
            </FieldWrapHalf>

            <FieldWrapHalf>
              <Autocomplete
                id="state"
                options={usStates.states.map((option: any) => option.name)}
                renderInput={(params: any) => {
                  return (
                    <div ref={params.InputProps.ref}>
                      <TextInput
                        {...params.inputProps}
                        autoComplete="off"
                        aria-autocomplete="off"
                        aria-controls="off"
                        name="state"
                        placeholder="State*"
                        type="text"
                        required
                      />
                    </div>
                  )
                }}
              />
            </FieldWrapHalf>

            <FieldWrapHalf>
              <TextInput
                type="text"
                name="zip5"
                placeholder="ZIP Code*"
                required
                maxlength="5"
                minlength="5"
              />
            </FieldWrapHalf>

            <FieldWrapHalf>
              <TextInput
                type="tel"
                name="phone"
                placeholder="Phone Number*"
                required
              />
            </FieldWrapHalf>

          </AddressFieldSet>

          <Disclaimer />

          {submitState.name === 'error' && (
            <ErrorBox message={submitState.message} />
          )}

          <ButtonWrap>
            <Button type="submit">Send Message</Button>
          </ButtonWrap>
        </Form>
      </Inner>
      <Spinner visible={submitState.name === 'submitting'} />
      <SuccessHero visible={submitState.name === 'success'} />
    </Container>
  );
};
