import React from 'react';
import Page from '../Page';
import styled from 'styled-components';
import theme, {
  query,
  colors,
  constants,
  breaks,
  fonts,
} from '../../style/theme';
import Typography, {buildTypograpy as font} from '../../style/Typography';
import {gutter} from '../../style/layout';
import {FreeTextAreaWrapper} from '../FreeTextArea';

const mq = breaks([query.above.phone, query.above.tablet, query.above.medium]);

export const PetitionPage = styled(Page)``;

interface _propsFormContainer {
  centered?: boolean;
}

export const Layout = {
  Main: styled.div`
    display: grid;
    grid-column-gap: 0;
    grid-template-areas:
      'c'
      'f';
    padding: ${constants.sectionPaddingMobile} 0 0 0;
    @media (${query.above.tablet}) {
      grid-template-areas: '. c . f .';
      grid-template-columns: ${gutter.desktop} 1.6fr 48px 1fr ${gutter.desktop};
    }
  `,
  Content: styled.div`
    grid-area: c;
    ${FreeTextAreaWrapper} {
      max-width: unset;
      text-align: left;
      margin-left: 0;
      margin-bottom: 20px;
    }

    @media (${query.above.tablet}) {
      margin-bottom: 96px;
      padding-top: 30px;
    }
  `,
  Form: styled.div`
    grid-area: f;
    position: relative;
    z-index: 1;
    transform: translate3d(0, 0, 0); /* safari ftw */
    background: ${colors.lightGray};
    padding: 26px ${gutter.mobile} ${constants.sectionPaddingMobile};
    @media (${query.above.tablet}) {
      padding: 0;
      background: none;
    }

    ul {
      align-items: flex-start;
      & > li > * {
        margin-top: 0;
      }
    }
  `,
};

const formH2Font = mq({
  ...theme.fonts.sofiaBold,
  fontSize: ['24px', '32px', '32px', '42px'],
  lineHeight: ['28px', '34px', '34px', '48px'],
  letterSpacing: ['-0.25px', '-0.5px', '-0.5px', '-0.63px'],
});

export const Form = {
  Container: styled.div<_propsFormContainer>`
    ${(props) => (props.centered ? {textAlign: 'center'} : {})}
    background: none;
    @media (${query.above.tablet}) {
      background: ${colors.white};
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
      padding: 22px 40px;
    }
    form {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: stretch;
    }
    input,
    p {
      margin: 17px 0 16px;
    }
    input {
      ${fonts.sofiaRegular}
      font-size: 15px;
      padding-bottom: 8px;
      color: #414042;
      
      ::placeholder {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        font-weight: inherit;
      }
  }
    }
    button {
      margin: 27px 0 22px;
    }
    & > svg {
      width: 60px;
    }
  `,
  Msg: styled.div<{show?: boolean}>`
    ${(props) => (props.show ? {} : {display: 'none'})}
  `,
  P1: styled(Typography.P1)`
    ${mq({
      fontSize: ['14px', '16px', '18px', '18px'],
      lineHeight: ['20px', '25px', '27px', '27px'],
    })}
  `,
  P2: styled(Typography.P2)`
    font-size: 12px;
    line-height: 18px;
  `,
  H2: styled(Typography.H2)`
    margin: 16px 0;
    ${formH2Font}
    text-align: center;
  `,
  H3: styled(Typography.H3)`
    font-size: 16px;
    letter-spacing: -0.1px;
  `,
};

export const FormShift = styled.div`
  @media (${query.above.tablet}) {
    position: sticky;
    top: 0;
    margin-top: -184px;
    padding-top: 90px;
    padding-bottom: 90px;
  }
`;

export const Imposition = styled.div`
  z-index:1;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.yellow};
  padding: 20px ${gutter.mobile};
  margin-top: 42px;
  cursor: pointer;
  ${theme.typography.ui1}
  ${mq({
    marginBottom: ['-52px', '-58px'],
  })}
  @media (${query.above.tablet}) {
    display: none;
  }
`;
