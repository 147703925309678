import React from 'react';
import styled from 'styled-components';
import CustomBlockContent from '../CustomBlockContent';
import {query, fonts, colors} from './../../style/theme';
import {P1} from '../../style/Typography';
import FreeTextArea from '../FreeTextArea';

import {
  CardWrapper,
  CardTitle,
  LinkWrapper,
  CardContent,
} from './../StatsHero/StaticInfographicCard';
import TextLink from './../TextLink';
import Img from 'gatsby-image';

interface StatCardProps {
  card: any;
  statCard: any;
  cardIndex: string;
}

export const CardCopy = styled(FreeTextArea)`
  margin: 25px 25px 10px 20px;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.charcoal};
  ${fonts.sofiaRegular};
  text-align: center;

  strong {
    ${fonts.sofiaBold};
  }

  @media (${query.above(767)}) {
    font-size: 16px;
    line-height: 25px;
    margin: 20px 30px;
  }

  @media (${query.above(1135)}) {
    min-height: 100px;
  }
`;

export const CardImage = styled(Img)<{cardIndex: string}>`
  align-items: center;
  margin: 30px auto;
  max-width: ${(props) => (props.cardIndex === 'card-1' ? '215px' : '200px')};
`;

export const TwoColumnStatCardWrapper = styled(CardWrapper)`
  max-width: 375px;
  box-shadow: 15px 30px 30px 0px rgba(0, 0, 0, 0.1) !important;

  @media (${query.above(767)}) {
    max-width: 460px;
  }
`;

export const TwoColumnStatCardImage = styled(CardImage)`
  max-width: 215px;

  @media (${query.above.small}) {
    max-width: 285px;
  }
`;

export const TwoColumnStatCardTitle = styled(CardTitle)`
  @media (${query.above(767)}) {
    font-size: 24px;
    letter-spacing: -0.28px;
    line-height: 30px;
  }

  @media (${query.above.small}) {
    font-size: 25px;
    letter-spacing: -0.32px;
    line-height: 38px;
  }
`;

export const TwoColumnStatCardCopy = styled(CardCopy)`
  margin: 30px;

  @media (${query.above(767)}) {
    font-size: 18px;
    line-height: 26px;
    max-width: 280px;
    margin: 20px;
  }

  @media (${query.above.small}) {
    font-size: 23px;
    line-height: 36px;
    max-width: 375px;
    margin: 20px 40px;
  }
`;

const StatCard = ({card, statCard, cardIndex}: StatCardProps) => {
  return (
    <React.Fragment>
      <TwoColumnStatCardWrapper>
        <TwoColumnStatCardTitle as="h3">
          {statCard.headline}
        </TwoColumnStatCardTitle>
        <TwoColumnStatCardImage
          fluid={card.image.src.asset.fluid}
          alt={card.image.alt}
          title={card.image.title}
          cardIndex={cardIndex}
        />
        <CardContent>
          <TwoColumnStatCardCopy>
            <CustomBlockContent blocks={statCard.copy} />
          </TwoColumnStatCardCopy>
          <LinkWrapper>
            Source: <TextLink {...statCard.link} />
          </LinkWrapper>
        </CardContent>
      </TwoColumnStatCardWrapper>
    </React.Fragment>
  );
};

export default StatCard;
