import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import CustomBlockContent from '../CustomBlockContent';
import {fonts, colors, query} from '../../style/theme';
import FreeTextArea from '../FreeTextArea';

const Section = styled.section`
  padding: 0px 0px 128px;

  @media (${query.upto.tablet}) {
    padding-bottom: 0;
  }
`;

const Inner = styled.div``;

const UL = styled.ul`
  max-width: 685px;
  margin: 0 auto;

  @media (${query.upto.tablet}) {
    max-width: none;
  }
`;

const LI = styled.li`
  position: relative;
  margin-bottom: 86px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (${query.upto.tablet}) {
    margin-bottom: 0px;
  }
`;

const Question = styled.div<{isOpen: boolean}>`
  h2 {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.38px;
    font-family: ${fonts.sofiaBold.fontFamily};
    font-weight: 700;
    color: ${colors.charcoal};
    margin-bottom: 20px;

    &:before {
      position: absolute;
      content: 'Q';
      left: -45px;
      color: #709f5a;
    }
  }

  @media (${query.upto.tablet}) {
    h2 {
      background: #709f5a;
      padding: 20px 40px 20px 20px;
      font-size: 16px;
      line-height: 22px;
      color: white;
      letter-spacing: normal;
      margin-bottom: 0;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid white;

      &:before {
        display: none;
      }

      &:after {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
        content: '';
        position: absolute;
        top: 29px;
        right: 20px;
        transition: all 0.2s linear;
        transform: rotate(${(props) => (props.isOpen ? 0 : 180)}deg);
      }
    }
  }
`;

const Answer = styled(FreeTextArea)<{isOpen: boolean; contentHeight: number}>`
  color: ${colors.charcoal};

  p {
    font-family: ${fonts.sofiaRegular.fontFamily};
    font-size: 18px;
    line-height: 27px;

    sup {
      vertical-align: super;
      font-size: 0.5em;
      padding-left: 2px;
      color: inherit;
    }

    a {
      color: rgb(112, 159, 90);
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:before {
    position: absolute;
    content: 'A';
    left: -45px;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.38px;
    font-family: ${fonts.sofiaBold.fontFamily};
    font-weight: 700;
    color: #709f5a;
  }

  @media (${query.upto.tablet}) {
    background: white;
    padding: 0 20px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: normal;
    max-height: ${({isOpen, contentHeight}) => (isOpen ? contentHeight : 0)}px;
    overflow: hidden;
    transition: max-height 0.2s linear;

    &:before {
      display: none;
    }

    p {
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }
    }
  }
`;

interface FAQInterface {
  question: object;
  answer: object;
}

const FAQ = ({question, answer}: FAQInterface) => {
  const [isOpen, setOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const answerWrap = useRef(null);

  const getContentHeight = () => {
    return answerWrap.current ? answerWrap.current.offsetHeight : 0;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        setContentHeight(getContentHeight());
      });
    }
    setContentHeight(getContentHeight());
  }, []);

  return (
    <LI>
      <Question
        isOpen={isOpen}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        {typeof question === 'string' ? (
          <h2>{question}</h2>
        ) : (
          <CustomBlockContent blocks={question} />
        )}
      </Question>
      <Answer contentHeight={contentHeight} isOpen={isOpen}>
        <div ref={answerWrap}>
          <CustomBlockContent blocks={answer} />
        </div>
      </Answer>
    </LI>
  );
};

export default ({faqs}: {faqs: Array<object>}) => {
  const schemaDotOrgContent = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq: FAQInterface) => {
      return {
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer[0].children.map((c) => c.text).join(' '),
        },
      };
    }),
  };

  return (
    <React.Fragment>
      <Section>
        <Inner>
          <UL>
            {faqs.map((faq) => (
              <FAQ {...faq} />
            ))}
          </UL>
        </Inner>
      </Section>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{__html: JSON.stringify(schemaDotOrgContent)}}
      />
    </React.Fragment>
  );
};
