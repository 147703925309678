import React from 'react';
import styled from 'styled-components';
import CloseIcon from './../../images/close-icon.svg';
import {ModalProps} from './../../interfaces';

const ModalContainer = styled.section<{
  showModal: boolean;
  backgroundColor: string;
}>`
  position: fixed;
  // overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${(props) => `${props.backgroundColor}` || 'black'};
  display: ${(props) => (props.showModal ? 'flex' : 'none')};
  z-index: 99;
`;

const ModalSection = styled.div`
  position: relative;
  overflow: scroll;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
  }
`;

const Modal = ({
  handleClose,
  showModal,
  children,
  enableClose,
  backgroundColor,
  className,
}: ModalProps) => {
  return (
    <ModalContainer
      showModal={showModal}
      backgroundColor={backgroundColor}
      className={`${className || ''}`}
    >
      <ModalSection>
        {enableClose && (
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        )}
        {children}
      </ModalSection>
    </ModalContainer>
  );
};

export default Modal;
