import styled, {css} from 'styled-components';
import {query} from '../../../style/theme';

const responsivePadding = (
  youtubeUrl: string,
  minHeight: number,
  showYoutubeVideo: boolean,
) => {
  return youtubeUrl
    ? css`
        min-height: ${showYoutubeVideo ? '350px' : 'auto'};

        @media (${query.above.tablet}) {
          max-height: 500px;
          min-height: 450px;
        }

        @media (${query.above(992)}) {
          min-height: ${minHeight}px;
          max-height: 750px;
        }
      `
    : css`
        @media (${query.above.tablet}) {
          &:after {
            padding-top: 56.25%;
            display: block;
            content: '';
          }
        }
      `;
};

export const VideoContainer = styled.div<{
  minHeight: number;
  youtubeUrl: string;
  showYoutubeVideo: boolean;
}>`
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  max-width: 1240px;
  margin-top: 30px;

  @media (${query.above.tablet}) {
    margin-top: 60px;
  }

  ${({youtubeUrl, minHeight, showYoutubeVideo}) =>
    responsivePadding(youtubeUrl, minHeight, showYoutubeVideo)};
`;

export const VideoWrapper = styled.video<{wrappedInModal: boolean}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  border: 0;
  min-width: 100%;
  object-fit: cover;
  display: ${(props) => (props.wrappedInModal ? 'flex' : 'none')};
  max-height: 100%;
  display: none;

  @media (${query.above.tablet}) {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

export const VideoPreview = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const YoutubeVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
