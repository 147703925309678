import React from 'react';
import {GridContainer, Image} from './styles';

const EmbeddedImageGrid = (props: any) => {
  const {images} = props;
  return (
    <GridContainer>
      {images.map((image: any, index: number) => {
        return <Image fluid={image.src.asset.fluid} />;
      })}
    </GridContainer>
  );
};

export default EmbeddedImageGrid;
