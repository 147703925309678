import React from 'react';
import {Link} from 'gatsby';

const urlIsExternal = (url = '') => url.indexOf('://') !== -1;

export interface LinkProps {
  href: string;
  extraClasses?: string;
  className?: string;
  openInNewWindow?: string;
  children?: any;
}

export default (props: LinkProps) => {
  const isExternal = urlIsExternal(props.href);
  const className = `${props.extraClasses || ''} ${props.className || ''}`;
  const target = props.openInNewWindow ? '_blank' : '';

  if (isExternal) {
    return (
      <a className={className} target={target} href={props.href}>
        {props.children}
      </a>
    );
  } else {
    return (
      <Link className={className} to={props.href || ''} target={target}>
        {props.children}
      </Link>
    );
  }
};
