import React from 'react';
import CustomBlockContent from '../CustomBlockContent';
import {useStaticQuery, graphql} from 'gatsby';
import {
  Section,
  Wrapper,
  Headline,
  LogoGroup,
  LogoItem,
  LogoImage,
  Copy,
} from './styles';

interface propsPartner {
  title: string;
  logo: any;
  href?: string;
  id: string;
}

interface propsPartnerList {
  headline: string;
  subhead: [any];
}

export default (props: propsPartnerList) => {
  const {partners} = useStaticQuery(
    graphql`
      query {
        partners: allSanityPartner {
          nodes {
            id: _key
            title
            logo {
              src {
                asset {
                  fluid(maxWidth: 150) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            href
          }
        }
      }
    `,
  );

  partners.nodes.sort((a: any, b: any) => a.title.localeCompare(b.title));

  return (
    <Section>
      <Wrapper>
        <Headline>{props.headline}</Headline>
        <Copy>
          <CustomBlockContent blocks={props.subhead} />
        </Copy>
        <LogoGroup>
          {partners &&
            partners.nodes.map((partner: propsPartner) => (
              <LogoItem key={partner.id} href={partner.href} target="_blank">
                <div>
                  <LogoImage
                    fluid={partner.logo.src.asset.fluid}
                    aspectRatio={partner.logo.src.asset.fluid.aspectRatio}
                    title={partner.title}
                  />
                </div>
              </LogoItem>
            ))}
        </LogoGroup>
      </Wrapper>
    </Section>
  );
};
