import styled from 'styled-components';
import {colors, fonts} from '../../style/theme';

export const AlertBarSection = styled.div<{showAlertBar: boolean}>`
  background-color: ${colors.charcoal};
  width: 100%;
  z-index: 2;
  display: ${(props) => (props.showAlertBar === true ? 'block' : 'none')};
`;

export const Wrapper = styled.div`
  padding: 14px 60px;
`;

export const Copy = styled.div`
  display: block;
  color: ${colors.white};
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin: 0 auto;
  max-width: 1280px;
  ${fonts.sofiaRegular};

  * {
    color: ${colors.white};
    ${fonts.sofiaRegular};
    font-size: 12px;
  }
  a {
    text-decoration: underline;

    &:hover {
      color: ${colors.yellow};
    }
  }
`;

export const CloseWrapper = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 13px;
  cursor: pointer;

  svg g {
    transition: all 0.2s linear;
  }

  &:hover {
    svg g {
      stroke: ${colors.yellow};
    }
  }
`;
