import styled from 'styled-components';
import TextLink from './../../TextLink';
import {fonts, query} from './../../../style/theme';

export const LinkWrapper = styled.div`
  margin: 13px auto 0 auto;
  width: 100%;
  max-width: 1024px;

  @media (${query.above.tablet}) {
    margin: 17px auto 0 auto;
  }

  @media (${query.above.large}) {
    max-width: 1240px;
  }
`;

export const SourceLink = styled(TextLink)`
  font-family: ${fonts.sofiaRegular.fontFamily};
  font-weight: ${fonts.sofiaRegular.fontWeight};
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0;
`;

export const EmbeddedVideoContainer = styled.section`
  margin: 20px;

  @media (${query.above.tablet}) {
    margin: 35px;
  }

  @media (${query.above.large}) {
    margin: 85px 0;
  }
`;

export const CaptionWrap = styled.div`
  margin: 13px auto 0 auto;
  width: 100%;
  max-width: 100%;
  font-size: 13px;
  line-height: 1.4em;

  a {
    color: #709f5a;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: bold;
  }

  @media (${query.above.tablet}) {
    margin: 17px auto 0 auto;
    font-size: 16px;
    line-height: 25px;
  }
`;

export const Inner = styled.div`
  margin: 0 auto;
  max-width: 1024px;

  @media (${query.above.large}) {
    max-width: 1240px;
  }
`;
