import React from 'react';
import {Container, Image, Caption as CaptionWrap, Inner} from './styles';
import {EmbeddedImageProps} from './../../interfaces';
import Caption from '../Caption';

const EmbeddedImage = ({image, copy}: EmbeddedImageProps) => {
  return (
    <Container>
      <Inner>
        <Image
          fluid={image?.src?.asset?.fluid}
          alt={image?.alt}
          title={image?.title}
        />
        {copy && (
          <CaptionWrap>
            <Caption blocks={copy} />
          </CaptionWrap>
        )}
      </Inner>
    </Container>
  );
};

export default EmbeddedImage;
