import React from 'react';
import styled, {css} from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import CustomBlockContent from '../CustomBlockContent';
import {query, colors} from '../../style/theme';
import Dots from './Dots';
import {HeroSlideProps} from '../../interfaces';

const HeroSlideContainer = styled.div<{
  active: boolean;
  backgroundColor: string;
}>`
  height: 100%;
  position: relative;
  padding: 0 20px;
  background: ${(props) => props.backgroundColor};
  z-index: 2;
  display: flex;
  flex-direction: column-reverse;

  @media (${query.above.tablet}) {
    height: 100%;
    z-index: ${(props) => (props.active ? '1' : '0')};
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
`;

const BgImage = styled(BackgroundImage)`
  position: relative;
  background-size: contain;
  background-position: center bottom !important;
  background-repeat: no-repeat !important;
  z-index: 1;
  flex: 2 2 auto;
  width: 100%;

  @media (${query.above.tablet}) {
    max-height: none;
    min-height: 0;
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 3%;

    background-size: auto 90% !important;
    :before,
    :after {
      background-size: inherit !important;
    }
  }

  @media (min-width: 1020px) {
    background-size: auto 100% !important;
    :before,
    :after {
      background-size: inherit !important;
    }
  }
`;

const Content = styled.div<{copyAlignment: string}>`
  height: auto;
  z-index: 2;
  padding-top: 80px;

  @media (${query.above.tablet}) {
    max-width: 1280px;
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Heading = styled.div`
  h1,
  h2 {
    color: ${colors.white};
    font-size: 32px;
    letter-spacing: -0.5px;
    line-height: 34px;
    margin-bottom: 10px;
    max-width: 650px;
    font-weight: bold;

    strong {
      color: ${colors.lightYellow};
      display: inline;
    }
  }

  @media (${query.above.tablet}) {
    width: 50%;
    h1,
    h2 {
      font-weight: bold;
      letter-spacing: -0.88px;
      line-height: 1.2em;
      font-size: 4vw;
      margin-bottom: 20px;
      width: 100%;
      max-width: 100%;

      strong {
        min-width: 425px;
      }
    }
  }
`;

const CopyStyle = () => `
  p {
    color: ${colors.white};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 10px;

    strong {
      font-weight: 900;
    }

    sup {
      vertical-align: super;
      font-size: 0.5em;
      padding-left: 2px;
      color: inherit;
    }
  }

  @media (${query.above.tablet}) {
    p {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 27px;
      margin-bottom: 20px;
    }
  }
`;

const Copy = styled.div`
  ${CopyStyle};
  max-width: 650px;

  @media (${query.above.tablet}) {
    max-width: 48%;
  }
`;

const Suffix = styled.div`
  ${CopyStyle};

  p {
    font-weight: bold;
    margin-bottom: 25px;

    strong {
      color: ${colors.lightYellow};
    }

    sup {
      vertical-align: super;
      font-size: 0.5em;
      padding-left: 2px;
      color: inherit;
    }
  }
`;

const SymbolWrapper = styled.div`
  position: absolute;
  bottom: 75px;
  left: 20px;

  @media (${query.above.phone}) {
    bottom: 100px;
  }

  @media (${query.above.tablet}) {
    left: 10px;
  }
`;

const AnimatedArrow = styled.div`
  width: 2px;
  height: 0px;
  background-color: #fff;
  top: 0;
  bottom: initial;
  animation: line-animation 2.5s ease-in-out 0s infinite normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;

  &:before {
    content: ' ';
    width: 10px;
    height: 10px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
    display: block;
    position: absolute;
    bottom: 0;
    animation: arrow-animation 2.5s ease-in-out 0s infinite normal;
  }

  @keyframes line-animation {
    0% {
      height: 0px;
      top: 0px;
      opacity: 0;
    }
    50% {
      height: 44px;
      top: 0px;
      opacity: 1;
    }
    75% {
      height: 0px;
      top: 44px;
      opacity: 0;
    }
    100% {
      height: 0px;
      top: 44px;
      opacity: 0;
    }
  }

  @keyframes arrow-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.75;
    }
  }
`;

const HeroSlide = ({
  slide,
  slideImage,
  active,
  numberOfDots,
  activeSlide,
  setActiveSlide,
}: HeroSlideProps) => {
  const {headline, copy, suffix, copyAlignment} = slide;
  const {tablet, large, medium, small} = slideImage.desktopImage.src.asset;

  const fluid = [
    slideImage.mobileImage.src.asset.fluid,
    {
      ...tablet,
      media: `(${query.above.tablet})`,
    },
    {
      ...small,
      media: `(${query.above.small})`,
    },
    {
      ...medium,
      media: `(${query.above.medium})`,
    },
    {
      ...large,
      media: `(${query.above.large})`,
    },
  ];
  return (
    <HeroSlideContainer backgroundColor={slide.backgroundColor} active={active}>
      <BgImage loading="eager" fadeIn={false} style={{}} fluid={fluid} />
      <Content copyAlignment={copyAlignment || 'left'}>
        <Heading>
          <CustomBlockContent blocks={headline} />
        </Heading>
        <Copy>
          <CustomBlockContent blocks={copy} />
        </Copy>
        <Suffix>
          <CustomBlockContent blocks={suffix} />
        </Suffix>
        <SymbolWrapper>
          <AnimatedArrow />
        </SymbolWrapper>
      </Content>
      <Dots
        numberOfDots={numberOfDots}
        activeSlide={activeSlide}
        handleDotClick={setActiveSlide}
      />
    </HeroSlideContainer>
  );
};

export default HeroSlide;
