import React from 'react';
import styled from 'styled-components';
import {colors, query} from '../../style/theme';

interface DotProps {
  numberOfDots: number;
  activeSlide: number;
  handleDotClick: (i: number) => void;
}

export const DotsList = styled.div`
  margin: 0;
  padding: 0 20px;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;

  @media (${query.above.phone}) {
    bottom: 50px;
  }

  @media (${query.above.tablet}) {
    justify-content: center;
  }
`;

export const Dot = styled.button<{active: boolean}>`
  position: relative;
  background-color: ${(props) =>
    props.active ? `${colors.yellow}` : `${colors.white}`};
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 100%;
  display: inline-block;
  margin: 0 6px 0 0;
  transition: all 0.2s ease;
  cursor: pointer;
  outline: none;
  padding: 0;
  z-index: 3;
`;

const Dots = ({numberOfDots, activeSlide, handleDotClick}: DotProps) => (
  <DotsList>
    {[...Array(numberOfDots)].map((_, i) => (
      <Dot
        key={`hero-carousel-${i}`}
        active={i === activeSlide}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          handleDotClick(i);
        }}
      />
    ))}
  </DotsList>
);

export default Dots;
