import styled from 'styled-components';
import ButtonLink from '../ButtonLink';
import theme, {colors, fonts, query} from '../../style/theme';
import {MainContentSection} from '../../style/layout';
import {H1, P1} from '../../style/Typography';
import FreeTextArea from '../FreeTextArea';

export const Container = styled(MainContentSection)`
  background-color: ${colors.white};
`;

export const Inner = styled.div`
  margin-top: -8px;
  @media (${query.above.tablet}) {
    margin-top: -15px;
  }
`;

export const Headline = styled(H1)`
  text-align: center;
  margin-bottom: 20px;
  color: ${colors.charcoal};
  font-size: 32px;
  line-height: 34px;
  ${fonts.sofiaBold};

  @media (${query.above.tablet}) {
    font-size: 60px;
    letter-spacing: -0.5px;
    line-height: 63px;
  }
`;

export const Copy = styled(FreeTextArea)`
  max-width: 825px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 22px;
  ${fonts.sofiaRegular};

  p {
    text-align: center;
    margin-bottom: 20px !important;
    padding: 0 4px;
  }

  strong {
    font-weight: bold;
  }

  @media (${query.above.tablet}) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Button = styled(ButtonLink)`
  padding: 0 auto;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  ${fonts.sofiaRegular};
  font-weight: 700;

  @media (${query.above(500)}) {
    padding: 0 45px;
    width: auto;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
`;
