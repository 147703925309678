import React from 'react';
import CustomBlockContent from '../CustomBlockContent';
import FreeTextArea from '../FreeTextArea';
import styled from 'styled-components';
import {fonts, query} from '../../style/theme';

export const TextArea = styled(FreeTextArea)`
  max-width: 685px;
  margin: 0 auto;
  margin-bottom: 45px;

  &:last-child {
    padding-bottom: 45px;
  }

  p {
    font-size: 18px;
    line-height: 27px;
    ${fonts.sofiaRegular}
  }

  h2 {
    font-size: 30px;
    lin-height: 38px;
    letter-sapcing: -0.38;
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.25;
  }

  a {
    color: #709f5a;
  }

  @media (${query.upto.tablet}) {
    padding: 0 20px;

    p {
      font-size: 14px;
      line-height: 22px;
    }

    h2 {
      font-size: 20px;
      lin-height: 24px;
      letter-sapcing: -0.25;
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: normal;
    }
  }
`;

export default (props: {copy: object}) => (
  <TextArea>
    <CustomBlockContent blocks={props.copy} />
  </TextArea>
);
