import React from 'react';
import styled from 'styled-components';
import theme, {query} from '../../style/theme';
import {mainContentGutterStyle} from '../../style/layout';
import FreeTextArea from '../FreeTextArea';
import ButtonLink from '../ButtonLink';

const blockSpacing = theme.typography.p1.fontSize;

// produce responsive css from passed config instead of hardcoding
const responsiveSlickCss = (cfg, slideCount) => {
  // NB: lexical order of breakpoints is important
  const breaks = [...cfg.breaks].reverse();
  const lines = breaks.map((brk) =>
    slideCount > brk.count + 1
      ? `
          @media (${query.above(brk.px)}) {
          }
        `
      : `
          @media (${query.above(brk.px)}) {
            max-width: ${theme.px.maxContentWidth + 40}px;
            padding-right: 0;
            margin: 0 auto;
          }
        `,
  );
  return lines.join('\n');
};

export const Slick = styled.div`
  position: relative;
  overflow-x: hidden;
  padding-top: 35px; /* overflow-x hidden still hides top */
  margin-top: -35px;
  padding-bottom: 35px;
  margin-bottom: -35px;

  /* mobile single slide carousel */
  padding-right: 40px;

  /* larger sizes */
  ${(props) => responsiveSlickCss(props.cfg, props.slideCount)}

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    z-index: 4;

    div {
      outline: none;
    }
  }

  .slick-slide {
    display: flex;
    align-items: stretch;
    margin: 20px;
  }

  .slick-slide > div {
    display: flex;
    align-items: stretch;
    flex: 0 0 100%;
  }
`;

export const Wrapper = styled.section`
  z-index: 0;
  position: relative;
  background: white;
  padding: 43px 0 30px;
  @media (${query.above.tablet}) {
    padding: 83px 0 70px;
  }
`;

// produce responsive css from passed config instead of hardcoding
const responsiveButtonHiderCss = (cfg, slideCount) => {
  // NB: lexical order of breakpoints is important
  const breaks = [...cfg.breaks].reverse();
  const lines = breaks.map(
    (brk) => `@media (${query.above(brk.px)}) {
    display: ${slideCount > brk.count + 1 ? 'inherit' : 'none'};
  }`,
  );
  return lines.join('\n');
};

export const ButtonHider = styled.div`
  display: none;
  ${(props) => responsiveButtonHiderCss(props.cfg, props.slideCount)}
`;

const CarouselHeadStyle = styled.div`
  ${mainContentGutterStyle}
  & > div {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
    }
  }
  h2 {
    margin-bottom: ${blockSpacing};
  }
  margin-bottom: 20px;

  @media (${query.above.tablet}) {
    margin-bottom: 70px;
  }
`;

export const Subhead = styled(FreeTextArea)`
  margin-bottom: ${blockSpacing};
`;

export const Button = styled(ButtonLink)`
  width: 100%;

  @media (${query.above(500)}) {
    width: auto;
  }

  @media (${query.above.tablet}) {
    margin-top: 10px;
  }
`;

export const CarouselHead = (props) => (
  <CarouselHeadStyle>
    <div {...props} />
  </CarouselHeadStyle>
);
