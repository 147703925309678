import styled, {css} from 'styled-components';
import {query} from '../../../style/theme';

export const YoutubeVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const responsivePadding = (youtubeUrl: string, minHeight: number) => {
  return youtubeUrl
    ? css`
        min-height: 350px;

        @media (${query.above.tablet}) {
          max-height: 500px;
          min-height: 450px;
        }

        @media (${query.above(992)}) {
          min-height: ${minHeight}px;
          max-height: 650px;
        }
      `
    : css`
        &:after {
          padding-top: 56.25%;
          display: block;
          content: '';
        }
      `;
};

export const VideoContainer = styled.div<{
  minHeight: number;
  youtubeUrl: string;
}>`
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  max-width: 1024px;

  @media (${query.above.large}) {
    max-width: 1240px;
  }

  ${({youtubeUrl, minHeight}) => responsivePadding(youtubeUrl, minHeight)};
`;

export const VideoWrapper = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 100%;
  height: auto;
  border: 0;
  object-fit: cover;

  @media (${query.above.large}) {
    width: 100%;
    height: 100%;
  }
`;
