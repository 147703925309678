import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from '../../style/Buttons';

const StyledButton = styled(Button)`
  text-decoration: none;
`;

const ButtonLink = (props) => {
  let url = '';

  try {
    url = props.href ? props.href : props.file.file.asset.url;
  } catch (err) {}

  return (
    <StyledButton
      as="a"
      color={props.color}
      className={`${props.extraClasses} ${props.className || ''}`}
      href={url}
      target={props.openInNewWindow ? '_blank' : '_self'}
      data-analytics-id={props.trackingID}
    >
      {props.buttonText || 'Learn more'}
    </StyledButton>
  );
};

ButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  openInNewWindow: PropTypes.bool,
  color: PropTypes.oneOf([
    'blue',
    'green',
    'gray',
    'red',
    'yellow',
    'charcoal',
    'lightGray',
    'white',
  ]),
  extraClasses: PropTypes.string,
  trackingID: PropTypes.string,
  file: PropTypes.object,
};

ButtonLink.defaultProps = {
  color: 'green',
  extraClasses: '',
};

export default ButtonLink;
