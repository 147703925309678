import theme, {query} from './theme';
import styled, {css} from 'styled-components';

export type pageLayoutName = 'simple' | null;

export interface propsPageStyle {
  layout?: pageLayoutName;
  showAlertBar: boolean;
  alertBarHeight: number;
  isHome: boolean;
  mobileNavOpen: boolean;
}

const ifSimpleLayout = ({layout}: propsPageStyle) => {
  if (layout == 'simple') {
    return css`
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .Content {
        position: relative;
        box-sizing: border-box;
        flex: 1;

        padding-top: 92px;
        @media (${query.upto.small}) {
          padding-top: 54px;
        }
      }
    `;
  }
  return {};
};

const PageStyle = styled.div<propsPageStyle>`
  color: ${theme.colors.charcoal};
  ${(props) => theme.typography.p2};
  position: relative;
  ${(props) => ifSimpleLayout}
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  em {
    font-style: italic !important;
  }
  padding-top: ${(props) => {
    const {isHome, alertBarHeight, showAlertBar} = props;
    let padding = 0;

    if (isHome && showAlertBar) {
      padding = alertBarHeight;
    }

    if (!isHome && showAlertBar) {
      padding = alertBarHeight + 81;
    }

    if (!isHome && !showAlertBar) {
      padding = 81;
    }

    return `${padding}px`;
  }};

  @media (${query.upto.small}) {
    padding-top: ${(props) => {
      const {isHome, alertBarHeight, showAlertBar} = props;
      let padding = 0;

      if (isHome && showAlertBar) {
        padding = alertBarHeight;
      }

      if (!isHome && showAlertBar) {
        padding = alertBarHeight + 50;
      }

      if (!isHome && !showAlertBar) {
        padding = 50;
      }

      return `${padding}px`;
    }};

    ${({mobileNavOpen}) => {
      return mobileNavOpen
        ? `
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: fixed;
      `
        : '';
    }};
  }

  * {
    font: display;
  }
`;

export default PageStyle;
