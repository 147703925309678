import styled from 'styled-components';
import {H1} from '../../style/Typography';
import {fonts, colors, query} from '../../style/theme';
import BackgroundImage from 'gatsby-background-image';

export const SectionWrapper = styled.section``;

export const BackgroundSection = styled(BackgroundImage)`
  height: 100%;
  background-position: top center;
  background-size: cover;
`;

export const Wrapper = styled.div<{variant: string}>`
  max-width: 320px;
  margin: 0 auto;
  padding: ${(props) =>
    props.variant === 'Discover Page' ? '125px 20px 40px 20px' : '40px 20px'};

  @media (${query.above.phone}) {
    max-width: 500px;
  }

  @media (${query.above.tablet}) {
    max-width: 1280px;
    padding: ${(props) =>
      props.variant === 'Discover Page'
        ? '175px 100px 100px 100px'
        : '100px 100px'};
  }

  @media (${query.above.large}) {
    padding: ${(props) =>
      props.variant === 'Discover Page'
        ? '225px 100px 100px 100px'
        : '100px 100px'};
  }
`;

export const CopyContainer = styled.div<{variant: string}>`
  max-width: 900px;
  color: ${colors.white};
  text-align: center;
  margin: 0 auto;
  ${(p) => {
    if (p.variant === 'Discover Page') {
      return {paddingBottom: '100px'};
    }
  }}

  @media (${query.above.tablet}) {
    ${(p) => {
      if (p.variant === 'Discover Page') {
        return {paddingBottom: '80px'};
      }
    }}
  }
`;

export const Eyebrow = styled.span`
  color: ${colors.lightYellow};
  font-family: ${fonts.sofiaRegular.fontFamily};
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 12px;
  letter-spacing: 1.25px;
  line-height: 24px;
  padding: 10px 0;

  @media (${query.above.tablet}) {
    font-size: 15px;
    letter-spacing: 1.5px;
    line-height: 20px;
  }
`;

export const Headline = styled(H1)<{variant: string}>`
  color: ${colors.white};
  margin-bottom: ${(props) =>
    props.variant === 'Discover Page' ? '15px' : '9px'};
  font-size: 32px;
  line-height: 34px;
  letter-spacing: -0.5px;

  @media (${query.above.tablet}) {
    font-size: 60px;
    line-height: 63px;
    letter-spacing: -1.73px;
    margin-bottom: 25px;
  }
`;

export const BlockCopyWrapper = styled.div`
  max-width: 755px;
  margin: 0 auto;

  p {
    font-family: ${fonts.sofiaRegular.fontFamily};

    sup {
      vertical-align: super;
      font-size: 0.5em;
      padding-left: 2px;
      color: inherit;
    }

    @media (${query.above.tablet}) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  strong {
    font-weight: 800;
  }
`;
