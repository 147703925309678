import React from 'react';
import CustomBlockContent from '../CustomBlockContent';
import Logo from '../../images/hero-logo-sm.svg';
import {
  SectionWrapper,
  CopySection,
  Wrapper,
  Eyebrow,
  Headline,
  CopyContainer,
  BlockCopyWrapper,
  LogoWrapper,
  HeroImage,
} from './styles';
import {ImageProps} from '../../interfaces';

export default (props: {
  eyebrow: string;
  headline: string;
  copy: object;
  image: ImageProps;
  showLogo: boolean;
}) => (
  <SectionWrapper>
    <CopySection>
      <Wrapper image={props.image ? true : false}>
        <CopyContainer>
          {props.eyebrow ? <Eyebrow as="h1">{props.eyebrow}</Eyebrow> : null}
          {props.headline ? (
            <Headline as="h2">{props.headline}</Headline>
          ) : null}
          {props.showLogo === true ? (
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          ) : null}
          <BlockCopyWrapper>
            <CustomBlockContent blocks={props.copy} />
          </BlockCopyWrapper>
        </CopyContainer>
      </Wrapper>
    </CopySection>
    {props.image ? <HeroImage fluid={props.image?.src?.asset?.fluid} /> : null}
  </SectionWrapper>
);
