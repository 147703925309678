import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

export default (props: {className: string}) => {
  const images = useStaticQuery(graphql`
    query {
      loading: file(name: {eq: "loading"}) {
        src: publicURL
      }
    }
  `);

  return (
    <img className={props.className} src={images.loading.src} loading="lazy" />
  );
};
