import React, {useEffect, useRef} from 'react';
import Close from '../../images/close.svg';
import {AlertBarSection, Wrapper, Copy, CloseWrapper} from './styles';
import {observer, inject} from 'mobx-react';
import {graphql, useStaticQuery} from 'gatsby';
import CustomBlockContent from '../CustomBlockContent';

interface AlertBarProps {
  onCloseClick: any;
  store: any;
}

const AlertBar = ({onCloseClick, store}: AlertBarProps) => {
  const elementRef = useRef(null);
  const {closeAlertBar, showAlertBar, setAlertBarHeight} = store;

  const data = useStaticQuery(graphql`
    {
      sanityAlertBar {
        id: _id
        copy: _rawAlertBarCopy(resolveReferences: {maxDepth: 10})
      }
    }
  `);
  const {copy: alertBarCopy} = data.sanityAlertBar;

  useEffect(() => {
    elementRef &&
      elementRef.current &&
      setAlertBarHeight(elementRef.current.offsetHeight);
  }, []);

  return (
    alertBarCopy &&
    showAlertBar && (
      <AlertBarSection ref={elementRef} showAlertBar={showAlertBar}>
        <Wrapper>
          <Copy>
            <CustomBlockContent blocks={alertBarCopy} />
          </Copy>
          <CloseWrapper onClick={closeAlertBar}>
            <Close />
          </CloseWrapper>
        </Wrapper>
      </AlertBarSection>
    )
  );
};

export default inject(`store`)(observer(AlertBar));
