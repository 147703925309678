import styled from 'styled-components';
import {fonts, query} from '../../style/theme';
import {H2, H3} from '../../style/Typography';
import LoadingIcon from '../LoadingIcon';
import TextLink from '../TextLink';

export const Container = styled.section`
  background: #f7f7f8;
  position: relative;
  padding: 0 20px;
`;

export const Inner = styled.div<{submitState: object}>`
  padding-top: 70px;
  padding-bottom: 100px;
  max-width: 972px;
  margin: 0 auto;
  opacity: ${({submitState}) => {
    if (submitState.name === 'submitting') return 0.5;
    if (submitState.name === 'success') return 0;
  }};
  @media (${query.upto.tablet}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const FormTitle = styled(H2)`
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.38px;
  padding-bottom: 6px;
  border-bottom: 2px solid #383b41;
  margin-bottom: 25px;
`;

export const SectionTitle = styled(H3)`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.25px;
  ${fonts.sofiaBold};
  padding-bottom: 6px;
  border-bottom: 2px solid #383b41;
  margin-bottom: 10px;
`;

export const Form = styled.form``;

export const NameFieldSet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const AddressFieldSet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const FieldWrap = styled.div`
  color: #414042;
  font-size: 14px;
  line-height: 42px;
  ${fonts.sofiaRegular};
`;

export const Label = styled.label`
  ${fonts.sofiaBold};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.1px;
  margin-bottom: 10px;
  display: block;
  flex: 0 0 100%;
`;

export const FieldWrapHalf = styled(FieldWrap)`
  flex: 0 0 48%;

  @media (${query.upto.tablet}) {
    flex: 0 0 100%;
  }
`;

export const FieldWrapFull = styled(FieldWrap)`
  flex: 0 0 100%;
`;

export const TextInput = styled.input`
  background: transparent;
  border: 1px solid #979797;
  width: calc(100% - 14px);
  margin-bottom: 12px;
  color: #414042;
  font-size: 14px;
  line-height: 42px;
  ${fonts.sofiaRegular};
  outline: none;
  padding-left: 10px;

  ::placeholder {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    font-weight: inherit;
  }

  @media (${query.upto.tablet}) {
    max-width: 100%;
    font-size: 14px;
    margin-bottom: 24px;
  }
`;

export const TextArea = styled.textarea`
  color: #414042;
  font-size: 14px;
  background: transparent;
  border: 1px solid #979797;
  width: calc(100% - 20px);
  ${fonts.sofiaRegular};
  padding: 10px;
  margin-bottom: 28px;
  display: block;
`;

export const Disclaimer = styled.small`
  display: block;
  font-size: 12px;
  line-height: 18px;
  max-width: 610px;
`;

export const ButtonWrap = styled.div`
  margin: 0 auto;
  text-align: center;
  padding-top: 34px;
`;

export const Spinner = styled(LoadingIcon)<{
  visible: boolean;
  className: string;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  z-index: ${(props) => (props.visible ? 2 : -1)};
  width: 100px;
  transition: opacity 0.2s linear;
`;

export const FinePrintLink = styled(TextLink).attrs({
  color: 'darkGreen',
})`
  font-size: inherit;
`;
