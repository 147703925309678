import React from 'react';
import styled from 'styled-components';
import {fonts, colors, query} from '../style/theme';

export const FreeTextAreaWrapper = styled.div`
  color: ${colors.charcoal};

  h1,
  h2,
  h3,
  h4,
  h5 {
    ${fonts.sofiaBold}
    line-height: 1.12em;
    letter-spacing: -0.02883em;
    margin-bottom: 20px;

    @media (${query.upto.tablet}) {
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 50px;
  }

  h3 {
    font-size: 40px;
  }

  h4 {
    font-size: 30px;
  }

  h5 {
    font-size: 20px;
  }

  p {
    font-family: ${fonts.sofiaRegular.fontFamily};
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px;

    sup {
      vertical-align: super;
      font-size: 0.5em;
      color: inherit;
      padding-left: 2px;
    }

    @media (${query.upto.tablet}) {
      font-size: 14px;
      line-height: 22px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${colors.green};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  i,
  em {
    font-style: italic;
  }

  * {
    sup {
      vertical-align: super;
      font-size: 0.5em;
      padding-left: 2px;
      color: inherit;
    }
  }

  ul > li {
    list-style: disc;
  }

  ol > li {
    list-style: decimal;
  }

  li {
    display: list-item;
    margin-left: 1.25em;
    padding-bottom: 20px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;

    @media (${query.above.tablet}) {
      font-size: 18px;
      line-height: 27px;
      padding-left: 0.25em;
    }
  }
`;

const FreeTextArea: React.FC<{className?: string}> = ({
  children,
  className,
}) => (
  <FreeTextAreaWrapper className={className}>{children}</FreeTextAreaWrapper>
);

export default FreeTextArea;
