import React from 'react';
import styled from 'styled-components';
import {H2 as BaseH2, P1 as BaseP1} from '../../style/Typography';
import {query} from '../../style/theme';
import Checkmark from '../../images/checkmark-icon.svg';
import ShareSocialIcons from '../ShareSocialIcons';

const Container = styled.div<{visible: boolean}>`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: ${(props) => (props.visible ? 0 : '-200vw')};
  z-index: 5;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s linear 0.2s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  background: #f7f7f8;

  @media (${query.upto.small}) {
    padding: 0 25px;
    width: calc(100% - 50px);
  }
`;

const CheckmarkWrap = styled.div`
  margin-bottom: 20px;
  position: relative;
  z-index: 2;

  @media (${query.upto.small}) {
    width: 40px;
    height: 40px;
    svg {
      width: inherit;
      height: inherit;
    }
  }
`;

const H2 = styled(BaseH2)`
  color: #404042;
  font-size: 57px;
  line-height: 1.2em;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  max-width: 864px;
  text-align: center;

  @media (${query.upto.small}) {
    text-align: center;
    font-size: 32px;
    line-height: 34px;
    letter-spacing: -0.5px;
  }
`;

const P1 = styled(BaseP1)`
  color: #404042;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
  max-width: 805px;
  text-align: center;

  @media (${query.upto.small}) {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  strong {
    font-weight: bold;
    display: inline-block;
  }
`;

const SocialWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (${query.upto.small}) {
    justify-content: flex-start;
    flex-direction: column;

    p {
      margin-bottom: 10px;
    }
  }
`;

const StayConnected = styled(H2)`
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 10px;
  position: relative;
  top: -5px;
  margin-right: 14px;

  @media (${query.upto.small}) {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }
`;

const ThankYouHeadline = styled(H2)`
  @media (${query.upto.small}) {
    font-size: 32px;
    line-height: 34px;
  }
`;

export default (props: {visible: boolean}) => {
  return (
    <Container visible={props.visible}>
      <CheckmarkWrap>
        <Checkmark />
      </CheckmarkWrap>
      <ThankYouHeadline>
        Thanks for contacting your legislator. Increase the impact by sharing.
      </ThankYouHeadline>
      <P1>
        Now that you’ve reached out to your legislator, get your friends and
        family to do the same so we can let lawmakers know that this issue
        matters to us.
      </P1>
      <SocialWrap>
        <StayConnected as="p">Share:</StayConnected>
        <ShareSocialIcons />
      </SocialWrap>
    </Container>
  );
};
