import styled from 'styled-components';
import {query} from '../../style/theme';
import Img from 'gatsby-image';

export const Container = styled.section`
  margin: 20px;
  max-width: 1024px;
  text-align: center;

  @media (${query.above.tablet}) {
    margin: 35px;
  }

  @media (${query.above(1100)}) {
    margin: 35px auto;
  }

  @media (${query.above.large}) {
    margin: 85px auto;
    max-width: 1240px;
  }
`;

export const Image = styled(Img)`
  z-index: 1;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  margin: 0 auto;

  @media (${query.above.large}) {
    max-width: 1024px;
  }
`;

export const Caption = styled.div`
  margin: 13px auto 0 auto;
  width: 100%;
  max-width: 1024px;
  font-size: 13px;
  line-height: 1.4em;
  letter-spacing: 0;
  text-align: left;

  a {
    color: #709f5a;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: bold;
  }

  @media (${query.above.tablet}) {
    margin: 17px auto 0 auto;
    font-size: 16px;
    line-height: 25px;
  }

  @media (${query.above.large}) {
    max-width: 1240px;
  }
`;

export const Inner = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`;
