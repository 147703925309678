import styled, {CSSObject} from 'styled-components';
import theme, {themeColor} from './theme';
import {stringifyKey} from 'mobx/lib/internal';

export interface propsButton {
  color?: themeColor;
  textColor?: themeColor;
  borderColor?: themeColor;
  hoverColor?: themeColor;
  hoverTextColor?: themeColor;
  hoverBorderColor?: themeColor;
  flex?: boolean;
  compact?: boolean;
}

interface iMapColors {
  (props: propsButton): {
    bg: string;
    text: string;
    border: string;
    hoverBg: string;
    hoverText: string;
    hoverBorder: string;
  };
}

const mapColors: iMapColors = (props) => {
  const bg = props.color ? theme.colors[props.color] : theme.colors.yellow;
  const text = props.textColor
    ? theme.colors[props.textColor]
    : theme.colors.charcoal;
  const border = props.borderColor ? theme.colors[props.borderColor] : bg;
  const hoverBg = props.hoverColor
    ? theme.colors[props.hoverColor]
    : theme.colors.transparent;
  const hoverText = props.hoverTextColor
    ? theme.colors[props.hoverTextColor]
    : text;
  const hoverBorder = props.hoverBorderColor
    ? theme.colors[props.hoverBorderColor]
    : border;

  return {
    bg,
    text,
    border,
    hoverBg,
    hoverText,
    hoverBorder,
  };
};

const buttonish = (props: propsButton): CSSObject => {
  const colorMap = mapColors(props);
  return {
    boxSizing: 'border-box',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '3px',
    padding: `0 ${props.compact ? '8px' : '45px'}`,
    border: `solid ${theme.constants.buttonBorderWidth} ${colorMap.border}`,
    backgroundColor: colorMap.bg,
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    height: '45px',
    maxHeight: '45px',
    borderRadius: '999px',
    outline: 'none',
    whiteSpace: 'nowrap',
    color: colorMap.text,
    ...theme.typography.ui1,
    '&:hover': {
      backgroundColor: colorMap.hoverBg,
      color: colorMap.hoverText,
      borderColor: colorMap.hoverBorder,
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
    cursor: 'pointer',
  };
};

let flexible = ({flex}: propsButton) => {
  return flex ? {flex: 1} : {};
};

const smaller = (props: propsButton) => ({
  height: '40px',
  padding: `0 ${props.compact ? '6px' : '40px'} 2px`,
  maxHeight: '40px',
  ...theme.typography.ui2,
});

const circle = (px: number) => ({
  height: `${px}px`,
  width: `${px}px`,
  maxHeight: `${px}px`,
  maxWidth: `${px}px`,
  padding: 0,
});

export const Button = styled.button<propsButton>(buttonish, flexible);
export const ButtonSmall = styled.button<propsButton>(
  buttonish,
  flexible,
  smaller,
);
export const ButtonRound = styled.button<propsButton>(buttonish, circle(63));
export const ButtonRoundSmall = styled.button<propsButton>(
  buttonish,
  circle(45),
);
export const ButtonRoundLarge = styled.button<propsButton>(
  buttonish,
  circle(100),
);
export const CloseButtonRound = styled.button<propsButton>(
  buttonish,
  circle(65),
);

export default {
  Button,
  ButtonSmall,
  ButtonRound,
  ButtonRoundSmall,
  ButtonRoundLarge,
};
