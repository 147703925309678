import React from 'react';
import CustomBlockContent from '../CustomBlockContent';
import styled from 'styled-components';
import Video from './../Video';
import {
  ButtonLinkProps,
  VideoHeroProps,
  VideoHeroState,
} from './../../interfaces';
import {Container, Inner, Headline, Copy, ButtonGroup, Button} from './styles';
import {P1} from './../../style/Typography';
import {query} from './../../style/theme';

const VideoSection = styled(Video)`
  margin-top: 30px;

  @media (${query.above.tablet}) {
    margin-top: 60px;
  }
`;

class VideoHero extends React.Component<VideoHeroProps, VideoHeroState> {
  state = {
    showVideo: this.props.ambientVideo,
    showModal: false,
    showYoutubeVideo: false,
  };

  openModal = () => {
    this.setState({showModal: true});
  };

  playYoutubeVideo = () => {
    this.setState({showYoutubeVideo: true});
  };

  hideModal = () => {
    this.setState({showModal: false, showVideo: false});
  };

  setVideo = (ambientVideo: boolean) => {
    this.setState({showVideo: true}, () => {
      if (ambientVideo) {
        this.playYoutubeVideo();
      } else {
        this.openModal();
      }
    });
  };

  render() {
    const {headline, isH1, copy, button, video, ambientVideo} = this.props;
    const {showVideo, showModal, showYoutubeVideo} = this.state;
    return (
      <Container>
        <Inner>
          <Headline as={isH1 ? 'h1' : 'h2'}>{headline}</Headline>
          <Copy>
            <CustomBlockContent blocks={copy} />
          </Copy>
          <ButtonGroup>
            <Button {...button[0]} />
          </ButtonGroup>
          <div style={{display: 'block'}}>
            <VideoSection
              showVideo={showVideo}
              showModal={showModal}
              showYoutubeVideo={showYoutubeVideo}
              Video={video}
              ambientVideo={ambientVideo}
              hideModal={this.hideModal}
              setVideo={this.setVideo}
              minHeight={650}
            />
          </div>
        </Inner>
      </Container>
    );
  }
}

export default VideoHero;
