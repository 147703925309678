import React from 'react';
import styled from 'styled-components';
import {H3} from '../../style/Typography';
import BackgroundImage from 'gatsby-background-image';
import {query, colors} from '../../style/theme';
import ButtonLink from '../ButtonLink';
import {ImageProps, ButtonLinkProps} from '../../interfaces';

const Section = styled.section`
  min-height: 460px;
  padding: 20px;
  display: flex;
  justify-content: center;

  @media (${query.upto.tablet}) {
    padding: 0;
    min-height: 0;
  }
`;

const Inner = styled(BackgroundImage)<{tag?: string}>`
  max-width: 1240px;
  min-height: 460px;
  flex: 0 0 100%;

  @media (${query.upto.tablet}) {
    padding: 50px 20px;
    min-height: 0;
    max-width: calc(100% - 40px);
  }
`;

const CopyContainer = styled.div`
  max-width: 320px;
  position: absolute;
  bottom: 50px;
  left: 50px;

  @media (${query.upto.tablet}) {
    max-width: 100%;
    position: static;
  }
`;

const Headline = styled(H3)`
  color: white;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 22px;

  @media (${query.upto.tablet}) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 19px;
  }
`;

const Button = styled(ButtonLink)`
  @media (${query.upto.tablet}) {
    width: 100%;
  }

  &:hover {
    color: ${colors.yellow};
  }
`;

export default ({
  headline,
  image,
  buttonLink,
}: {
  headline: string;
  image: ImageProps;
  buttonLink: ButtonLinkProps;
}) => (
  <Section>
    <Inner tag="section" fluid={image.src.asset.fluid}>
      <CopyContainer>
        <Headline>{headline}</Headline>
        <Button {...buttonLink} />
      </CopyContainer>
    </Inner>
  </Section>
);
