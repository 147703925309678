import {useState, useEffect} from 'react';
import scrollToElement from 'scroll-to-element';
import {forceVisible} from 'react-lazyload';

export const scrollListener = (callback = () => {}) => {
  let lastPosition = window.scrollY;
  return () => {
    const direction = lastPosition > window.scrollY ? 'up' : 'down';
    callback(direction, window.scrollY);
    lastPosition = window.scrollY;
  };
};

export function useNavState(wrapperRef, animationThreshold = 0) {
  const [isAtTop, setIsAtTop] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [direction, setDirection] = useState(null);
  const [style, setStyle] = useState({
    position: 'absolute',
    top: '0',
    transition: 'all .2s linear',
  });

  const handleScroll = (direction, scrollY) => {
    const height =
      wrapperRef && wrapperRef.current && wrapperRef.current.offsetHeight;
    setIsAtTop(window.scrollY === 0);
    setDirection(direction);
    setIsActive(window.scrollY >= height);
  };

  const boundScrollListener = scrollListener(handleScroll.bind(style));

  useEffect(() => {
    window.addEventListener('scroll', boundScrollListener);

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', boundScrollListener);
      }
    };
  }, [boundScrollListener]);

  useEffect(() => {
    const height =
      wrapperRef && wrapperRef.current && wrapperRef.current.offsetHeight;

    setStyle((prevStyle) => {
      if (isAtTop) {
        return {
          ...prevStyle,
          position: 'absolute',
          top: 0,
          transition: 'all .2s linear',
        };
      }

      if (direction === 'up' && isActive) {
        return {
          ...prevStyle,
          position: 'fixed',
          top: 0,
          transition: 'all 0.2s linear',
        };
      }

      if (direction === 'down' && isActive && window.scrollY > height + 5) {
        return {
          ...prevStyle,
          position: 'fixed',
          top: (height + 5 || 150) * -1,
          transition:
            prevStyle.position === 'fixed' ? 'all 0.2s linear' : 'none',
        };
      }

      if (direction === 'down' && isActive) {
        return {
          ...prevStyle,
          position: 'fixed',
          top: (height + 5 || 150) * -1,
          transition: 'none',
        };
      }

      return prevStyle;
    });
  }, [direction, isAtTop, isActive]);

  return {isAtTop, ...style, isActive};
}

export function scrollToHero() {
  forceVisible();
  scrollToElement('.EmailSignupHero-wrapper');
}
