import React from 'react';
import ModuleLoader from '../ModuleLoader';
import Footer from '../Footer';
import {PageProps} from '../../interfaces';
import {Reset} from 'styled-reset';
import MainNavigation from '../MainNavigation';
import PageStyle, {propsPageStyle} from '../../style/PageStyle';
import EmailSignupHero from '../EmailSignupHero';
import DevGuides from '../../style/DevGuides';
import {observer, inject} from 'mobx-react';
import HeadMeta from '../HeadMeta';
import InfoDialog from '../InfoDialog';

const Page = (props: PageProps & propsPageStyle) => {
  let {
    body,
    showPreFooter,
    children,
    pagePath,
    layout,
    className,
    store,
    id,
  } = props;

  const {showAlertBar, alertBarHeight, mobileNavOpen} = store;

  body = body || [];

  const isDraft = id && id.includes && id.includes('draft');

  return (
    <PageStyle
      showAlertBar={showAlertBar}
      layout={layout}
      className={`Container ${className || ''}`}
      alertBarHeight={alertBarHeight}
      isHome={pagePath === '/'}
      mobileNavOpen={mobileNavOpen}
    >
      <Reset />
      <HeadMeta {...props} pagePath={pagePath} />
      <MainNavigation pagePath={pagePath} />
      <main className="Content">
        {children
          ? children
          : body.map((component, index) => (
              <ModuleLoader
                component={component}
                order={index}
                key={`module-child-${index}${component.id}`}
              />
            ))}
      </main>
      {showPreFooter === true ? <EmailSignupHero /> : null}
      <Footer />
      <DevGuides />
      {isDraft && (
        <InfoDialog>
          You are viewing a draft of <em>{props.metaTitle}</em>
        </InfoDialog>
      )}
    </PageStyle>
  );
};

export default inject('store')(observer(Page));
