import React, {useState, useEffect, useContext} from 'react';
import {NavListItem, NavList as NavListWrap, NavItemLink} from './styles';
import BackgroundImage from 'gatsby-background-image';
import {observer, inject} from 'mobx-react';

interface NavItemProps {
  children: React.FC;
  linkText: string;
  pagePath: string;
  href: string;
  styleState: string;
  id: string;
  totalItems: number;
  image: object;
}

interface NavListProps {
  children: React.FC;
  isOpen: boolean;
  items: Array<object>;
  styleState: string;
  pagePath: string;
  store: any;
}

const isCurrentPage = (url1 = '', url2 = '') => {
  return url1.replace(/\/$/, '') === url2.replace(/\/$/, '');
};

const NavItem = (props: NavItemProps) => {
  const isCurrent = isCurrentPage(props.pagePath, props.href);

  return (
    <NavListItem styleState={props.styleState}>
      {props.children ? (
        props.children
      ) : (
        <NavItemLink {...props} isCurrent={isCurrent}>
          {props.linkText}
        </NavItemLink>
      )}
      {props.image && <BackgroundImage fluid={props.image.src.asset.fluid} />}
    </NavListItem>
  );
};

const NavList = (props: NavListProps) => {
  const {closeMobileNavMenu} = props.store;

  return (
    <NavListWrap
      isOpen={props.isOpen}
      onClick={e => {
        closeMobileNavMenu();
      }}
    >
      {props.items.map(item => {
        return (
          <NavItem
            key={item.id}
            {...item}
            styleState={props.styleState}
            pagePath={props.pagePath}
          />
        );
      })}
      {props.children && <NavItem>{props.children}</NavItem>}
    </NavListWrap>
  );
};

export default inject(`store`)(observer(NavList));
