import React from 'react';
import PlayButton from './../PlayButton';
import Modal from '../Modal';
import {
  VideoContainer,
  VideoWrapper,
  VideoPreview,
  YoutubeVideo,
} from './styles/video';
import {PreviewImage, StaticVideoPlaceholderImage} from './styles/images';
import {VideoProps} from './../../interfaces';
import LazyLoad from 'react-lazyload';

class Video extends React.Component<VideoProps> {
  constructor(props: VideoProps) {
    super(props);
  }

  render() {
    const {
      showVideo,
      showModal,
      showYoutubeVideo,
      Video,
      ambientVideo,
      hideModal,
      setVideo,
      minHeight,
    } = this.props;
    const {youtubeUrl, video, videoPlaceholderImage} = Video[0];
    return (
      <LazyLoad height={700}>
        {/* If showVideo is true render video in Modal or in Video container
          else show the Preview Image with PlayButton
        */}
        {showVideo ? (
          // If ambientVideo is true render Youtube Video or Uploaded Video
          // else render video in Modal
          ambientVideo ? (
            <VideoContainer
              minHeight={minHeight}
              youtubeUrl={youtubeUrl}
              showYoutubeVideo={showYoutubeVideo}
            >
              {/* If youtubeUrl exists render Youtube Video
                else render preview Image with PlayButton on it
               */}
              {youtubeUrl ? (
                showYoutubeVideo ? (
                  <YoutubeVideo
                    src={`${youtubeUrl}`}
                    allowFullScreen
                    frameBorder={0}
                    allow={'autoplay'}
                  />
                ) : (
                  <VideoPreview>
                    <PreviewImage
                      fluid={videoPlaceholderImage.src.asset.fluid}
                      alt={videoPlaceholderImage.alt}
                      title={videoPlaceholderImage.title}
                      minHeight={minHeight}
                    />
                    <PlayButton
                      setVideo={(ambientVideo: boolean) =>
                        setVideo(ambientVideo)
                      }
                      ambientVideo={ambientVideo}
                    />
                  </VideoPreview>
                )
              ) : (
                <React.Fragment>
                  <VideoWrapper
                    loop
                    autoPlay
                    muted="muted"
                    preload="true"
                    wrappedInModal={showVideo}
                  >
                    <source src={video.asset.url} type="video/mp4" />
                  </VideoWrapper>
                  <StaticVideoPlaceholderImage
                    fluid={videoPlaceholderImage.src.asset.fluid}
                    alt={videoPlaceholderImage.alt}
                    title={videoPlaceholderImage.title}
                    minHeight={minHeight}
                    youtubeUrl={youtubeUrl}
                  />
                </React.Fragment>
              )}
            </VideoContainer>
          ) : (
            <Modal showModal={showModal} handleClose={hideModal}>
              {youtubeUrl ? (
                <YoutubeVideo
                  src={`${youtubeUrl}`}
                  allowFullScreen
                  frameBorder={0}
                  allow={'autoplay'}
                />
              ) : (
                <VideoWrapper
                  controls
                  disablePictureInPicture
                  autoPlay
                  preload="true"
                  wrappedInModal={showVideo}
                >
                  <source src={video.asset.url} type="video/mp4" />
                </VideoWrapper>
              )}
            </Modal>
          )
        ) : (
          <VideoPreview>
            <PreviewImage
              fluid={videoPlaceholderImage.src.asset.fluid}
              alt={videoPlaceholderImage.alt}
              title={videoPlaceholderImage.title}
              minHeight={minHeight}
            />
            <PlayButton
              setVideo={(ambientVideo: boolean) => setVideo(ambientVideo)}
              ambientVideo={ambientVideo}
              trackingLabel={video.asset.url}
            />
          </VideoPreview>
        )}
      </LazyLoad>
    );
  }
}

export default Video;
