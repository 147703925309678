import React from 'react';
import Helmet from 'react-helmet';
import {HeadMetaInterface} from '../../interfaces';
import {useStaticQuery, graphql} from 'gatsby';

export default (props: HeadMetaInterface) => {
  let {
    metaTitle,
    metaDescription,
    socialShareDescription,
    pagePath,
    socialShareImage,
    fontFace,
  } = props;

  const defaultSocialShareImage = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "og-default-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Helmet>
      <style data-font-face>{fontFace}</style>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:url" content={pagePath} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta
        property="og:description"
        content={socialShareDescription || metaDescription}
      />
      <meta
        property="og:image"
        content={
          socialShareImage && socialShareImage.src
            ? socialShareImage.src.asset.url
            : `https://www.reuseplastics.org${defaultSocialShareImage.fileName.childImageSharp.fluid.src}`
        }
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta
        name="twitter:description"
        content={socialShareDescription || metaDescription}
      />
      <meta
        name="twitter:image"
        content={
          socialShareImage && socialShareImage.src
            ? `https://www.reuseplastics.org${socialShareImage.src.asset.url}`
            : `https://www.reuseplastics.org${defaultSocialShareImage.fileName.childImageSharp.fluid.src}`
        }
      />
    </Helmet>
  );
};
