import React, {useState} from 'react';
import {VideoPreview, PreviewImage} from './styles/images';
import {VideoContainer, YoutubeVideo, VideoWrapper} from './styles/video';
import {EmbeddedVideoContainer, CaptionWrap, Inner} from './styles';
import {EmbeddedVideoProps, EmbeddedVideoState} from './../../interfaces';
import Caption from '../Caption';

import PlayButton from './../PlayButton';
import Modal from '../Modal';

class EmbeddedVideo extends React.Component<
  EmbeddedVideoProps & EmbeddedVideoState & {captionCopy: unknown}
> {
  state = {
    showVideo: false,
    showModal: this.props.playVideoInModal,
  };

  openModal = () => {
    this.setState({showModal: true});
  };

  hideModal = () => {
    this.setState({showVideo: false, showModal: false});
  };

  setVideo = () => {
    this.setState({showVideo: true}, () => {
      this.props.playVideoInModal && this.openModal();
    });
  };

  render() {
    const {youtubeUrl, videoPlaceholderImage} = this.props.Video[0];
    const {captionCopy} = this.props;
    const {showVideo, showModal} = this.state;
    return (
      <EmbeddedVideoContainer>
        <Inner>
          {showVideo ? (
            showModal ? (
              <Modal
                showModal={showModal}
                handleClose={this.hideModal}
                enableClose={true}
              >
                {youtubeUrl ? (
                  <YoutubeVideo
                    src={`${youtubeUrl}`}
                    allowFullScreen
                    data-allow={'autoplay'}
                    frameBorder={0}
                  />
                ) : (
                  <VideoWrapper
                    controls
                    disablePictureInPicture
                    autoPlay
                    preload="true"
                  >
                    <source
                      src={this.props.Video[0].video.asset.url}
                      type="video/mp4"
                    />
                  </VideoWrapper>
                )}
              </Modal>
            ) : (
              <VideoContainer minHeight={575} youtubeUrl={youtubeUrl}>
                {youtubeUrl ? (
                  <YoutubeVideo
                    src={`${youtubeUrl}`}
                    allowFullScreen
                    data-allow={'autoplay'}
                    frameBorder={0}
                  />
                ) : (
                  <VideoWrapper
                    controls
                    disablePictureInPicture
                    autoPlay
                    preload="true"
                  >
                    <source
                      src={this.props.Video[0].video.asset.url}
                      type="video/mp4"
                    />
                  </VideoWrapper>
                )}
              </VideoContainer>
            )
          ) : (
            <VideoPreview>
              {videoPlaceholderImage && (
                <PreviewImage
                  fluid={videoPlaceholderImage.src.asset.fluid}
                  alt={videoPlaceholderImage.alt}
                  title={videoPlaceholderImage.title}
                  minHeight={575}
                />
              )}
              <PlayButton
                setVideo={this.setVideo}
                showVideo={showVideo || false}
                trackingLabel={this.props.Video[0]?.video?.asset?.url || ''}
              />
            </VideoPreview>
          )}
          {captionCopy && (
            <CaptionWrap>
              <Caption blocks={captionCopy} />
            </CaptionWrap>
          )}
        </Inner>
      </EmbeddedVideoContainer>
    );
  }
}

export default EmbeddedVideo;
