import styled from 'styled-components';
import Link from '../../Link';
import {fonts, colors} from '../../../style/theme';
import {ButtonSmall} from '../../../style/Buttons';

export const NavWrapper = styled.nav<{styleState: string}>`
  position: fixed;
  left: 0;
  width: 100%;

  z-index: 2;
  background-color: ${({styleState}) => {
    return styleState === 'transparent' ? 'transparent' : 'white';
  }};
  transition: all 0.2s linear;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: ${({styleState}) => {
    return styleState === 'transparent'
      ? 'none'
      : '0 2px 8px 0 rgba(0, 0, 0, 0.1)';
  }};
`;

export const Inner = styled.div`
  display: flex;
  flex: 0 0 auto;
  max-width: 1280px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: calc(100% - 40px);
`;

export const LogoWrap = styled(Link)<{styleState: string}>`
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-right: 15px;
  padding: 14px 0 0;
  color: ${({styleState}) =>
    styleState === 'transparent' ? 'white' : '#414042'};

  svg {
    width: 355px;
    height: 70px;
  }

  *[fill='#FFFFFF'],
  *[fill='#ffffff'] {
    fill: ${({styleState}) =>
      styleState === 'transparent' ? 'white' : '#404042'};
  }

  *[stroke='#FFFFFF'],
  *[stroke='#ffffff'] {
    stroke: ${({styleState}) =>
      styleState === 'transparent' ? 'white' : '#404042'};
  }

  @media (max-width: 950px) {
    margin-right: 0px;
    width: 260px;
  }
`;

export const TagLine = styled.span<{styleState: string}>`
  flex: 0 0 170px;
  padding-left: 15px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.2em;
  border-left: 1px solid
    ${({styleState}) => (styleState === 'transparent' ? 'white' : '#414042')};
`;

export const NavList = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NavListItem = styled.li<{styleState: string}>`
  ${fonts.sofiaBold};
  color: ${({styleState}) =>
    styleState === 'transparent' ? 'white' : '#414042'};
  margin-left: 45px;

  &:first-child {
    margin-left: 0;
  }
`;

export const NavItemLink = styled(Link)<{isCurrent: boolean}>`
  color: inherit;
  text-decoration: none;
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    background: #ffd04f;
    height: 3px;
    position: absolute;
    bottom: -5px;
    left: 0;
    opacity: ${(props) => (props.isCurrent ? '1' : '0')};
    transition: opacity 0.2s linear;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

export const SignupButton = styled(ButtonSmall)<{styleState: string}>`
  width: 150px;

  &:hover {
    color: ${({styleState}) =>
      styleState === 'transparent' ? `${colors.yellow}` : '#414042'};
  }
`;
