import styled from 'styled-components';
import {colors, fonts, query} from './../../style/theme';
import ButtonLink from './../ButtonLink';

export const Container = styled.section`
  padding: 0;

  @media (${query.above.tablet}) {
    padding: 0px 40px 0px 80px;
  }

  @media (${query.above.small}) {
    padding: 0;
  }
`;

export const Inner = styled.div``;

export const UL = styled.ul`
  max-width: 685px;
  margin: 0 auto;

  @media (${query.upto.tablet}) {
    max-width: none;
  }
`;

export const LI = styled.li`
  position: relative;
  margin-bottom: 86px;

  @media (${query.upto.tablet}) {
    margin-bottom: 0px;
  }
`;

export const Term = styled.h3<{isOpen: boolean; content: string}>`
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.38px;
  font-family: ${fonts.sofiaBold.fontFamily};
  font-weight: 700;
  color: ${colors.charcoal};
  margin-bottom: 20px;

  &:before {
    position: absolute;
    content: '${(props) => props.content}';
    left: -45px;
    color: #709f5a;
  }

  @media (${query.upto.tablet}) {
    background: #709f5a;
    padding: 20px 40px 20px 20px;
    font-size: 16px;
    line-height: 22px;
    color: white;
    letter-spacing: normal;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid white;

    &:before {
      display: none;
    }

    &:after {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      content: '';
      position: absolute;
      top: 29px;
      right: 20px;
      transition: all 0.2s linear;
      transform: rotate(${(props) => (props.isOpen ? 0 : 180)}deg);
    }
  }
`;

export const Definition = styled.div<{isOpen: boolean; contentHeight: number}>`
  color: ${colors.charcoal};

  p {
    font-family: ${fonts.sofiaRegular.fontFamily};
    font-size: 18px;
    line-height: 27px;
  }

  @media (${query.upto.tablet}) {
    background: white;
    padding: 0 20px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: normal;
    max-height: ${({isOpen, contentHeight}) => (isOpen ? contentHeight : 0)}px;
    overflow: hidden;
    transition: max-height 0.2s linear;

    div {
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }
    }
  }
`;

export const Button = styled(ButtonLink)`
  padding: 0 auto;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  ${fonts.sofiaBold};

  @media (${query.above(500)}) {
    padding: 0 45px;
    width: auto;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const ButtonGroup = styled.div`
  max-width: none;
  padding: 20px;
  text-align: center;

  @media (${query.above.tablet}) {
    padding: 0px 0px 80px 0px;
    margin: 0 auto;
    max-width: 820px;
    text-align: left;
  }
`;
