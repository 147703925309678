import React from 'react';
import styled from 'styled-components';
import {H1, H2, P1, P2, Text} from '../../style/Typography';
import {Button, ButtonSmall} from '../../style/Buttons';
import ButtonLink from '../ButtonLink';
import TextLink, {ArrowLink} from '../TextLink';
import theme from '../../style/theme';
import BrandSocialIcons from '../SocialIcons/Brand';
import ShareSocialIcons from '../ShareSocialIcons';
import CarouselButtons from '../CtaCarousel/CarouselButtons';
import FreeTextArea from '../../components/FreeTextArea';
import PullQuote from '../../components/PullQuote';
import ResponSiveQueries from './responsiveQueries';

const Wrap = styled.div`
  background: ${theme.colors.charcoal};
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 0;
  padding: 0;
`;

const Container = styled.div`
  width: 728px;
  background: ${theme.colors.white};
  padding: 100px 18px 300px 18px;
  /* overflow-y: scroll; */
`;

const FlexWide = styled.div`
  display: flex;
`;

const ElementTitle = styled(H2)`
  border-bottom: 1px solid grey;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
`;

export default () => (
  <Wrap>
    <Container>
      <ElementTitle>Typography</ElementTitle>

      <H1>
        H1 - Laborum elit nulla incididunt sit Lorem sit nostrud aliqua duis.
      </H1>
      <H1 color="yellow">H1 - in yellow</H1>
      <P1>
        P1 - Consectetur mollit magna ullamco proident. Irure cillum irure qui
        id id occaecat proident.{' '}
        <strong>
          Dolor non tempor consequat proident excepteur id. Ipsum fugiat in
          excepteur cillum esse
        </strong>{' '}
        veniam sit magna ut deserunt.
      </P1>
      <P1 bold color="red">
        Excepteur enim nulla cillum cupidatat consectetur ullamco sint mollit.
        Aliqua qui magna voluptate pariatur magna culpa aliqua ea cupidatat
        commodo. Anim irure id exercitation veniam Lorem tempor in Lorem
        excepteur adipisicing incididunt sit ad. Et qui officia elit consectetur
        reprehenderit ullamco proident officia. Sit quis aliquip duis nostrud
        est.
      </P1>
      <H2>
        H2 - Laborum elit nulla{' '}
        <Text underline color="blue">
          incididunt
        </Text>{' '}
        sit Lorem sit nostrud aliqua duis.
      </H2>
      <P2>
        P2 - Consectetur mollit magna ullamco proident. Irure cillum irure qui
        id id occaecat proident. Dolor non tempor consequat proident excepteur
        id. Ipsum fugiat in excepteur cillum esse veniam sit magna ut deserunt.
      </P2>
      <P2>
        Excepteur enim nulla cillum cupidatat consectetur ullamco sint mollit.
        Aliqua qui magna voluptate pariatur magna culpa aliqua ea cupidatat
        commodo. Anim irure id exercitation veniam Lorem tempor in Lorem
        excepteur adipisicing incididunt sit ad. Et qui officia elit consectetur
        reprehenderit ullamco proident officia. Sit quis aliquip duis nostrud
        est.
      </P2>

      <ElementTitle>Buttons</ElementTitle>

      <Button>{`<Button />`}</Button>
      <Button color="red">{`<Button color="red"/>`}</Button>
      <Button color="charcoal" textColor="yellow">
        {`<Button color="charcoal" textColor="yellow" />`}
      </Button>
      <FlexWide>
        <Button flex>{`<Button flex />`}</Button>
        <Button color="charcoal" textColor="green" flex>
          Flex on them Haters
        </Button>
      </FlexWide>
      <ButtonSmall>Smaller Button</ButtonSmall>
      <ButtonSmall color="blue">(for nav)</ButtonSmall>
      <ElementTitle>ButtonLink Component</ElementTitle>
      <P2>Which extends the Button style component</P2>
      <ButtonLink href="#" buttonText="I'm a Button Link" />
      <Button>I'm just a button</Button>
      <P2>but is actually just an anchor tag</P2>
      <ElementTitle>
        TextLink Component (Built with Text primative)
      </ElementTitle>
      <P2>
        Here is some p2 text, and a{' '}
        <TextLink href="/" text="TextLink component" /> within. It uses a gatsby
        Link component.
      </P2>
      <P2>
        Here is an external link to
        <TextLink href="https://google.com" text="Google" />. It processes as a
        regular link.
      </P2>
      <H2>Arrow Link</H2>
      <ArrowLink href="https://google.com" text="Google" />
      <br />
      <ElementTitle>Brand Social Icons</ElementTitle>
      <BrandSocialIcons />

      <ElementTitle>Social Sharing Icon</ElementTitle>
      <ShareSocialIcons />

      <H2>Carousel Button</H2>
      <CarouselButtons />

      <ElementTitle>Free Text Wrapper</ElementTitle>

      <FreeTextArea>
        <h1>This is a headline</h1>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>

        <h2>This is a smaller h2 headline</h2>

        <p>
          You can also make text <b>bold (using the "b" or "strong" tags)</b> or{' '}
          <i>italics (using the "i" or "em" tags)</i>. You can get really crazy
          and <u>underline stuff.</u>
        </p>

        <p>
          Lorem ipsum dolor sit amet,{' '}
          <a href="http://www.google.com">consectetur adipiscing elit</a>, sed
          do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.{' '}
        </p>
      </FreeTextArea>

      <ElementTitle>Pull Quote</ElementTitle>

      <PullQuote
        quote={`
          Flexible packaging has many positive attributes—highly efficient, great product protection, and low environmental impact. However, recovery has been one of its weak points.
        `}
        name="- LARRY BANER"
        title="Senior Packaging Research Scientist"
        employer="NestlePurina Petcare"
      />

      <ElementTitle>Responsive Queries</ElementTitle>

      <ResponSiveQueries />
    </Container>
  </Wrap>
);
