import React from 'react';
import styled from 'styled-components';
import FreeTextArea from '../FreeTextArea';
import ButtonLink from '../ButtonLink';
import theme, {query} from '../../style/theme';
import {P1, Text, H2} from '../../style/Typography';
import {MainContentSection} from '../../style/layout';

const Wrapper = styled(MainContentSection)`
  background: ${theme.colors.darkGreen};
  display: flex;
  justify-content: center;
`;

const Inner = styled.div`
  max-width: 685px;
  margin: -7px 0 -20px 0;

  @media (${query.above.tablet}) {
    margin: -14px 0 0 0;
  }
`;

const Headline = styled(H2)`
  font-size: 60px;
  line-height: 63px;
  letter-spacing: -0.88px;
  color: white;
  margin-bottom: 30px;

  @media (${query.upto.tablet}) {
    font-size: 32px;
    line-height: 34px;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
  }
`;

const P = styled(P1)`
  margin: ${theme.typography.p1.lineHeight} 0;
`;

const CopyArea = styled(FreeTextArea)`
  color: white;
  margin-bottom: 30px;

  @media (${query.upto.tablet}) {
    margin-bottom: 15px;
  }
`;

const Button = styled(ButtonLink)`
  margin: 0;

  &:hover {
    color: ${theme.colors.yellow};
  }

  @media (${query.upto.tablet}) {
    margin: 0 0 30px 0;
    width: 100%;
  }
`;

interface ComponentProps {
  headline: string;
  terms: [
    {
      term: string;
      definition: string;
      slug: {
        current: string;
      };
    },
  ];
  buttonLink: object;
  className: string;
}

export default ({
  headline,
  terms,
  buttonLink,
  className = '',
}: ComponentProps) => (
  <Wrapper className={`GlossaryCTA ${className}`}>
    <Inner>
      <Headline>{headline}</Headline>
      {terms.map((term) => (
        <P color="white" responsive id={term?.slug?.current}>
          <Text bold>
            {term.term}
            {':'}
          </Text>
          {/* \xa0 => no-break space unicode character */}
          {'\xa0\xa0'}
          {term.definition}
        </P>
      ))}
      {buttonLink && (
        <Button
          trackingID={buttonLink.trackingID}
          color="yellow"
          buttonText={buttonLink.buttonText}
          href={buttonLink.href}
        />
      )}
    </Inner>
  </Wrapper>
);
