import React from 'react';
import CustomBlockContent from '../CustomBlockContent';
import {
  SectionWrapper,
  BackgroundSection,
  Wrapper,
  Eyebrow,
  Headline,
  CopyContainer,
  BlockCopyWrapper,
} from './styles';
import {CenteredInteriorHeroProps} from '../../interfaces';

export default (props: CenteredInteriorHeroProps) => {
  return (
    <SectionWrapper>
      <BackgroundSection fluid={props.backgroundImage.src.asset.fluid}>
        <Wrapper variant={props.variant || ''}>
          <CopyContainer variant={props.variant || ''}>
            {props.eyebrow && <Eyebrow>{props.eyebrow}</Eyebrow>}
            {props.headline && (
              <Headline as="h1" variant={props.variant || ''}>
                {props.headline}
              </Headline>
            )}
            <BlockCopyWrapper>
              <CustomBlockContent blocks={props.copy} />
            </BlockCopyWrapper>
          </CopyContainer>
        </Wrapper>
      </BackgroundSection>
    </SectionWrapper>
  );
};
