import Img from 'gatsby-image';
import styled from 'styled-components';
import {query} from '../../../style/theme';

export const PreviewImage = styled(Img)<{minHeight: number}>`
  max-height: 830px;
  height: auto;
  bottom: 0;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;

  @media (${query.above.tablet}) {
    min-height: 500px;
  }

  @media (${query.above(992)}) {
    min-height: ${props => `${props.minHeight}px`};
  }
`;

export const StaticVideoPlaceholderImage = styled(Img)<{
  minHeight: number;
  youtubeUrl?: string;
}>`
  max-height: 830px;
  height: auto;
  bottom: 0;
  z-index: 1;
  display: ${props => (props.youtubeUrl ? 'none' : 'flex')};
  max-width: 100%;
  max-height: 100%;

  @media (${query.above.tablet}) {
    display: none;
  }

  @media (${query.above(992)}) {
    min-height: ${props => `${props.minHeight}px`};
  }
`;
