/** @jsx jsx */
//import React from 'react';
import Select from 'react-select';
import React, {useEffect, useRef} from 'react';
import {jsx} from '@emotion/core';

const selectStyles = {
  placeholder: (styles) => {
    return {
      ...styles,
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      color: 'inherit',
      padding: 0,
    };
  },
  container: (styles) => {
    return {
      ...styles,
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      color: 'inherit',
      marginBottom: 12,
      borderColor: 'transparent',
    };
  },
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderColor: '#979797',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      color: 'inherit',
      height: 46,
      '&:hover': {
        borderColor: '#979797',
        boxShadow: 'none',
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      fontSize: 'inherit',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      color: 'inherit',
      paddingLeft: 10,
      paddingTop: 0,
      paddingBottom: 0,
    };
  },
  input: (styles) => {
    return {
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      border: 0,
      background: 'transparent',
      opacity: 0,
      '&:focus': {
        border: 0,
        outline: 'none',
      },
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      borderRadius: 0,
    };
  },
};

const CustomInput = (props) => {
  const {getStyles, className, selectProps} = props;
  return (
    <input
      {...props}
      css={getStyles('input', props)}
      required
      className={className}
      value={selectProps.value ? selectProps.value.value : ''}
    />
  );
};

export default (props) => {
  return (
    <Select
      styles={selectStyles}
      components={{Input: CustomInput}}
      {...props}
    />
  );
};
