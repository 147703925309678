import styled from 'styled-components';
import Link from '../../Link';
import {fonts, query} from '../../../style/theme';

export const NavList = styled.ul<{isOpen: boolean}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 17px;

  @media (${query.upto.small}) {
    top: 100%;
    left: 0;
    width: 100%;
    max-height: ${(props) => (props.isOpen ? `100vh` : 0)};
    z-index: 10;
    background: #414042;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    transition: min-height 0.2s linear;
    flex: 2 2 auto;
    padding: 0;
  }
`;

export const NavListItem = styled.li<{styleState: string}>`
  ${fonts.sofiaBold};
  color: ${({styleState}) =>
    styleState === 'transparent' ? 'white' : '#414042'};
  margin-left: 45px;

  &:first-child {
    margin-left: 0;
  }

  @media (${query.upto.small}) {
    ${fonts.sofiaBold};
    font-size: 40px;
    line-height: 80px;
    letter-spacing: -0.63;
    color: white;
    width: auto;
    padding: 0 20px;
    text-align: left;
    margin-left: 0;
  }

  > div {
    display: none;

    @media (${query.upto.small}) {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
`;

export const NavItemLink = styled(Link)<{isCurrent: boolean}>`
  color: inherit;
  text-decoration: none;
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    background: #ffd04f;
    height: 3px;
    position: absolute;
    bottom: -5px;
    left: 0;
    opacity: ${(props) => (props.isCurrent ? '1' : '0')};
    transition: opacity 0.2s linear;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  @media (${query.upto.small}) {
    position: relative;
    z-index: 2;
  }
`;
