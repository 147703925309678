import styled from 'styled-components';
import Img from 'gatsby-image';
import {EmbeddedVideoContainer} from './../EmbeddedVideo/styles';
import {query} from '../../style/theme';

export const GridContainer = styled(EmbeddedVideoContainer)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 25px;
  max-width: 1024px;
  margin: 20px;

  @media (${query.above.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 45px;
    margin: 35px;
  }

  @media (${query.above.small}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 45px;
    margin: 45px auto;
  }

  @media (${query.above.large}) {
    margin: 85px auto;
    max-width: 1240px;
  }
`;

export const Image = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
