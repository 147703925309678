import React, {FunctionComponent as fc} from 'react';
import {story as _story} from '../../interfaces';
import {observer, inject} from 'mobx-react';
import Card from '../Card';

export interface propsStoryCard {
  story: _story;
  featured?: boolean;
  discoverStore?: any;
}

// Gives Data to shared Card Component
const StoryCard: fc<propsStoryCard> = ({story, featured, discoverStore}) => {
  const {setCategory} = discoverStore;
  const title = story.title;
  const link = `/news/${story.slug.current}`;
  const description = story.metaDescription || '';
  const primaryAccent = story?.category?.name || 'General';
  const primaryAccentLink = `/news?category=${story?.category?.slug.current}`;
  const primaryAccentEvent = () => {
    setCategory(story?.category?.slug.current);
  };
  const secondaryAccent = story.publishedDate;
  const accent = {
    primary: {
      accent: primaryAccent,
      link: primaryAccentLink,
      event: primaryAccentEvent,
    },
    secondary: {
      accent: secondaryAccent,
    },
  };
  const parentPath = 'news';

  return (
    <Card
      title={title}
      link={link}
      image={story?.previewImage || null}
      description={description}
      accent={accent}
      featured={featured}
      parentPath={parentPath}
    />
  );
};

export default inject(`discoverStore`)(observer(StoryCard));
