import React, {useRef} from 'react';
import {NavProps} from '../common';
import Burger from 'react-hamburger-menu';
import Logo from '../../../images/logo-sm.svg';
import {NavBar, LogoWrap, SignupButton, BurgerWrap, Inner} from './styles';
import NavList from '../NavList';
import AlertBar from '../../AlertBar';
import {useNavState, scrollToHero} from '../utils';
import {observer, inject} from 'mobx-react';

const MobileNav = (props: NavProps) => {
  const {mobileNavOpen, showAlertBar, toggleMobileNavMenu} = props.store;
  const wrapperRef = useRef(null);
  const {position, top, transition, isActive} = useNavState(wrapperRef);

  return (
    <NavBar
      style={{
        position,
        top: mobileNavOpen ? 0 : top,
        transition,
      }}
      ref={wrapperRef}
      isActive={isActive}
      isOpen={mobileNavOpen}
    >
      <AlertBar
        showAlertBar={showAlertBar}
        copy={props.alertBarCopy}
        onCloseClick={(e) => {
          props.setShowAlertBar(false);
        }}
      />
      <Inner>
        <LogoWrap>
          <Logo />
        </LogoWrap>

        <SignupButton
          onClick={(e) => {
            e.preventDefault();
            scrollToHero();
          }}
        >
          Sign Up
        </SignupButton>

        <BurgerWrap>
          <Burger
            isOpen={mobileNavOpen}
            width={22}
            height={14}
            strokeWidth={2}
            menuClicked={() => {
              toggleMobileNavMenu();
            }}
            color="#414042"
          />
        </BurgerWrap>
      </Inner>
      <NavList
        isOpen={mobileNavOpen}
        items={props.items}
        pagePath={props.pagePath}
      />
    </NavBar>
  );
};

export default inject(`store`)(observer(MobileNav));
